import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';
import {axiosInstance} from '@/utils/axios'

const apiUri = function (path: string, query: any = {}): string {
    const localVarUrlObj = globalImportUrl.parse(BASE_PATH + path, true);
    localVarUrlObj.query = {...localVarUrlObj.query, ...query};

    return globalImportUrl.format(localVarUrlObj);
};

type Response = Promise<AxiosPromise>;

export const Api = {
    async jobs(): Response {
        return axiosInstance.get(apiUri('/it/jobs'));
    },
};


// LEGACY BELOW

/**
 *
 * @export
 * @interface DistributorDistributorDetail
 */
export interface DistributorDistributorDetail {
    /**
     *
     * @type {string}
     * @memberof DistributorDistributorDetail
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorDistributorDetail
     */
    name?: string;
}
/**
 *
 * @export
 * @interface DistributorJsonldDistributorDetail
 */
export interface DistributorJsonldDistributorDetail {
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldDistributorDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldDistributorDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof DistributorJsonldDistributorDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldDistributorDetail
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldDistributorDetail
     */
    name?: string;
}
/**
 *
 * @export
 * @interface DistributorJsonldOutboundDetail
 */
export interface DistributorJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof DistributorJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundDetail
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundDetail
     */
    name?: string;
}
/**
 *
 * @export
 * @interface DistributorJsonldOutboundOverview
 */
export interface DistributorJsonldOutboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof DistributorJsonldOutboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundOverview
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorJsonldOutboundOverview
     */
    name?: string;
}
/**
 *
 * @export
 * @interface DistributorOutboundDetail
 */
export interface DistributorOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof DistributorOutboundDetail
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorOutboundDetail
     */
    name?: string;
}
/**
 *
 * @export
 * @interface DistributorOutboundOverview
 */
export interface DistributorOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof DistributorOutboundOverview
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof DistributorOutboundOverview
     */
    name?: string;
}
/**
 *
 * @export
 * @interface ExternalOrderItem
 */
export interface ExternalOrderItem {
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItem
     */
    ean: string;
    /**
     *
     * @type {number}
     * @memberof ExternalOrderItem
     */
    quantity: number;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItem
     */
    options?: string | null;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItem
     */
    location?: string | null;
}
/**
 *
 * @export
 * @interface ExternalOrderItemJsonld
 */
export interface ExternalOrderItemJsonld {
    /**
     *
     * @type {string | any}
     * @memberof ExternalOrderItemJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItemJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItemJsonld
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItemJsonld
     */
    ean: string;
    /**
     *
     * @type {number}
     * @memberof ExternalOrderItemJsonld
     */
    quantity: number;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItemJsonld
     */
    options?: string | null;
    /**
     *
     * @type {string}
     * @memberof ExternalOrderItemJsonld
     */
    location?: string | null;
}
/**
 *
 * @export
 * @interface InboundInboundCreate
 */
export interface InboundInboundCreate {
    /**
     *
     * @type {string}
     * @memberof InboundInboundCreate
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundInboundCreate
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundInboundCreate
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundCreate
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundCreate
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundCreate
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundCreate
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundCreate
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {Array<object>}
     * @memberof InboundInboundCreate
     */
    photos?: Array<object>;
}
/**
 *
 * @export
 * @interface InboundInboundDetail
 */
export interface InboundInboundDetail {
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    createdAt?: string;
    /**
     *
     * @type {UserInboundDetail}
     * @memberof InboundInboundDetail
     */
    createdBy?: UserInboundDetail;
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundInboundDetail
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {Array<InboundItemInboundDetail>}
     * @memberof InboundInboundDetail
     */
    items?: Array<InboundItemInboundDetail>;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundDetail
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundDetail
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundDetail
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundDetail
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {string}
     * @memberof InboundInboundDetail
     */
    completedAt?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnInboundDetail>}
     * @memberof InboundInboundDetail
     */
    returnItems?: Array<OutboundItemReturnInboundDetail>;
    /**
     *
     * @type {Array<InboundPhotoInboundDetail>}
     * @memberof InboundInboundDetail
     */
    photos?: Array<InboundPhotoInboundDetail>;
}
/**
 *
 * @export
 * @interface InboundInboundOverview
 */
export interface InboundInboundOverview {
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    createdAt?: string;
    /**
     *
     * @type {UserInboundOverview}
     * @memberof InboundInboundOverview
     */
    createdBy?: UserInboundOverview;
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundInboundOverview
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    state?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundOverview
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundOverview
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundOverview
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundInboundOverview
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {string}
     * @memberof InboundInboundOverview
     */
    completedAt?: string | null;
}
/**
 *
 * @export
 * @interface InboundInboundTransportOverview
 */
export interface InboundInboundTransportOverview {
    /**
     *
     * @type {string}
     * @memberof InboundInboundTransportOverview
     */
    createdAt?: string;
    /**
     *
     * @type {UserInboundTransportOverview}
     * @memberof InboundInboundTransportOverview
     */
    createdBy?: UserInboundTransportOverview;
    /**
     *
     * @type {string}
     * @memberof InboundInboundTransportOverview
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundInboundTransportOverview
     */
    completedAt?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemInboundDetail
 */
export interface InboundItemInboundDetail {
    /**
     *
     * @type {ProductInboundDetail}
     * @memberof InboundItemInboundDetail
     */
    product: ProductInboundDetail;
    /**
     *
     * @type {LocationInboundDetail}
     * @memberof InboundItemInboundDetail
     */
    location: LocationInboundDetail;
    /**
     *
     * @type {number}
     * @memberof InboundItemInboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundDetail
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<InboundItemPhotoInboundDetail>}
     * @memberof InboundItemInboundDetail
     */
    photos?: Array<InboundItemPhotoInboundDetail>;
}
/**
 *
 * @export
 * @interface InboundItemInboundItemCreate
 */
export interface InboundItemInboundItemCreate {
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemCreate
     */
    product: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemCreate
     */
    inbound: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemCreate
     */
    location: string;
    /**
     *
     * @type {number}
     * @memberof InboundItemInboundItemCreate
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemCreate
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemCreate
     */
    comment?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemInboundItemDetail
 */
export interface InboundItemInboundItemDetail {
    /**
     *
     * @type {ProductInboundItemDetail}
     * @memberof InboundItemInboundItemDetail
     */
    product: ProductInboundItemDetail;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemDetail
     */
    inbound: string;
    /**
     *
     * @type {LocationInboundItemDetail}
     * @memberof InboundItemInboundItemDetail
     */
    location: LocationInboundItemDetail;
    /**
     *
     * @type {number}
     * @memberof InboundItemInboundItemDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemDetail
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemInboundItemDetail
     */
    comment?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemJsonldInboundDetail
 */
export interface InboundItemJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof InboundItemJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldInboundDetail}
     * @memberof InboundItemJsonldInboundDetail
     */
    product: ProductJsonldInboundDetail;
    /**
     *
     * @type {LocationJsonldInboundDetail}
     * @memberof InboundItemJsonldInboundDetail
     */
    location: LocationJsonldInboundDetail;
    /**
     *
     * @type {number}
     * @memberof InboundItemJsonldInboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundDetail
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<InboundItemPhotoJsonldInboundDetail>}
     * @memberof InboundItemJsonldInboundDetail
     */
    photos?: Array<InboundItemPhotoJsonldInboundDetail>;
}
/**
 *
 * @export
 * @interface InboundItemJsonldInboundItemCreate
 */
export interface InboundItemJsonldInboundItemCreate {
    /**
     *
     * @type {string | any}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    product: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    inbound: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    location: string;
    /**
     *
     * @type {number}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemCreate
     */
    comment?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemJsonldInboundItemDetail
 */
export interface InboundItemJsonldInboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldInboundItemDetail}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    product: ProductJsonldInboundItemDetail;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    inbound: string;
    /**
     *
     * @type {LocationJsonldInboundItemDetail}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    location: LocationJsonldInboundItemDetail;
    /**
     *
     * @type {number}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundItemJsonldInboundItemDetail
     */
    comment?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemPhotoInboundDetail
 */
export interface InboundItemPhotoInboundDetail {
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemPhotoJsonldInboundDetail
 */
export interface InboundItemPhotoJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof InboundItemPhotoJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoJsonldInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotoJsonldInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface InboundItemPhotosJsonldInboundItemCreate
 */
export interface InboundItemPhotosJsonldInboundItemCreate {
    /**
     *
     * @type {string | any}
     * @memberof InboundItemPhotosJsonldInboundItemCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotosJsonldInboundItemCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundItemPhotosJsonldInboundItemCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface InboundJsonldInboundCreate
 */
export interface InboundJsonldInboundCreate {
    /**
     *
     * @type {string | any}
     * @memberof InboundJsonldInboundCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundCreate
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundCreate
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundJsonldInboundCreate
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundCreate
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundCreate
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundCreate
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundCreate
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundCreate
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {Array<InboundPhotoJsonldInboundCreate>}
     * @memberof InboundJsonldInboundCreate
     */
    photos?: Array<InboundPhotoJsonldInboundCreate>;
}
/**
 *
 * @export
 * @interface InboundJsonldInboundDetail
 */
export interface InboundJsonldInboundDetail {
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof InboundJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    createdAt?: string;
    /**
     *
     * @type {UserJsonldInboundDetail}
     * @memberof InboundJsonldInboundDetail
     */
    createdBy?: UserJsonldInboundDetail;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundJsonldInboundDetail
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {Array<InboundItemJsonldInboundDetail>}
     * @memberof InboundJsonldInboundDetail
     */
    items?: Array<InboundItemJsonldInboundDetail>;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundDetail
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundDetail
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundDetail
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundDetail
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundDetail
     */
    completedAt?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnJsonldInboundDetail>}
     * @memberof InboundJsonldInboundDetail
     */
    returnItems?: Array<OutboundItemReturnJsonldInboundDetail>;
    /**
     *
     * @type {Array<InboundPhotoJsonldInboundDetail>}
     * @memberof InboundJsonldInboundDetail
     */
    photos?: Array<InboundPhotoJsonldInboundDetail>;
}
/**
 *
 * @export
 * @interface InboundJsonldInboundLocationScan
 */
export interface InboundJsonldInboundLocationScan {
    /**
     *
     * @type {string | any}
     * @memberof InboundJsonldInboundLocationScan
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundLocationScan
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundLocationScan
     */
    type?: string;
}
/**
 *
 * @export
 * @interface InboundJsonldInboundOverview
 */
export interface InboundJsonldInboundOverview {
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    createdAt?: string;
    /**
     *
     * @type {UserJsonldInboundOverview}
     * @memberof InboundJsonldInboundOverview
     */
    createdBy?: UserJsonldInboundOverview;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof InboundJsonldInboundOverview
     */
    amount?: number | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    preOrderProducts?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    state?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundOverview
     */
    typeReturn?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundOverview
     */
    typeShop?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundOverview
     */
    typeOther?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof InboundJsonldInboundOverview
     */
    typePreOrder?: boolean;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundOverview
     */
    completedAt?: string | null;
}
/**
 *
 * @export
 * @interface InboundJsonldInboundTransportOverview
 */
export interface InboundJsonldInboundTransportOverview {
    /**
     *
     * @type {string | any}
     * @memberof InboundJsonldInboundTransportOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundTransportOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundTransportOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundTransportOverview
     */
    createdAt?: string;
    /**
     *
     * @type {UserJsonldInboundTransportOverview}
     * @memberof InboundJsonldInboundTransportOverview
     */
    createdBy?: UserJsonldInboundTransportOverview;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundTransportOverview
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof InboundJsonldInboundTransportOverview
     */
    completedAt?: string | null;
}
/**
 *
 * @export
 * @interface InboundPhotoInboundDetail
 */
export interface InboundPhotoInboundDetail {
    /**
     *
     * @type {string}
     * @memberof InboundPhotoInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface InboundPhotoJsonldInboundCreate
 */
export interface InboundPhotoJsonldInboundCreate {
    /**
     *
     * @type {string | any}
     * @memberof InboundPhotoJsonldInboundCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface InboundPhotoJsonldInboundDetail
 */
export interface InboundPhotoJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof InboundPhotoJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotoJsonldInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface InboundPhotosJsonldInboundCreate
 */
export interface InboundPhotosJsonldInboundCreate {
    /**
     *
     * @type {string | any}
     * @memberof InboundPhotosJsonldInboundCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof InboundPhotosJsonldInboundCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundPhotosJsonldInboundCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface InboundStateTransitionInboundStateChange
 */
export interface InboundStateTransitionInboundStateChange {
    /**
     *
     * @type {string}
     * @memberof InboundStateTransitionInboundStateChange
     */
    transition: string;
}
/**
 *
 * @export
 * @interface InboundTransportInboundTransportDetail
 */
export interface InboundTransportInboundTransportDetail {
    /**
     *
     * @type {LocationInboundTransportDetail}
     * @memberof InboundTransportInboundTransportDetail
     */
    location?: LocationInboundTransportDetail;
    /**
     *
     * @type {string}
     * @memberof InboundTransportInboundTransportDetail
     */
    state?: string | null;
    /**
     *
     * @type {UserInboundTransportDetail}
     * @memberof InboundTransportInboundTransportDetail
     */
    assignee?: UserInboundTransportDetail | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InboundTransportInboundTransportDetail
     */
    lateProductIds?: Array<number> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InboundTransportInboundTransportDetail
     */
    inbounds?: Array<string>;
}
/**
 *
 * @export
 * @interface InboundTransportInboundTransportOverview
 */
export interface InboundTransportInboundTransportOverview {
    /**
     *
     * @type {LocationInboundTransportOverview}
     * @memberof InboundTransportInboundTransportOverview
     */
    location?: LocationInboundTransportOverview;
    /**
     *
     * @type {string}
     * @memberof InboundTransportInboundTransportOverview
     */
    state?: string | null;
    /**
     *
     * @type {UserInboundTransportOverview}
     * @memberof InboundTransportInboundTransportOverview
     */
    assignee?: UserInboundTransportOverview | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InboundTransportInboundTransportOverview
     */
    lateProductIds?: Array<number> | null;
    /**
     *
     * @type {Array<InboundInboundTransportOverview>}
     * @memberof InboundTransportInboundTransportOverview
     */
    inbounds?: Array<InboundInboundTransportOverview>;
}
/**
 *
 * @export
 * @interface InboundTransportJsonldInboundTransportDetail
 */
export interface InboundTransportJsonldInboundTransportDetail {
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    context?: string | any;
    /**
     *
     * @type {LocationJsonldInboundTransportDetail}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    location?: LocationJsonldInboundTransportDetail;
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    state?: string | null;
    /**
     *
     * @type {UserJsonldInboundTransportDetail}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    assignee?: UserJsonldInboundTransportDetail | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    lateProductIds?: Array<number> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof InboundTransportJsonldInboundTransportDetail
     */
    inbounds?: Array<string>;
}
/**
 *
 * @export
 * @interface InboundTransportJsonldInboundTransportOverview
 */
export interface InboundTransportJsonldInboundTransportOverview {
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    type?: string;
    /**
     *
     * @type {LocationJsonldInboundTransportOverview}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    location?: LocationJsonldInboundTransportOverview;
    /**
     *
     * @type {string}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    state?: string | null;
    /**
     *
     * @type {UserJsonldInboundTransportOverview}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    assignee?: UserJsonldInboundTransportOverview | null;
    /**
     *
     * @type {Array<number>}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    lateProductIds?: Array<number> | null;
    /**
     *
     * @type {Array<InboundJsonldInboundTransportOverview>}
     * @memberof InboundTransportJsonldInboundTransportOverview
     */
    inbounds?: Array<InboundJsonldInboundTransportOverview>;
}
/**
 *
 * @export
 * @interface InboundTransportStateTransitionInboundTransportStateChange
 */
export interface InboundTransportStateTransitionInboundTransportStateChange {
    /**
     *
     * @type {string}
     * @memberof InboundTransportStateTransitionInboundTransportStateChange
     */
    transition: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {Array<WorkspaceJsonldWorkspaceDetail>}
     * @memberof InlineResponse200
     */
    hydramember: Array<WorkspaceJsonldWorkspaceDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse200
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse200
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse200
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     *
     * @type {Array<DistributorJsonldDistributorDetail>}
     * @memberof InlineResponse2001
     */
    hydramember: Array<DistributorJsonldDistributorDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2001
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2001
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2001
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     *
     * @type {Array<LocationMutationJsonldLocationMutationDetail>}
     * @memberof InlineResponse20010
     */
    hydramember: Array<LocationMutationJsonldLocationMutationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20010
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20010
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20010
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     *
     * @type {Array<LocationJsonldLocationDetail>}
     * @memberof InlineResponse20011
     */
    hydramember: Array<LocationJsonldLocationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20011
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20011
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20011
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
    /**
     *
     * @type {Array<LocationTypeJsonldLocationTypeDetail>}
     * @memberof InlineResponse20012
     */
    hydramember: Array<LocationTypeJsonldLocationTypeDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20012
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20012
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20012
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
    /**
     *
     * @type {Array<OutboundMutationJsonldOutboundMutationDetail>}
     * @memberof InlineResponse20013
     */
    hydramember: Array<OutboundMutationJsonldOutboundMutationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20013
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20013
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20013
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
    /**
     *
     * @type {Array<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail>}
     * @memberof InlineResponse20014
     */
    hydramember: Array<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20014
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20014
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20014
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
    /**
     *
     * @type {Array<OutboundItemReturnJsonldOutboundItemReturnDetail>}
     * @memberof InlineResponse20015
     */
    hydramember: Array<OutboundItemReturnJsonldOutboundItemReturnDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20015
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20015
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20015
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
    /**
     *
     * @type {Array<OutboundItemJsonldOutboundItemDetail>}
     * @memberof InlineResponse20016
     */
    hydramember: Array<OutboundItemJsonldOutboundItemDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20016
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20016
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20016
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
    /**
     *
     * @type {Array<OutboundJsonldOutboundOverview>}
     * @memberof InlineResponse20017
     */
    hydramember: Array<OutboundJsonldOutboundOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20017
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20017
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20017
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
    /**
     *
     * @type {Array<OutboundJsonldOutboundDetail>}
     * @memberof InlineResponse20018
     */
    hydramember: Array<OutboundJsonldOutboundDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20018
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20018
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20018
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
    /**
     *
     * @type {Array<ProductJsonldPackageProducts>}
     * @memberof InlineResponse20019
     */
    hydramember: Array<ProductJsonldPackageProducts>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20019
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20019
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20019
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     *
     * @type {Array<StockJsonldStockOverview>}
     * @memberof InlineResponse2002
     */
    hydramember: Array<StockJsonldStockOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2002
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2002
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2002
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
    /**
     *
     * @type {Array<PackingMaterialJsonldPackingMaterialDetail>}
     * @memberof InlineResponse20020
     */
    hydramember: Array<PackingMaterialJsonldPackingMaterialDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20020
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20020
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20020
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
    /**
     *
     * @type {Array<ProductPickListJsonldProductPickListDetail>}
     * @memberof InlineResponse20021
     */
    hydramember: Array<ProductPickListJsonldProductPickListDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20021
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20021
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20021
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
    /**
     *
     * @type {Array<ProductMutationJsonldProductMutationDetail>}
     * @memberof InlineResponse20022
     */
    hydramember: Array<ProductMutationJsonldProductMutationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20022
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20022
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20022
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
    /**
     *
     * @type {Array<ProductPickListJsonldProductPickListOverview>}
     * @memberof InlineResponse20023
     */
    hydramember: Array<ProductPickListJsonldProductPickListOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20023
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20023
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20023
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
    /**
     *
     * @type {Array<ProductJsonldProductOverview>}
     * @memberof InlineResponse20024
     */
    hydramember: Array<ProductJsonldProductOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20024
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20024
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20024
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20025
 */
export interface InlineResponse20025 {
    /**
     *
     * @type {Array<StockJsonldStockDetail>}
     * @memberof InlineResponse20025
     */
    hydramember: Array<StockJsonldStockDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20025
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20025
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20025
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20026
 */
export interface InlineResponse20026 {
    /**
     *
     * @type {Array<StockJsonldStockProducts>}
     * @memberof InlineResponse20026
     */
    hydramember: Array<StockJsonldStockProducts>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20026
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20026
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20026
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20027
 */
export interface InlineResponse20027 {
    /**
     *
     * @type {Array<StockMutationJsonldStockMutationDetail>}
     * @memberof InlineResponse20027
     */
    hydramember: Array<StockMutationJsonldStockMutationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20027
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20027
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20027
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20028
 */
export interface InlineResponse20028 {
    /**
     *
     * @type {Array<TranslationJsonldTranslationDetail>}
     * @memberof InlineResponse20028
     */
    hydramember: Array<TranslationJsonldTranslationDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20028
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20028
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20028
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20029
 */
export interface InlineResponse20029 {
    /**
     *
     * @type {Array<UserJsonldUserDetail>}
     * @memberof InlineResponse20029
     */
    hydramember: Array<UserJsonldUserDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20029
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20029
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20029
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     *
     * @type {Array<LocationJsonldLocationZone>}
     * @memberof InlineResponse2003
     */
    hydramember: Array<LocationJsonldLocationZone>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2003
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2003
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2003
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse20030
 */
export interface InlineResponse20030 {
    /**
     *
     * @type {Array<WorkspaceJsonldPrinterDetail>}
     * @memberof InlineResponse20030
     */
    hydramember: Array<WorkspaceJsonldPrinterDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse20030
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse20030
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse20030
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     *
     * @type {Array<InboundTransportJsonldInboundTransportDetail>}
     * @memberof InlineResponse2004
     */
    hydramember: Array<InboundTransportJsonldInboundTransportDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2004
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2004
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2004
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     *
     * @type {Array<InboundJsonldInboundDetail>}
     * @memberof InlineResponse2005
     */
    hydramember: Array<InboundJsonldInboundDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2005
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2005
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2005
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     *
     * @type {Array<InboundItemJsonldInboundItemDetail>}
     * @memberof InlineResponse2006
     */
    hydramember: Array<InboundItemJsonldInboundItemDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2006
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2006
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2006
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     *
     * @type {Array<InboundTransportJsonldInboundTransportOverview>}
     * @memberof InlineResponse2007
     */
    hydramember: Array<InboundTransportJsonldInboundTransportOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2007
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2007
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2007
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     *
     * @type {Array<InboundJsonldInboundOverview>}
     * @memberof InlineResponse2008
     */
    hydramember: Array<InboundJsonldInboundOverview>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2008
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2008
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2008
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     *
     * @type {Array<JobJsonldJobDetail>}
     * @memberof InlineResponse2009
     */
    hydramember: Array<JobJsonldJobDetail>;
    /**
     *
     * @type {number}
     * @memberof InlineResponse2009
     */
    hydratotalItems?: number;
    /**
     *
     * @type {InlineResponse200HydraView}
     * @memberof InlineResponse2009
     */
    hydraview?: InlineResponse200HydraView;
    /**
     *
     * @type {InlineResponse200HydraSearch}
     * @memberof InlineResponse2009
     */
    hydrasearch?: InlineResponse200HydraSearch;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearch
 */
export interface InlineResponse200HydraSearch {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    hydratemplate?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearch
     */
    hydravariableRepresentation?: string;
    /**
     *
     * @type {Array<InlineResponse200HydraSearchHydraMapping>}
     * @memberof InlineResponse200HydraSearch
     */
    hydramapping?: Array<InlineResponse200HydraSearchHydraMapping>;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraSearchHydraMapping
 */
export interface InlineResponse200HydraSearchHydraMapping {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    variable?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    property?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof InlineResponse200HydraSearchHydraMapping
     */
    required?: boolean;
}
/**
 *
 * @export
 * @interface InlineResponse200HydraView
 */
export interface InlineResponse200HydraView {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    hydrafirst?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    hydralast?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    hydraprevious?: string;
    /**
     *
     * @type {string}
     * @memberof InlineResponse200HydraView
     */
    hydranext?: string;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
    /**
     *
     * @type {string}
     * @memberof Job
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof Job
     */
    inQueue?: number | null;
    /**
     *
     * @type {number}
     * @memberof Job
     */
    jobsRunning?: number | null;
    /**
     *
     * @type {number}
     * @memberof Job
     */
    capableWorkers?: number | null;
}
/**
 *
 * @export
 * @interface JobJobDetail
 */
export interface JobJobDetail {
    /**
     *
     * @type {string}
     * @memberof JobJobDetail
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof JobJobDetail
     */
    inQueue?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJobDetail
     */
    jobsRunning?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJobDetail
     */
    capableWorkers?: number | null;
}
/**
 *
 * @export
 * @interface JobJsonld
 */
export interface JobJsonld {
    /**
     *
     * @type {string | any}
     * @memberof JobJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof JobJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof JobJsonld
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof JobJsonld
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof JobJsonld
     */
    inQueue?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJsonld
     */
    jobsRunning?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJsonld
     */
    capableWorkers?: number | null;
}
/**
 *
 * @export
 * @interface JobJsonldJobDetail
 */
export interface JobJsonldJobDetail {
    /**
     *
     * @type {string}
     * @memberof JobJsonldJobDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof JobJsonldJobDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof JobJsonldJobDetail
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof JobJsonldJobDetail
     */
    inQueue?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJsonldJobDetail
     */
    jobsRunning?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobJsonldJobDetail
     */
    capableWorkers?: number | null;
}
/**
 *
 * @export
 * @interface LocationInboundDetail
 */
export interface LocationInboundDetail {
    /**
     *
     * @type {string}
     * @memberof LocationInboundDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationInboundDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationInboundDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationInboundDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationInboundItemDetail
 */
export interface LocationInboundItemDetail {
    /**
     *
     * @type {string}
     * @memberof LocationInboundItemDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundItemDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundItemDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationInboundItemDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationInboundItemDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundItemDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundItemDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundItemDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundItemDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundItemDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationInboundItemDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationInboundTransportDetail
 */
export interface LocationInboundTransportDetail {
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationInboundTransportDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationInboundTransportDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationInboundTransportOverview
 */
export interface LocationInboundTransportOverview {
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationInboundTransportOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationInboundTransportOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationInboundTransportOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationInboundTransportOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldInboundDetail
 */
export interface LocationJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldInboundDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldInboundDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldInboundItemDetail
 */
export interface LocationJsonldInboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldInboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundItemDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldInboundItemDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundItemDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundItemDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundItemDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundItemDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundItemDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldInboundItemDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldInboundTransportDetail
 */
export interface LocationJsonldInboundTransportDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldInboundTransportDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldInboundTransportDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldInboundTransportDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldInboundTransportOverview
 */
export interface LocationJsonldInboundTransportOverview {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldInboundTransportOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldInboundTransportOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldInboundTransportOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldInboundTransportOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldInboundTransportOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldLocationDetail
 */
export interface LocationJsonldLocationDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldLocationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldLocationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldLocationDetail
     */
    level?: number | null;
    /**
     *
     * @type {Array<StockJsonldLocationDetail>}
     * @memberof LocationJsonldLocationDetail
     */
    stocks?: Array<StockJsonldLocationDetail>;
    /**
     *
     * @type {UserJsonldLocationDetail}
     * @memberof LocationJsonldLocationDetail
     */
    disabledBy?: UserJsonldLocationDetail | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    disabledAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationDetail
     */
    balancedAt?: string | null;
}
/**
 *
 * @export
 * @interface LocationJsonldLocationMutationDetail
 */
export interface LocationJsonldLocationMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldLocationMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationMutationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldLocationMutationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationMutationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationMutationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationMutationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationMutationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationMutationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldLocationMutationDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldLocationReturnInboundItems
 */
export interface LocationJsonldLocationReturnInboundItems {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldLocationReturnInboundItems
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationReturnInboundItems
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationReturnInboundItems
     */
    type?: string;
}
/**
 *
 * @export
 * @interface LocationJsonldLocationReturnOutbounds
 */
export interface LocationJsonldLocationReturnOutbounds {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldLocationReturnOutbounds
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationReturnOutbounds
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationReturnOutbounds
     */
    type?: string;
}
/**
 *
 * @export
 * @interface LocationJsonldLocationZone
 */
export interface LocationJsonldLocationZone {
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldLocationZone
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldLocationZone
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationZone
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationZone
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationZone
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationZone
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldLocationZone
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldLocationZone
     */
    level?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldLocationZone
     */
    stats?: Array<string> | null;
}
/**
 *
 * @export
 * @interface LocationJsonldOutboundDetail
 */
export interface LocationJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldOutboundDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldOutboundDetail
     */
    level?: number | null;
    /**
     *
     * @type {Array<StockJsonldOutboundDetail>}
     * @memberof LocationJsonldOutboundDetail
     */
    stocks?: Array<StockJsonldOutboundDetail>;
}
/**
 *
 * @export
 * @interface LocationJsonldOutboundOverview
 */
export interface LocationJsonldOutboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldOutboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldOutboundOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldOutboundOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldOutboundOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldOutboundOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldProductPickListDetail
 */
export interface LocationJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldProductPickListDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldProductPickListDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldProductPickListOverview
 */
export interface LocationJsonldProductPickListOverview {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldProductPickListOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldProductPickListOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldProductPickListOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldProductPickListOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldProductPickListOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldStockDetail
 */
export interface LocationJsonldStockDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldStockDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldStockDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldStockDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldStockMutationDetail
 */
export interface LocationJsonldStockMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldStockMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockMutationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldStockMutationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockMutationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockMutationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockMutationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockMutationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockMutationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldStockMutationDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationJsonldStockOverview
 */
export interface LocationJsonldStockOverview {
    /**
     *
     * @type {string | any}
     * @memberof LocationJsonldStockOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationJsonldStockOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationJsonldStockOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationJsonldStockOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationJsonldStockOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationLocationCreate
 */
export interface LocationLocationCreate {
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    row?: string | null;
    /**
     *
     * @type {LocationLocationCreate}
     * @memberof LocationLocationCreate
     */
    parent?: LocationLocationCreate | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    zone?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationLocationCreate
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationCreate
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationCreate
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationCreate
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationCreate
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationCreate
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationLocationCreate
     */
    level?: number | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationCreate
     */
    balancedAt?: string | null;
}
/**
 *
 * @export
 * @interface LocationLocationDetail
 */
export interface LocationLocationDetail {
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationLocationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationLocationDetail
     */
    level?: number | null;
    /**
     *
     * @type {Array<StockLocationDetail>}
     * @memberof LocationLocationDetail
     */
    stocks?: Array<StockLocationDetail>;
    /**
     *
     * @type {UserLocationDetail}
     * @memberof LocationLocationDetail
     */
    disabledBy?: UserLocationDetail | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    disabledAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationDetail
     */
    balancedAt?: string | null;
}
/**
 *
 * @export
 * @interface LocationLocationMutationDetail
 */
export interface LocationLocationMutationDetail {
    /**
     *
     * @type {string}
     * @memberof LocationLocationMutationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationMutationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationMutationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationLocationMutationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationLocationMutationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationMutationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationMutationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationMutationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationMutationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationMutationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationLocationMutationDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationLocationZone
 */
export interface LocationLocationZone {
    /**
     *
     * @type {string}
     * @memberof LocationLocationZone
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationZone
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationLocationZone
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationLocationZone
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationLocationZone
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationZone
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationZone
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationZone
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationZone
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationLocationZone
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationLocationZone
     */
    level?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationLocationZone
     */
    stats?: Array<string> | null;
}
/**
 *
 * @export
 * @interface LocationMutationJsonldLocationMutationDetail
 */
export interface LocationMutationJsonldLocationMutationDetail {
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {LocationJsonldLocationMutationDetail}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    location?: LocationJsonldLocationMutationDetail;
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserJsonldLocationMutationDetail}
     * @memberof LocationMutationJsonldLocationMutationDetail
     */
    mutatedBy?: UserJsonldLocationMutationDetail | null;
}
/**
 *
 * @export
 * @interface LocationMutationLocationMutationDetail
 */
export interface LocationMutationLocationMutationDetail {
    /**
     *
     * @type {LocationLocationMutationDetail}
     * @memberof LocationMutationLocationMutationDetail
     */
    location?: LocationLocationMutationDetail;
    /**
     *
     * @type {string}
     * @memberof LocationMutationLocationMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof LocationMutationLocationMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationMutationLocationMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationMutationLocationMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserLocationMutationDetail}
     * @memberof LocationMutationLocationMutationDetail
     */
    mutatedBy?: UserLocationMutationDetail | null;
}
/**
 *
 * @export
 * @interface LocationOutboundDetail
 */
export interface LocationOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof LocationOutboundDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationOutboundDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationOutboundDetail
     */
    level?: number | null;
    /**
     *
     * @type {Array<StockOutboundDetail>}
     * @memberof LocationOutboundDetail
     */
    stocks?: Array<StockOutboundDetail>;
}
/**
 *
 * @export
 * @interface LocationOutboundOverview
 */
export interface LocationOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof LocationOutboundOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationOutboundOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationOutboundOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationOutboundOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationOutboundOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationProductPickListDetail
 */
export interface LocationProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationProductPickListDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationProductPickListDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationProductPickListOverview
 */
export interface LocationProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationProductPickListOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationProductPickListOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationProductPickListOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationProductPickListOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationStockDetail
 */
export interface LocationStockDetail {
    /**
     *
     * @type {string}
     * @memberof LocationStockDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationStockDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationStockDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationStockDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationStockMutationDetail
 */
export interface LocationStockMutationDetail {
    /**
     *
     * @type {string}
     * @memberof LocationStockMutationDetail
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockMutationDetail
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockMutationDetail
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationStockMutationDetail
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationStockMutationDetail
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockMutationDetail
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockMutationDetail
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockMutationDetail
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockMutationDetail
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockMutationDetail
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationStockMutationDetail
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationStockOverview
 */
export interface LocationStockOverview {
    /**
     *
     * @type {string}
     * @memberof LocationStockOverview
     */
    barcode: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockOverview
     */
    row?: string | null;
    /**
     *
     * @type {string}
     * @memberof LocationStockOverview
     */
    locationType: string;
    /**
     *
     * @type {string}
     * @memberof LocationStockOverview
     */
    zoneType?: string | null;
    /**
     *
     * @type {Array<string>}
     * @memberof LocationStockOverview
     */
    zones?: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockOverview
     */
    mobile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockOverview
     */
    manco?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockOverview
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockOverview
     */
    full?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof LocationStockOverview
     */
    inUse?: boolean;
    /**
     *
     * @type {number}
     * @memberof LocationStockOverview
     */
    level?: number | null;
}
/**
 *
 * @export
 * @interface LocationTypeJsonldLocationTypeDetail
 */
export interface LocationTypeJsonldLocationTypeDetail {
    /**
     *
     * @type {string}
     * @memberof LocationTypeJsonldLocationTypeDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationTypeJsonldLocationTypeDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof LocationTypeJsonldLocationTypeDetail
     */
    context?: string | any;
}
/**
 *
 * @export
 * @interface LocationUploadFileJsonldLocationCreate
 */
export interface LocationUploadFileJsonldLocationCreate {
    /**
     *
     * @type {string | any}
     * @memberof LocationUploadFileJsonldLocationCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof LocationUploadFileJsonldLocationCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof LocationUploadFileJsonldLocationCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundAddressJsonldOutboundAddressDetail
 */
export interface OutboundAddressJsonldOutboundAddressDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    company?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    streetName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    houseNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    houseNumberAddition?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    digicode?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    postalCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressJsonldOutboundAddressDetail
     */
    countryCode?: string | null;
}
/**
 *
 * @export
 * @interface OutboundAddressOutboundAddressDetail
 */
export interface OutboundAddressOutboundAddressDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    company?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    streetName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    houseNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    houseNumberAddition?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    digicode?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    postalCode?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressDetail
     */
    countryCode?: string | null;
}
/**
 *
 * @export
 * @interface OutboundAddressOutboundAddressEdit
 */
export interface OutboundAddressOutboundAddressEdit {
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    company?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    streetName?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    houseNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    houseNumberAddition?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    digicode?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundAddressOutboundAddressEdit
     */
    postalCode?: string | null;
}
/**
 *
 * @export
 * @interface OutboundExternalOrder
 */
export interface OutboundExternalOrder {
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrder
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrder
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrder
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrder
     */
    distributionZone?: string | null;
    /**
     *
     * @type {Array<ExternalOrderItem>}
     * @memberof OutboundExternalOrder
     */
    items?: Array<ExternalOrderItem>;
    /**
     *
     * @type {boolean}
     * @memberof OutboundExternalOrder
     */
    includePromoMaterial?: boolean;
}
/**
 *
 * @export
 * @interface OutboundExternalOrderJsonld
 */
export interface OutboundExternalOrderJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundExternalOrderJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    reference: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    createdAt: string;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundExternalOrderJsonld
     */
    distributionZone?: string | null;
    /**
     *
     * @type {Array<ExternalOrderItemJsonld>}
     * @memberof OutboundExternalOrderJsonld
     */
    items?: Array<ExternalOrderItemJsonld>;
    /**
     *
     * @type {boolean}
     * @memberof OutboundExternalOrderJsonld
     */
    includePromoMaterial?: boolean;
}
/**
 *
 * @export
 * @interface OutboundInboundDetail
 */
export interface OutboundInboundDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundInboundDetail
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundItemInboundDetail
 */
export interface OutboundItemInboundDetail {
    /**
     *
     * @type {ProductInboundDetail}
     * @memberof OutboundItemInboundDetail
     */
    product?: ProductInboundDetail;
    /**
     *
     * @type {OutboundInboundDetail}
     * @memberof OutboundItemInboundDetail
     */
    outbound?: OutboundInboundDetail;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldInboundDetail
 */
export interface OutboundItemJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldInboundDetail}
     * @memberof OutboundItemJsonldInboundDetail
     */
    product?: ProductJsonldInboundDetail;
    /**
     *
     * @type {OutboundJsonldInboundDetail}
     * @memberof OutboundItemJsonldInboundDetail
     */
    outbound?: OutboundJsonldInboundDetail;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundDetail
 */
export interface OutboundItemJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldOutboundDetail}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    product?: ProductJsonldOutboundDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {number}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    options?: string | null;
    /**
     *
     * @type {LocationJsonldOutboundDetail}
     * @memberof OutboundItemJsonldOutboundDetail
     */
    pickLocation?: LocationJsonldOutboundDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundItemDetail
 */
export interface OutboundItemJsonldOutboundItemDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {ProductJsonldOutboundItemDetail}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    product?: ProductJsonldOutboundItemDetail;
    /**
     *
     * @type {OutboundJsonldOutboundItemDetail}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    outbound?: OutboundJsonldOutboundItemDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    state?: string | null;
    /**
     *
     * @type {number}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    options?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemDetail
     */
    pickLocation?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundItemReturnDetail
 */
export interface OutboundItemJsonldOutboundItemReturnDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundItemReturnDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemReturnDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemReturnDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldOutboundItemReturnDetail}
     * @memberof OutboundItemJsonldOutboundItemReturnDetail
     */
    product?: ProductJsonldOutboundItemReturnDetail;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundItemReturnInstructionDetail
 */
export interface OutboundItemJsonldOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundItemReturnInstructionDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemReturnInstructionDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundItemReturnInstructionDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemJsonldOutboundItemReturnInstructionDetail
     */
    product?: ProductJsonldOutboundItemReturnInstructionDetail;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundMutationDetail
 */
export interface OutboundItemJsonldOutboundMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundMutationDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldOutboundMutationDetail}
     * @memberof OutboundItemJsonldOutboundMutationDetail
     */
    product?: ProductJsonldOutboundMutationDetail;
    /**
     *
     * @type {number}
     * @memberof OutboundItemJsonldOutboundMutationDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundItemJsonldOutboundOverview
 */
export interface OutboundItemJsonldOutboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemJsonldOutboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldOutboundOverview}
     * @memberof OutboundItemJsonldOutboundOverview
     */
    product?: ProductJsonldOutboundOverview;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundDetail
 */
export interface OutboundItemOutboundDetail {
    /**
     *
     * @type {ProductOutboundDetail}
     * @memberof OutboundItemOutboundDetail
     */
    product?: ProductOutboundDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {number}
     * @memberof OutboundItemOutboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundDetail
     */
    options?: string | null;
    /**
     *
     * @type {LocationOutboundDetail}
     * @memberof OutboundItemOutboundDetail
     */
    pickLocation?: LocationOutboundDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundItemDetail
 */
export interface OutboundItemOutboundItemDetail {
    /**
     *
     * @type {ProductOutboundItemDetail}
     * @memberof OutboundItemOutboundItemDetail
     */
    product?: ProductOutboundItemDetail;
    /**
     *
     * @type {OutboundOutboundItemDetail}
     * @memberof OutboundItemOutboundItemDetail
     */
    outbound?: OutboundOutboundItemDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundItemDetail
     */
    state?: string | null;
    /**
     *
     * @type {number}
     * @memberof OutboundItemOutboundItemDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundItemDetail
     */
    options?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundItemDetail
     */
    pickLocation?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundItemEdit
 */
export interface OutboundItemOutboundItemEdit {
    /**
     *
     * @type {string}
     * @memberof OutboundItemOutboundItemEdit
     */
    product?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemOutboundItemEdit
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundItemReturnDetail
 */
export interface OutboundItemOutboundItemReturnDetail {
    /**
     *
     * @type {ProductOutboundItemReturnDetail}
     * @memberof OutboundItemOutboundItemReturnDetail
     */
    product?: ProductOutboundItemReturnDetail;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundItemReturnInstructionDetail
 */
export interface OutboundItemOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {ProductOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemOutboundItemReturnInstructionDetail
     */
    product?: ProductOutboundItemReturnInstructionDetail;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundMutationDetail
 */
export interface OutboundItemOutboundMutationDetail {
    /**
     *
     * @type {ProductOutboundMutationDetail}
     * @memberof OutboundItemOutboundMutationDetail
     */
    product?: ProductOutboundMutationDetail;
    /**
     *
     * @type {number}
     * @memberof OutboundItemOutboundMutationDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundItemOutboundOverview
 */
export interface OutboundItemOutboundOverview {
    /**
     *
     * @type {ProductOutboundOverview}
     * @memberof OutboundItemOutboundOverview
     */
    product?: ProductOutboundOverview;
}
/**
 *
 * @export
 * @interface OutboundItemReturnInboundDetail
 */
export interface OutboundItemReturnInboundDetail {
    /**
     *
     * @type {OutboundItemInboundDetail}
     * @memberof OutboundItemReturnInboundDetail
     */
    outboundItem?: OutboundItemInboundDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInboundDetail
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnInboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInboundDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoInboundDetail>}
     * @memberof OutboundItemReturnInboundDetail
     */
    photos?: Array<OutboundItemReturnPhotoInboundDetail>;
}
/**
 *
 * @export
 * @interface OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
 */
export interface OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
     */
    outboundItem?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate
     */
    comment: string;
}
/**
 *
 * @export
 * @interface OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
 */
export interface OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    context?: string | any;
    /**
     *
     * @type {OutboundItemJsonldOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    outboundItem?: OutboundItemJsonldOutboundItemReturnInstructionDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    comment: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    addedAt?: string;
    /**
     *
     * @type {UserJsonldOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail
     */
    addedBy?: UserJsonldOutboundItemReturnInstructionDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnInstructionOutboundItemReturnInstructionCreate
 */
export interface OutboundItemReturnInstructionOutboundItemReturnInstructionCreate {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionCreate
     */
    outboundItem?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionCreate
     */
    comment: string;
}
/**
 *
 * @export
 * @interface OutboundItemReturnInstructionOutboundItemReturnInstructionDetail
 */
export interface OutboundItemReturnInstructionOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {OutboundItemOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionDetail
     */
    outboundItem?: OutboundItemOutboundItemReturnInstructionDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionDetail
     */
    comment: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionDetail
     */
    addedAt?: string;
    /**
     *
     * @type {UserOutboundItemReturnInstructionDetail}
     * @memberof OutboundItemReturnInstructionOutboundItemReturnInstructionDetail
     */
    addedBy?: UserOutboundItemReturnInstructionDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnJsonldInboundDetail
 */
export interface OutboundItemReturnJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {OutboundItemJsonldInboundDetail}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    outboundItem?: OutboundItemJsonldInboundDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoJsonldInboundDetail>}
     * @memberof OutboundItemReturnJsonldInboundDetail
     */
    photos?: Array<OutboundItemReturnPhotoJsonldInboundDetail>;
}
/**
 *
 * @export
 * @interface OutboundItemReturnJsonldOutboundItemReturnCreate
 */
export interface OutboundItemReturnJsonldOutboundItemReturnCreate {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    outboundItem?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    inbound?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoJsonldOutboundItemReturnCreate>}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnCreate
     */
    photos?: Array<OutboundItemReturnPhotoJsonldOutboundItemReturnCreate>;
}
/**
 *
 * @export
 * @interface OutboundItemReturnJsonldOutboundItemReturnDetail
 */
export interface OutboundItemReturnJsonldOutboundItemReturnDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    context?: string | any;
    /**
     *
     * @type {OutboundItemJsonldOutboundItemReturnDetail}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    outboundItem?: OutboundItemJsonldOutboundItemReturnDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    inbound?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoJsonldOutboundItemReturnDetail>}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    photos?: Array<OutboundItemReturnPhotoJsonldOutboundItemReturnDetail>;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    addedAt?: string;
    /**
     *
     * @type {UserJsonldOutboundItemReturnDetail}
     * @memberof OutboundItemReturnJsonldOutboundItemReturnDetail
     */
    addedBy?: UserJsonldOutboundItemReturnDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnOutboundItemReturnCreate
 */
export interface OutboundItemReturnOutboundItemReturnCreate {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    outboundItem?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    inbound?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoOutboundItemReturnCreate>}
     * @memberof OutboundItemReturnOutboundItemReturnCreate
     */
    photos?: Array<OutboundItemReturnPhotoOutboundItemReturnCreate>;
}
/**
 *
 * @export
 * @interface OutboundItemReturnOutboundItemReturnDetail
 */
export interface OutboundItemReturnOutboundItemReturnDetail {
    /**
     *
     * @type {OutboundItemOutboundItemReturnDetail}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    outboundItem?: OutboundItemOutboundItemReturnDetail;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    inbound?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    action?: string;
    /**
     *
     * @type {number}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    comment?: string | null;
    /**
     *
     * @type {Array<OutboundItemReturnPhotoOutboundItemReturnDetail>}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    photos?: Array<OutboundItemReturnPhotoOutboundItemReturnDetail>;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    addedAt?: string;
    /**
     *
     * @type {UserOutboundItemReturnDetail}
     * @memberof OutboundItemReturnOutboundItemReturnDetail
     */
    addedBy?: UserOutboundItemReturnDetail | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoInboundDetail
 */
export interface OutboundItemReturnPhotoInboundDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoJsonldInboundDetail
 */
export interface OutboundItemReturnPhotoJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnPhotoJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldInboundDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldInboundDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
 */
export interface OutboundItemReturnPhotoJsonldOutboundItemReturnCreate {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnCreate
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
 */
export interface OutboundItemReturnPhotoJsonldOutboundItemReturnDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoJsonldOutboundItemReturnDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoOutboundItemReturnCreate
 */
export interface OutboundItemReturnPhotoOutboundItemReturnCreate {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoOutboundItemReturnCreate
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoOutboundItemReturnCreate
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotoOutboundItemReturnDetail
 */
export interface OutboundItemReturnPhotoOutboundItemReturnDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoOutboundItemReturnDetail
     */
    fileName?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotoOutboundItemReturnDetail
     */
    imageContents?: string | null;
}
/**
 *
 * @export
 * @interface OutboundItemReturnPhotosJsonldOutboundItemReturnCreate
 */
export interface OutboundItemReturnPhotosJsonldOutboundItemReturnCreate {
    /**
     *
     * @type {string | any}
     * @memberof OutboundItemReturnPhotosJsonldOutboundItemReturnCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotosJsonldOutboundItemReturnCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundItemReturnPhotosJsonldOutboundItemReturnCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundItemStateTransitionOutboundItemStateChange
 */
export interface OutboundItemStateTransitionOutboundItemStateChange {
    /**
     *
     * @type {string}
     * @memberof OutboundItemStateTransitionOutboundItemStateChange
     */
    transition: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldInboundDetail
 */
export interface OutboundJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldInboundDetail
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundDetail
 */
export interface OutboundJsonldOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {Array<OutboundItemJsonldOutboundDetail>}
     * @memberof OutboundJsonldOutboundDetail
     */
    items?: Array<OutboundItemJsonldOutboundDetail>;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    assignedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    completedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {LocationJsonldOutboundDetail}
     * @memberof OutboundJsonldOutboundDetail
     */
    location?: LocationJsonldOutboundDetail | null;
    /**
     *
     * @type {UserJsonldOutboundDetail}
     * @memberof OutboundJsonldOutboundDetail
     */
    assignee?: UserJsonldOutboundDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    shippingCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    storeLocale?: string;
    /**
     *
     * @type {DistributorJsonldOutboundDetail}
     * @memberof OutboundJsonldOutboundDetail
     */
    distributor?: DistributorJsonldOutboundDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    cutOffTime?: string;
    /**
     *
     * @type {boolean}
     * @memberof OutboundJsonldOutboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    distributionZone?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof OutboundJsonldOutboundDetail
     */
    includePromoMaterial?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDetail
     */
    workspace?: string | null;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundDocumentDetail
 */
export interface OutboundJsonldOutboundDocumentDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundDocumentDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDocumentDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundDocumentDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundItemDetail
 */
export interface OutboundJsonldOutboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundItemDetail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundOverview
 */
export interface OutboundJsonldOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    state?: string | null;
    /**
     *
     * @type {Array<OutboundItemJsonldOutboundOverview>}
     * @memberof OutboundJsonldOutboundOverview
     */
    items?: Array<OutboundItemJsonldOutboundOverview>;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    assignedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    completedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {LocationJsonldOutboundOverview}
     * @memberof OutboundJsonldOutboundOverview
     */
    location?: LocationJsonldOutboundOverview | null;
    /**
     *
     * @type {UserJsonldOutboundOverview}
     * @memberof OutboundJsonldOutboundOverview
     */
    assignee?: UserJsonldOutboundOverview | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    shippingCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    storeLocale?: string;
    /**
     *
     * @type {DistributorJsonldOutboundOverview}
     * @memberof OutboundJsonldOutboundOverview
     */
    distributor?: DistributorJsonldOutboundOverview | null;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    cutOffTime?: string;
    /**
     *
     * @type {boolean}
     * @memberof OutboundJsonldOutboundOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof OutboundJsonldOutboundOverview
     */
    includePromoMaterial?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundOverview
     */
    workspace?: string | null;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundPackingMaterialDetail
 */
export interface OutboundJsonldOutboundPackingMaterialDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundPackingMaterialDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundPackingMaterialDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundPackingMaterialDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundShippingLabelDetail
 */
export interface OutboundJsonldOutboundShippingLabelDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundShippingLabelDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundShippingLabelDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundShippingLabelDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldOutboundShippingMethodDetail
 */
export interface OutboundJsonldOutboundShippingMethodDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldOutboundShippingMethodDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundShippingMethodDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldOutboundShippingMethodDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldPackingMaterialDetail
 */
export interface OutboundJsonldPackingMaterialDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldPackingMaterialDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldPackingMaterialDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldPackingMaterialDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldProductPickListDetail
 */
export interface OutboundJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListDetail
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldProductPickListOverview
 */
export interface OutboundJsonldProductPickListOverview {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldProductPickListOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldProductPickListOverview
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundJsonldReturnRequestDetail
 */
export interface OutboundJsonldReturnRequestDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundJsonldReturnRequestDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldReturnRequestDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundJsonldReturnRequestDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundLogOutboundJsonld
 */
export interface OutboundLogOutboundJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundLogOutboundJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundLogOutboundJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundLogOutboundJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundMutationJsonldOutboundMutationDetail
 */
export interface OutboundMutationJsonldOutboundMutationDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    outbound?: string;
    /**
     *
     * @type {OutboundItemJsonldOutboundMutationDetail}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    outboundItem?: OutboundItemJsonldOutboundMutationDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserJsonldOutboundMutationDetail}
     * @memberof OutboundMutationJsonldOutboundMutationDetail
     */
    mutatedBy?: UserJsonldOutboundMutationDetail | null;
}
/**
 *
 * @export
 * @interface OutboundMutationOutboundMutationDetail
 */
export interface OutboundMutationOutboundMutationDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    outbound?: string;
    /**
     *
     * @type {OutboundItemOutboundMutationDetail}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    outboundItem?: OutboundItemOutboundMutationDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserOutboundMutationDetail}
     * @memberof OutboundMutationOutboundMutationDetail
     */
    mutatedBy?: UserOutboundMutationDetail | null;
}
/**
 *
 * @export
 * @interface OutboundOutboundDetail
 */
export interface OutboundOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    state?: string | null;
    /**
     *
     * @type {Array<OutboundItemOutboundDetail>}
     * @memberof OutboundOutboundDetail
     */
    items?: Array<OutboundItemOutboundDetail>;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    assignedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    completedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {LocationOutboundDetail}
     * @memberof OutboundOutboundDetail
     */
    location?: LocationOutboundDetail | null;
    /**
     *
     * @type {UserOutboundDetail}
     * @memberof OutboundOutboundDetail
     */
    assignee?: UserOutboundDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    shippingCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    storeLocale?: string;
    /**
     *
     * @type {DistributorOutboundDetail}
     * @memberof OutboundOutboundDetail
     */
    distributor?: DistributorOutboundDetail | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    cutOffTime?: string;
    /**
     *
     * @type {boolean}
     * @memberof OutboundOutboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    distributionZone?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof OutboundOutboundDetail
     */
    includePromoMaterial?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDetail
     */
    workspace?: string | null;
}
/**
 *
 * @export
 * @interface OutboundOutboundDistributorJsonld
 */
export interface OutboundOutboundDistributorJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundOutboundDistributorJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDistributorJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundDistributorJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundOutboundEdit
 */
export interface OutboundOutboundEdit {
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundEdit
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundEdit
     */
    cutOffTime?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundEdit
     */
    distributionZone?: string | null;
}
/**
 *
 * @export
 * @interface OutboundOutboundItemDetail
 */
export interface OutboundOutboundItemDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundItemDetail
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundItemDetail
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundItemDetail
     */
    createdAt?: string;
}
/**
 *
 * @export
 * @interface OutboundOutboundOverview
 */
export interface OutboundOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    reference?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    state?: string | null;
    /**
     *
     * @type {Array<OutboundItemOutboundOverview>}
     * @memberof OutboundOutboundOverview
     */
    items?: Array<OutboundItemOutboundOverview>;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    assignedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    completedAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    requestedShipDate?: string | null;
    /**
     *
     * @type {LocationOutboundOverview}
     * @memberof OutboundOutboundOverview
     */
    location?: LocationOutboundOverview | null;
    /**
     *
     * @type {UserOutboundOverview}
     * @memberof OutboundOutboundOverview
     */
    assignee?: UserOutboundOverview | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    shippingCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    storeLocale?: string;
    /**
     *
     * @type {DistributorOutboundOverview}
     * @memberof OutboundOutboundOverview
     */
    distributor?: DistributorOutboundOverview | null;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    cutOffTime?: string;
    /**
     *
     * @type {boolean}
     * @memberof OutboundOutboundOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof OutboundOutboundOverview
     */
    includePromoMaterial?: boolean;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundOverview
     */
    workspace?: string | null;
}
/**
 *
 * @export
 * @interface OutboundOutboundShippingMethodJsonld
 */
export interface OutboundOutboundShippingMethodJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundOutboundShippingMethodJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundShippingMethodJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundOutboundShippingMethodJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
 */
export interface OutboundPackingMaterialJsonldOutboundPackingMaterialCreate {
    /**
     *
     * @type {string | any}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    outbound: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    packingMaterial: string;
    /**
     *
     * @type {number}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialCreate
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
 */
export interface OutboundPackingMaterialJsonldOutboundPackingMaterialDetail {
    /**
     *
     * @type {string | any}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    outbound: string;
    /**
     *
     * @type {PackingMaterialJsonldOutboundPackingMaterialDetail}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    packingMaterial: PackingMaterialJsonldOutboundPackingMaterialDetail;
    /**
     *
     * @type {number}
     * @memberof OutboundPackingMaterialJsonldOutboundPackingMaterialDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundPackingMaterialOutboundPackingMaterialCreate
 */
export interface OutboundPackingMaterialOutboundPackingMaterialCreate {
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialCreate
     */
    outbound: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialCreate
     */
    packingMaterial: string;
    /**
     *
     * @type {number}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialCreate
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundPackingMaterialOutboundPackingMaterialDetail
 */
export interface OutboundPackingMaterialOutboundPackingMaterialDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialDetail
     */
    outbound: string;
    /**
     *
     * @type {PackingMaterialOutboundPackingMaterialDetail}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialDetail
     */
    packingMaterial: PackingMaterialOutboundPackingMaterialDetail;
    /**
     *
     * @type {number}
     * @memberof OutboundPackingMaterialOutboundPackingMaterialDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface OutboundPrintOutboundDocumentsJsonld
 */
export interface OutboundPrintOutboundDocumentsJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundPrintOutboundDocumentsJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundPrintOutboundDocumentsJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPrintOutboundDocumentsJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundPrintOutboundLabelsJsonld
 */
export interface OutboundPrintOutboundLabelsJsonld {
    /**
     *
     * @type {string | any}
     * @memberof OutboundPrintOutboundLabelsJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof OutboundPrintOutboundLabelsJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof OutboundPrintOutboundLabelsJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface OutboundProductPickListDetail
 */
export interface OutboundProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof OutboundProductPickListDetail
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundProductPickListOverview
 */
export interface OutboundProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof OutboundProductPickListOverview
     */
    reference?: string;
}
/**
 *
 * @export
 * @interface OutboundStateTransitionOutboundStateChange
 */
export interface OutboundStateTransitionOutboundStateChange {
    /**
     *
     * @type {string}
     * @memberof OutboundStateTransitionOutboundStateChange
     */
    transition: string;
}
/**
 *
 * @export
 * @interface PackingMaterialJsonldOutboundPackingMaterialDetail
 */
export interface PackingMaterialJsonldOutboundPackingMaterialDetail {
    /**
     *
     * @type {string | any}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    barcode?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    label?: string;
    /**
     *
     * @type {boolean}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    colorCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    maxWidth?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldOutboundPackingMaterialDetail
     */
    maxHeight?: number | null;
}
/**
 *
 * @export
 * @interface PackingMaterialJsonldPackingMaterialDetail
 */
export interface PackingMaterialJsonldPackingMaterialDetail {
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    barcode?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    label?: string;
    /**
     *
     * @type {boolean}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    colorCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    maxWidth?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialJsonldPackingMaterialDetail
     */
    maxHeight?: number | null;
}
/**
 *
 * @export
 * @interface PackingMaterialOutboundPackingMaterialDetail
 */
export interface PackingMaterialOutboundPackingMaterialDetail {
    /**
     *
     * @type {string}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    barcode?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    label?: string;
    /**
     *
     * @type {boolean}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    colorCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    maxWidth?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialOutboundPackingMaterialDetail
     */
    maxHeight?: number | null;
}
/**
 *
 * @export
 * @interface PackingMaterialPackingMaterialDetail
 */
export interface PackingMaterialPackingMaterialDetail {
    /**
     *
     * @type {string}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    barcode?: string;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    label?: string;
    /**
     *
     * @type {boolean}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {string}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    colorCode?: string | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    maxLength?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    maxWidth?: number | null;
    /**
     *
     * @type {number}
     * @memberof PackingMaterialPackingMaterialDetail
     */
    maxHeight?: number | null;
}
/**
 *
 * @export
 * @interface ProductInboundDetail
 */
export interface ProductInboundDetail {
    /**
     *
     * @type {string}
     * @memberof ProductInboundDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductInboundDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductInboundDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductInboundDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductInboundDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductInboundDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductInboundDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductInboundItemDetail
 */
export interface ProductInboundItemDetail {
    /**
     *
     * @type {string}
     * @memberof ProductInboundItemDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductInboundItemDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductInboundItemDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundItemDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundItemDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundItemDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductInboundItemDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundItemDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductInboundItemDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductInboundItemDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductInboundItemDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductJsonldInboundDetail
 */
export interface ProductJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldInboundDetail>}
     * @memberof ProductJsonldInboundDetail
     */
    packages?: Array<ProductPackageJsonldInboundDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldInboundItemDetail
 */
export interface ProductJsonldInboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldInboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundItemDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundItemDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundItemDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldInboundItemDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundItemDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldInboundItemDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldInboundItemDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldInboundItemDetail>}
     * @memberof ProductJsonldInboundItemDetail
     */
    packages?: Array<ProductPackageJsonldInboundItemDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldLocationDetail
 */
export interface ProductJsonldLocationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldLocationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldLocationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldLocationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldLocationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldLocationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldLocationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldLocationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldLocationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldLocationDetail>}
     * @memberof ProductJsonldLocationDetail
     */
    packages?: Array<ProductPackageJsonldLocationDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundDetail
 */
export interface ProductJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldOutboundDetail>}
     * @memberof ProductJsonldOutboundDetail
     */
    packages?: Array<ProductPackageJsonldOutboundDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundItemDetail
 */
export interface ProductJsonldOutboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundItemDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundItemDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundItemDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundItemDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundItemDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundItemDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldOutboundItemDetail>}
     * @memberof ProductJsonldOutboundItemDetail
     */
    packages?: Array<ProductPackageJsonldOutboundItemDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundItemReturnDetail
 */
export interface ProductJsonldOutboundItemReturnDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundItemReturnDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnDetail
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundItemReturnInstructionDetail
 */
export interface ProductJsonldOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundItemReturnInstructionDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnInstructionDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnInstructionDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundItemReturnInstructionDetail
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundMutationDetail
 */
export interface ProductJsonldOutboundMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldOutboundMutationDetail>}
     * @memberof ProductJsonldOutboundMutationDetail
     */
    packages?: Array<ProductPackageJsonldOutboundMutationDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldOutboundOverview
 */
export interface ProductJsonldOutboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldOutboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldOutboundOverview
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductJsonldPackageProducts
 */
export interface ProductJsonldPackageProducts {
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldPackageProducts
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldPackageProducts
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldPackageProducts
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldPackageProducts
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldPackageProducts
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldPackageProducts
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldPackageProducts
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldPackageProducts>}
     * @memberof ProductJsonldPackageProducts
     */
    packages?: Array<ProductPackageJsonldPackageProducts>;
}
/**
 *
 * @export
 * @interface ProductJsonldProductCreate
 */
export interface ProductJsonldProductCreate {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductCreate
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductCreate
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductCreate
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductCreate
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductCreate
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    inboundNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    shipmentNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductCreate
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductCreate
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldProductCreate>}
     * @memberof ProductJsonldProductCreate
     */
    packages?: Array<ProductPackageJsonldProductCreate>;
    /**
     *
     * @type {Array<ProductJsonldProductCreate>}
     * @memberof ProductJsonldProductCreate
     */
    sameProducts?: Array<ProductJsonldProductCreate>;
}
/**
 *
 * @export
 * @interface ProductJsonldProductDetail
 */
export interface ProductJsonldProductDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    shipmentNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldProductDetail>}
     * @memberof ProductJsonldProductDetail
     */
    packages?: Array<ProductPackageJsonldProductDetail>;
    /**
     *
     * @type {Array<ProductJsonldProductDetail>}
     * @memberof ProductJsonldProductDetail
     */
    sameProducts?: Array<ProductJsonldProductDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldProductLocationZoneDetail
 */
export interface ProductJsonldProductLocationZoneDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    inboundNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldProductLocationZoneDetail>}
     * @memberof ProductJsonldProductLocationZoneDetail
     */
    packages?: Array<ProductPackageJsonldProductLocationZoneDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldProductMutationDetail
 */
export interface ProductJsonldProductMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductMutationDetail
     */
    pickQtyThreshold?: number;
}
/**
 *
 * @export
 * @interface ProductJsonldProductOverview
 */
export interface ProductJsonldProductOverview {
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductOverview
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductOverview
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductOverview
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductOverview
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductOverview
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductOverview
     */
    pickQtyThreshold?: number;
}
/**
 *
 * @export
 * @interface ProductJsonldProductPickDetail
 */
export interface ProductJsonldProductPickDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductPickDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldProductPickDetail>}
     * @memberof ProductJsonldProductPickDetail
     */
    packages?: Array<ProductPackageJsonldProductPickDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldProductPickListDetail
 */
export interface ProductJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickListDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickListDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickListDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldProductPickListDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickListDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldProductPickListDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldProductPickListDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldProductPickListDetail>}
     * @memberof ProductJsonldProductPickListDetail
     */
    packages?: Array<ProductPackageJsonldProductPickListDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldStockDetail
 */
export interface ProductJsonldStockDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldStockDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldStockDetail>}
     * @memberof ProductJsonldStockDetail
     */
    packages?: Array<ProductPackageJsonldStockDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldStockMutationDetail
 */
export interface ProductJsonldStockMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldStockMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockMutationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldStockMutationDetail>}
     * @memberof ProductJsonldStockMutationDetail
     */
    packages?: Array<ProductPackageJsonldStockMutationDetail>;
}
/**
 *
 * @export
 * @interface ProductJsonldStockOverview
 */
export interface ProductJsonldStockOverview {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldStockOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockOverview
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockOverview
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockOverview
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockOverview
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockOverview
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockOverview
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldStockOverview>}
     * @memberof ProductJsonldStockOverview
     */
    packages?: Array<ProductPackageJsonldStockOverview>;
}
/**
 *
 * @export
 * @interface ProductJsonldStockProducts
 */
export interface ProductJsonldStockProducts {
    /**
     *
     * @type {string | any}
     * @memberof ProductJsonldStockProducts
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockProducts
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockProducts
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockProducts
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductJsonldStockProducts
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockProducts
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductJsonldStockProducts
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductJsonldStockProducts
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageJsonldStockProducts>}
     * @memberof ProductJsonldStockProducts
     */
    packages?: Array<ProductPackageJsonldStockProducts>;
}
/**
 *
 * @export
 * @interface ProductLocationDetail
 */
export interface ProductLocationDetail {
    /**
     *
     * @type {string}
     * @memberof ProductLocationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductLocationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductLocationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductLocationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductLocationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductLocationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductLocationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductLocationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductLocationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductLocationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductLocationDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductLocationZoneJsonldProductLocationZoneDetail
 */
export interface ProductLocationZoneJsonldProductLocationZoneDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductLocationZoneJsonldProductLocationZoneDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductLocationZoneJsonldProductLocationZoneDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductLocationZoneJsonldProductLocationZoneDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldProductLocationZoneDetail}
     * @memberof ProductLocationZoneJsonldProductLocationZoneDetail
     */
    product?: ProductJsonldProductLocationZoneDetail;
    /**
     *
     * @type {Array<number>}
     * @memberof ProductLocationZoneJsonldProductLocationZoneDetail
     */
    quantities?: Array<number>;
}
/**
 *
 * @export
 * @interface ProductLocationZoneProductLocationZoneDetail
 */
export interface ProductLocationZoneProductLocationZoneDetail {
    /**
     *
     * @type {ProductProductLocationZoneDetail}
     * @memberof ProductLocationZoneProductLocationZoneDetail
     */
    product?: ProductProductLocationZoneDetail;
    /**
     *
     * @type {Array<number>}
     * @memberof ProductLocationZoneProductLocationZoneDetail
     */
    quantities?: Array<number>;
}
/**
 *
 * @export
 * @interface ProductMutationJsonldProductMutationDetail
 */
export interface ProductMutationJsonldProductMutationDetail {
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {ProductJsonldProductMutationDetail}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    product?: ProductJsonldProductMutationDetail;
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserJsonldProductMutationDetail}
     * @memberof ProductMutationJsonldProductMutationDetail
     */
    mutatedBy?: UserJsonldProductMutationDetail | null;
}
/**
 *
 * @export
 * @interface ProductMutationProductMutationDetail
 */
export interface ProductMutationProductMutationDetail {
    /**
     *
     * @type {ProductProductMutationDetail}
     * @memberof ProductMutationProductMutationDetail
     */
    product?: ProductProductMutationDetail;
    /**
     *
     * @type {string}
     * @memberof ProductMutationProductMutationDetail
     */
    attribute?: string;
    /**
     *
     * @type {string}
     * @memberof ProductMutationProductMutationDetail
     */
    oldValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductMutationProductMutationDetail
     */
    newValue?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductMutationProductMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {UserProductMutationDetail}
     * @memberof ProductMutationProductMutationDetail
     */
    mutatedBy?: UserProductMutationDetail | null;
}
/**
 *
 * @export
 * @interface ProductOutboundDetail
 */
export interface ProductOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageOutboundDetail>}
     * @memberof ProductOutboundDetail
     */
    packages?: Array<ProductPackageOutboundDetail>;
}
/**
 *
 * @export
 * @interface ProductOutboundItemDetail
 */
export interface ProductOutboundItemDetail {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundItemDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundItemDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundItemDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundItemDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundItemDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundItemDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductOutboundItemDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductOutboundItemReturnDetail
 */
export interface ProductOutboundItemReturnDetail {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemReturnDetail
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductOutboundItemReturnInstructionDetail
 */
export interface ProductOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundItemReturnInstructionDetail
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductOutboundMutationDetail
 */
export interface ProductOutboundMutationDetail {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundMutationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductOutboundMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductOutboundMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductOutboundMutationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductOutboundMutationDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductOutboundOverview
 */
export interface ProductOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof ProductOutboundOverview
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldInboundDetail
 */
export interface ProductPackageJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldInboundDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldInboundItemDetail
 */
export interface ProductPackageJsonldInboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldInboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldInboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldInboundItemDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldLocationDetail
 */
export interface ProductPackageJsonldLocationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldLocationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldLocationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldLocationDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldOutboundDetail
 */
export interface ProductPackageJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundDetail
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldOutboundItemDetail
 */
export interface ProductPackageJsonldOutboundItemDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldOutboundItemDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundItemDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundItemDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldOutboundMutationDetail
 */
export interface ProductPackageJsonldOutboundMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldOutboundMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldOutboundMutationDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldPackageProducts
 */
export interface ProductPackageJsonldPackageProducts {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldPackageProducts
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldPackageProducts
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldPackageProducts
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldPackageProducts
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldProductCreate
 */
export interface ProductPackageJsonldProductCreate {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldProductCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductCreate
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldProductDetail
 */
export interface ProductPackageJsonldProductDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldProductDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductDetail
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldProductLocationZoneDetail
 */
export interface ProductPackageJsonldProductLocationZoneDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldProductLocationZoneDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductLocationZoneDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductLocationZoneDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldProductPickDetail
 */
export interface ProductPackageJsonldProductPickDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldProductPickDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductPickDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductPickDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldProductPickListDetail
 */
export interface ProductPackageJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldProductPickListDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldStockDetail
 */
export interface ProductPackageJsonldStockDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldStockDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldStockMutationDetail
 */
export interface ProductPackageJsonldStockMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldStockMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockMutationDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldStockOverview
 */
export interface ProductPackageJsonldStockOverview {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldStockOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockOverview
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPackageJsonldStockProducts
 */
export interface ProductPackageJsonldStockProducts {
    /**
     *
     * @type {string | any}
     * @memberof ProductPackageJsonldStockProducts
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockProducts
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockProducts
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageJsonldStockProducts
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageOutboundDetail
 */
export interface ProductPackageOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof ProductPackageOutboundDetail
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackagePackageProducts
 */
export interface ProductPackagePackageProducts {
    /**
     *
     * @type {string}
     * @memberof ProductPackagePackageProducts
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageProductCreate
 */
export interface ProductPackageProductCreate {
    /**
     *
     * @type {string}
     * @memberof ProductPackageProductCreate
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageProductDetail
 */
export interface ProductPackageProductDetail {
    /**
     *
     * @type {string}
     * @memberof ProductPackageProductDetail
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPackageProducts
 */
export interface ProductPackageProducts {
    /**
     *
     * @type {string}
     * @memberof ProductPackageProducts
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductPackageProducts
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPackageProducts
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageProducts
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageProducts
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageProducts
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductPackageProducts
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageProducts
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductPackageProducts
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductPackageProducts
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackagePackageProducts>}
     * @memberof ProductPackageProducts
     */
    packages?: Array<ProductPackagePackageProducts>;
}
/**
 *
 * @export
 * @interface ProductPackageStockProducts
 */
export interface ProductPackageStockProducts {
    /**
     *
     * @type {string}
     * @memberof ProductPackageStockProducts
     */
    ean?: string;
}
/**
 *
 * @export
 * @interface ProductPickJsonldProductPickDetail
 */
export interface ProductPickJsonldProductPickDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPickJsonldProductPickDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldProductPickDetail}
     * @memberof ProductPickJsonldProductPickDetail
     */
    product?: ProductJsonldProductPickDetail;
    /**
     *
     * @type {number}
     * @memberof ProductPickJsonldProductPickDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickDetail
     */
    stock?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickJsonldProductPickListDetail
 */
export interface ProductPickJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldProductPickListDetail}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    product?: ProductJsonldProductPickListDetail;
    /**
     *
     * @type {number}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    quantity?: number;
    /**
     *
     * @type {StockJsonldProductPickListDetail}
     * @memberof ProductPickJsonldProductPickListDetail
     */
    stock?: StockJsonldProductPickListDetail | null;
}
/**
 *
 * @export
 * @interface ProductPickJsonldProductPickListOverview
 */
export interface ProductPickJsonldProductPickListOverview {
    /**
     *
     * @type {string | any}
     * @memberof ProductPickJsonldProductPickListOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickListOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickListOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickJsonldProductPickListOverview
     */
    stock?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListJsonldOrderPickOutbound
 */
export interface ProductPickListJsonldOrderPickOutbound {
    /**
     *
     * @type {string | any}
     * @memberof ProductPickListJsonldOrderPickOutbound
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldOrderPickOutbound
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldOrderPickOutbound
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPickListJsonldProductPickListDetail
 */
export interface ProductPickListJsonldProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    originZone?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    destinationZone?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    onlySingleItemOutbounds?: boolean;
    /**
     *
     * @type {Array<ProductPickJsonldProductPickListDetail>}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    items?: Array<ProductPickJsonldProductPickListDetail>;
    /**
     *
     * @type {number}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    totalQuantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    state?: string | null;
    /**
     *
     * @type {UserJsonldProductPickListDetail}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    assignee?: UserJsonldProductPickListDetail | null;
    /**
     *
     * @type {OutboundJsonldProductPickListDetail}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    outbound?: OutboundJsonldProductPickListDetail | null;
    /**
     *
     * @type {LocationJsonldProductPickListDetail}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    location?: LocationJsonldProductPickListDetail | null;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListDetail
     */
    outboundEarliestCutoff?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListJsonldProductPickListOverview
 */
export interface ProductPickListJsonldProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    originZone?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    destinationZone?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    onlySingleItemOutbounds?: boolean;
    /**
     *
     * @type {Array<ProductPickJsonldProductPickListOverview>}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    items?: Array<ProductPickJsonldProductPickListOverview>;
    /**
     *
     * @type {number}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    totalQuantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    state?: string | null;
    /**
     *
     * @type {UserJsonldProductPickListOverview}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    assignee?: UserJsonldProductPickListOverview | null;
    /**
     *
     * @type {OutboundJsonldProductPickListOverview}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    outbound?: OutboundJsonldProductPickListOverview | null;
    /**
     *
     * @type {LocationJsonldProductPickListOverview}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    location?: LocationJsonldProductPickListOverview | null;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListOverview
     */
    outboundEarliestCutoff?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListJsonldProductPickListStocks
 */
export interface ProductPickListJsonldProductPickListStocks {
    /**
     *
     * @type {string | any}
     * @memberof ProductPickListJsonldProductPickListStocks
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListStocks
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListJsonldProductPickListStocks
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductPickListProductPickListDetail
 */
export interface ProductPickListProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListDetail
     */
    originZone?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListDetail
     */
    destinationZone?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductPickListProductPickListDetail
     */
    onlySingleItemOutbounds?: boolean;
    /**
     *
     * @type {Array<ProductPickProductPickListDetail>}
     * @memberof ProductPickListProductPickListDetail
     */
    items?: Array<ProductPickProductPickListDetail>;
    /**
     *
     * @type {number}
     * @memberof ProductPickListProductPickListDetail
     */
    totalQuantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListDetail
     */
    state?: string | null;
    /**
     *
     * @type {UserProductPickListDetail}
     * @memberof ProductPickListProductPickListDetail
     */
    assignee?: UserProductPickListDetail | null;
    /**
     *
     * @type {OutboundProductPickListDetail}
     * @memberof ProductPickListProductPickListDetail
     */
    outbound?: OutboundProductPickListDetail | null;
    /**
     *
     * @type {LocationProductPickListDetail}
     * @memberof ProductPickListProductPickListDetail
     */
    location?: LocationProductPickListDetail | null;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListDetail
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListDetail
     */
    outboundEarliestCutoff?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListProductPickListEdit
 */
export interface ProductPickListProductPickListEdit {
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListEdit
     */
    location: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListProductPickListOverview
 */
export interface ProductPickListProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListOverview
     */
    originZone?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListOverview
     */
    destinationZone?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProductPickListProductPickListOverview
     */
    onlySingleItemOutbounds?: boolean;
    /**
     *
     * @type {Array<ProductPickProductPickListOverview>}
     * @memberof ProductPickListProductPickListOverview
     */
    items?: Array<ProductPickProductPickListOverview>;
    /**
     *
     * @type {number}
     * @memberof ProductPickListProductPickListOverview
     */
    totalQuantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListOverview
     */
    state?: string | null;
    /**
     *
     * @type {UserProductPickListOverview}
     * @memberof ProductPickListProductPickListOverview
     */
    assignee?: UserProductPickListOverview | null;
    /**
     *
     * @type {OutboundProductPickListOverview}
     * @memberof ProductPickListProductPickListOverview
     */
    outbound?: OutboundProductPickListOverview | null;
    /**
     *
     * @type {LocationProductPickListOverview}
     * @memberof ProductPickListProductPickListOverview
     */
    location?: LocationProductPickListOverview | null;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListOverview
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPickListProductPickListOverview
     */
    outboundEarliestCutoff?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickListStateTransitionProductPickListStateChange
 */
export interface ProductPickListStateTransitionProductPickListStateChange {
    /**
     *
     * @type {string}
     * @memberof ProductPickListStateTransitionProductPickListStateChange
     */
    transition: string;
}
/**
 *
 * @export
 * @interface ProductPickProductPickDetail
 */
export interface ProductPickProductPickDetail {
    /**
     *
     * @type {ProductProductPickDetail}
     * @memberof ProductPickProductPickDetail
     */
    product?: ProductProductPickDetail;
    /**
     *
     * @type {number}
     * @memberof ProductPickProductPickDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof ProductPickProductPickDetail
     */
    stock?: string | null;
}
/**
 *
 * @export
 * @interface ProductPickProductPickEdit
 */
export interface ProductPickProductPickEdit {
    /**
     *
     * @type {number}
     * @memberof ProductPickProductPickEdit
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface ProductPickProductPickListDetail
 */
export interface ProductPickProductPickListDetail {
    /**
     *
     * @type {ProductProductPickListDetail}
     * @memberof ProductPickProductPickListDetail
     */
    product?: ProductProductPickListDetail;
    /**
     *
     * @type {number}
     * @memberof ProductPickProductPickListDetail
     */
    quantity?: number;
    /**
     *
     * @type {StockProductPickListDetail}
     * @memberof ProductPickProductPickListDetail
     */
    stock?: StockProductPickListDetail | null;
}
/**
 *
 * @export
 * @interface ProductPickProductPickListOverview
 */
export interface ProductPickProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof ProductPickProductPickListOverview
     */
    stock?: string | null;
}
/**
 *
 * @export
 * @interface ProductPrintProductJsonldProductCreate
 */
export interface ProductPrintProductJsonldProductCreate {
    /**
     *
     * @type {string | any}
     * @memberof ProductPrintProductJsonldProductCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ProductPrintProductJsonldProductCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ProductPrintProductJsonldProductCreate
     */
    type?: string;
}
/**
 *
 * @export
 * @interface ProductProductCreate
 */
export interface ProductProductCreate {
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductCreate
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductCreate
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductCreate
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductCreate
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductCreate
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    inboundNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    shipmentNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductCreate
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductCreate
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageProductCreate>}
     * @memberof ProductProductCreate
     */
    packages?: Array<ProductPackageProductCreate>;
    /**
     *
     * @type {Array<ProductProductCreate>}
     * @memberof ProductProductCreate
     */
    sameProducts?: Array<ProductProductCreate>;
}
/**
 *
 * @export
 * @interface ProductProductDetail
 */
export interface ProductProductDetail {
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    shipmentNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageProductDetail>}
     * @memberof ProductProductDetail
     */
    packages?: Array<ProductPackageProductDetail>;
    /**
     *
     * @type {Array<ProductProductDetail>}
     * @memberof ProductProductDetail
     */
    sameProducts?: Array<ProductProductDetail>;
}
/**
 *
 * @export
 * @interface ProductProductLocationZoneDetail
 */
export interface ProductProductLocationZoneDetail {
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductLocationZoneDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductLocationZoneDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductLocationZoneDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductLocationZoneDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductLocationZoneDetail
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    inboundNotification?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductLocationZoneDetail
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductLocationZoneDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductProductLocationZoneDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductProductMutationDetail
 */
export interface ProductProductMutationDetail {
    /**
     *
     * @type {string}
     * @memberof ProductProductMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductMutationDetail
     */
    pickQtyThreshold?: number;
}
/**
 *
 * @export
 * @interface ProductProductOverview
 */
export interface ProductProductOverview {
    /**
     *
     * @type {string}
     * @memberof ProductProductOverview
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductOverview
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductOverview
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductOverview
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductOverview
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductOverview
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductOverview
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductOverview
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductOverview
     */
    pickQtyThreshold?: number;
}
/**
 *
 * @export
 * @interface ProductProductPickDetail
 */
export interface ProductProductPickDetail {
    /**
     *
     * @type {string}
     * @memberof ProductProductPickDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductProductPickDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductProductPickListDetail
 */
export interface ProductProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof ProductProductPickListDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickListDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickListDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickListDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickListDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickListDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductProductPickListDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickListDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductProductPickListDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductProductPickListDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductProductPickListDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductProductStockMutation
 */
export interface ProductProductStockMutation {
    /**
     *
     * @type {string}
     * @memberof ProductProductStockMutation
     */
    ean: string;
}
/**
 *
 * @export
 * @interface ProductStockDetail
 */
export interface ProductStockDetail {
    /**
     *
     * @type {string}
     * @memberof ProductStockDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductStockDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductStockDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductStockDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductStockDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductStockDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductStockMutationDetail
 */
export interface ProductStockMutationDetail {
    /**
     *
     * @type {string}
     * @memberof ProductStockMutationDetail
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductStockMutationDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductStockMutationDetail
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockMutationDetail
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockMutationDetail
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockMutationDetail
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductStockMutationDetail
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockMutationDetail
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockMutationDetail
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductStockMutationDetail
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductStockMutationDetail
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductStockOverview
 */
export interface ProductStockOverview {
    /**
     *
     * @type {string}
     * @memberof ProductStockOverview
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductStockOverview
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductStockOverview
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockOverview
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockOverview
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockOverview
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductStockOverview
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockOverview
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockOverview
     */
    large?: boolean;
    /**
     *
     * @type {string}
     * @memberof ProductStockOverview
     */
    supplierName?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductStockOverview
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<object>}
     * @memberof ProductStockOverview
     */
    packages?: Array<object>;
}
/**
 *
 * @export
 * @interface ProductStockProducts
 */
export interface ProductStockProducts {
    /**
     *
     * @type {string}
     * @memberof ProductStockProducts
     */
    ean: string;
    /**
     *
     * @type {string}
     * @memberof ProductStockProducts
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    length?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    width?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    height?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    weight?: number | null;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    sizeGuideline?: number;
    /**
     *
     * @type {string}
     * @memberof ProductStockProducts
     */
    imageUrl?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockProducts
     */
    fragile?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockProducts
     */
    shippingBag?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockProducts
     */
    machineExcluded?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    orderQuantity?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProductStockProducts
     */
    serviceName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockProducts
     */
    highRisk?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof ProductStockProducts
     */
    large?: boolean;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    desiredPickQty?: number;
    /**
     *
     * @type {number}
     * @memberof ProductStockProducts
     */
    pickQtyThreshold?: number;
    /**
     *
     * @type {Array<ProductPackageStockProducts>}
     * @memberof ProductStockProducts
     */
    packages?: Array<ProductPackageStockProducts>;
}
/**
 *
 * @export
 * @interface ShippingMethodJsonldShippingMethodDetail
 */
export interface ShippingMethodJsonldShippingMethodDetail {
    /**
     *
     * @type {string | any}
     * @memberof ShippingMethodJsonldShippingMethodDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof ShippingMethodJsonldShippingMethodDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ShippingMethodJsonldShippingMethodDetail
     */
    type?: string;
}
/**
 *
 * @export
 * @interface StockJsonldLocationDetail
 */
export interface StockJsonldLocationDetail {
    /**
     *
     * @type {string | any}
     * @memberof StockJsonldLocationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof StockJsonldLocationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldLocationDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldLocationDetail}
     * @memberof StockJsonldLocationDetail
     */
    product?: ProductJsonldLocationDetail;
    /**
     *
     * @type {number}
     * @memberof StockJsonldLocationDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockJsonldOutboundDetail
 */
export interface StockJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof StockJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof StockJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {number}
     * @memberof StockJsonldOutboundDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockJsonldProductPickListDetail
 */
export interface StockJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof StockJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof StockJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldProductPickListDetail}
     * @memberof StockJsonldProductPickListDetail
     */
    product?: ProductJsonldProductPickListDetail;
    /**
     *
     * @type {LocationJsonldProductPickListDetail}
     * @memberof StockJsonldProductPickListDetail
     */
    location?: LocationJsonldProductPickListDetail;
    /**
     *
     * @type {number}
     * @memberof StockJsonldProductPickListDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockJsonldStockDetail
 */
export interface StockJsonldStockDetail {
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof StockJsonldStockDetail
     */
    context?: string | any;
    /**
     *
     * @type {ProductJsonldStockDetail}
     * @memberof StockJsonldStockDetail
     */
    product?: ProductJsonldStockDetail;
    /**
     *
     * @type {LocationJsonldStockDetail}
     * @memberof StockJsonldStockDetail
     */
    location?: LocationJsonldStockDetail;
    /**
     *
     * @type {number}
     * @memberof StockJsonldStockDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockDetail
     */
    createdAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockDetail
     */
    balancedAt?: string | null;
}
/**
 *
 * @export
 * @interface StockJsonldStockOverview
 */
export interface StockJsonldStockOverview {
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockOverview
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldStockOverview}
     * @memberof StockJsonldStockOverview
     */
    product?: ProductJsonldStockOverview;
    /**
     *
     * @type {LocationJsonldStockOverview}
     * @memberof StockJsonldStockOverview
     */
    location?: LocationJsonldStockOverview;
    /**
     *
     * @type {number}
     * @memberof StockJsonldStockOverview
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockOverview
     */
    createdAt?: string | null;
}
/**
 *
 * @export
 * @interface StockJsonldStockProducts
 */
export interface StockJsonldStockProducts {
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockProducts
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockJsonldStockProducts
     */
    type?: string;
    /**
     *
     * @type {ProductJsonldStockProducts}
     * @memberof StockJsonldStockProducts
     */
    product?: ProductJsonldStockProducts;
    /**
     *
     * @type {number}
     * @memberof StockJsonldStockProducts
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockLocationDetail
 */
export interface StockLocationDetail {
    /**
     *
     * @type {ProductLocationDetail}
     * @memberof StockLocationDetail
     */
    product?: ProductLocationDetail;
    /**
     *
     * @type {number}
     * @memberof StockLocationDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockMutationJsonldStockMutationDetail
 */
export interface StockMutationJsonldStockMutationDetail {
    /**
     *
     * @type {string}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {ProductJsonldStockMutationDetail}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    product?: ProductJsonldStockMutationDetail;
    /**
     *
     * @type {number}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    quantityDiff?: number;
    /**
     *
     * @type {string}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {number}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    currentQuantity?: number;
    /**
     *
     * @type {LocationJsonldStockMutationDetail}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    location?: LocationJsonldStockMutationDetail;
    /**
     *
     * @type {UserJsonldStockMutationDetail}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    mutatedBy?: UserJsonldStockMutationDetail | null;
    /**
     *
     * @type {string}
     * @memberof StockMutationJsonldStockMutationDetail
     */
    reason?: string | null;
}
/**
 *
 * @export
 * @interface StockMutationProductStockMutation
 */
export interface StockMutationProductStockMutation {
    /**
     *
     * @type {ProductProductStockMutation}
     * @memberof StockMutationProductStockMutation
     */
    product?: ProductProductStockMutation;
    /**
     *
     * @type {number}
     * @memberof StockMutationProductStockMutation
     */
    quantityDiff?: number;
    /**
     *
     * @type {number}
     * @memberof StockMutationProductStockMutation
     */
    currentQuantity?: number;
}
/**
 *
 * @export
 * @interface StockMutationStockMutationDetail
 */
export interface StockMutationStockMutationDetail {
    /**
     *
     * @type {ProductStockMutationDetail}
     * @memberof StockMutationStockMutationDetail
     */
    product?: ProductStockMutationDetail;
    /**
     *
     * @type {number}
     * @memberof StockMutationStockMutationDetail
     */
    quantityDiff?: number;
    /**
     *
     * @type {string}
     * @memberof StockMutationStockMutationDetail
     */
    mutatedAt?: string;
    /**
     *
     * @type {number}
     * @memberof StockMutationStockMutationDetail
     */
    currentQuantity?: number;
    /**
     *
     * @type {LocationStockMutationDetail}
     * @memberof StockMutationStockMutationDetail
     */
    location?: LocationStockMutationDetail;
    /**
     *
     * @type {UserStockMutationDetail}
     * @memberof StockMutationStockMutationDetail
     */
    mutatedBy?: UserStockMutationDetail | null;
    /**
     *
     * @type {string}
     * @memberof StockMutationStockMutationDetail
     */
    reason?: string | null;
}
/**
 *
 * @export
 * @interface StockOutboundDetail
 */
export interface StockOutboundDetail {
    /**
     *
     * @type {number}
     * @memberof StockOutboundDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockProductPickListDetail
 */
export interface StockProductPickListDetail {
    /**
     *
     * @type {ProductProductPickListDetail}
     * @memberof StockProductPickListDetail
     */
    product?: ProductProductPickListDetail;
    /**
     *
     * @type {LocationProductPickListDetail}
     * @memberof StockProductPickListDetail
     */
    location?: LocationProductPickListDetail;
    /**
     *
     * @type {number}
     * @memberof StockProductPickListDetail
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockStockDetail
 */
export interface StockStockDetail {
    /**
     *
     * @type {ProductStockDetail}
     * @memberof StockStockDetail
     */
    product?: ProductStockDetail;
    /**
     *
     * @type {LocationStockDetail}
     * @memberof StockStockDetail
     */
    location?: LocationStockDetail;
    /**
     *
     * @type {number}
     * @memberof StockStockDetail
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof StockStockDetail
     */
    createdAt?: string | null;
    /**
     *
     * @type {string}
     * @memberof StockStockDetail
     */
    balancedAt?: string | null;
}
/**
 *
 * @export
 * @interface StockStockOverview
 */
export interface StockStockOverview {
    /**
     *
     * @type {ProductStockOverview}
     * @memberof StockStockOverview
     */
    product?: ProductStockOverview;
    /**
     *
     * @type {LocationStockOverview}
     * @memberof StockStockOverview
     */
    location?: LocationStockOverview;
    /**
     *
     * @type {number}
     * @memberof StockStockOverview
     */
    quantity?: number;
    /**
     *
     * @type {string}
     * @memberof StockStockOverview
     */
    createdAt?: string | null;
}
/**
 *
 * @export
 * @interface StockStockProducts
 */
export interface StockStockProducts {
    /**
     *
     * @type {ProductStockProducts}
     * @memberof StockStockProducts
     */
    product?: ProductStockProducts;
    /**
     *
     * @type {number}
     * @memberof StockStockProducts
     */
    quantity?: number;
}
/**
 *
 * @export
 * @interface StockStockTransferJsonld
 */
export interface StockStockTransferJsonld {
    /**
     *
     * @type {string | any}
     * @memberof StockStockTransferJsonld
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof StockStockTransferJsonld
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StockStockTransferJsonld
     */
    type?: string;
}
/**
 *
 * @export
 * @interface TranslationJsonldTranslationCreate
 */
export interface TranslationJsonldTranslationCreate {
    /**
     *
     * @type {string | any}
     * @memberof TranslationJsonldTranslationCreate
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    locale?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    domain?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    source?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationCreate
     */
    target: string;
}
/**
 *
 * @export
 * @interface TranslationJsonldTranslationDetail
 */
export interface TranslationJsonldTranslationDetail {
    /**
     *
     * @type {string | any}
     * @memberof TranslationJsonldTranslationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    locale?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    domain?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    source?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationJsonldTranslationDetail
     */
    target: string;
}
/**
 *
 * @export
 * @interface TranslationTranslationCreate
 */
export interface TranslationTranslationCreate {
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationCreate
     */
    locale?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationCreate
     */
    domain?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationCreate
     */
    source?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationCreate
     */
    target: string;
}
/**
 *
 * @export
 * @interface TranslationTranslationDetail
 */
export interface TranslationTranslationDetail {
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationDetail
     */
    locale?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationDetail
     */
    domain?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationDetail
     */
    source?: string;
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationDetail
     */
    target: string;
}
/**
 *
 * @export
 * @interface TranslationTranslationEdit
 */
export interface TranslationTranslationEdit {
    /**
     *
     * @type {string}
     * @memberof TranslationTranslationEdit
     */
    target: string;
}
/**
 *
 * @export
 * @interface UserInboundDetail
 */
export interface UserInboundDetail {
    /**
     *
     * @type {string}
     * @memberof UserInboundDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserInboundOverview
 */
export interface UserInboundOverview {
    /**
     *
     * @type {string}
     * @memberof UserInboundOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserInboundTransportDetail
 */
export interface UserInboundTransportDetail {
    /**
     *
     * @type {string}
     * @memberof UserInboundTransportDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserInboundTransportOverview
 */
export interface UserInboundTransportOverview {
    /**
     *
     * @type {string}
     * @memberof UserInboundTransportOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldInboundDetail
 */
export interface UserJsonldInboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldInboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldInboundOverview
 */
export interface UserJsonldInboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldInboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldInboundTransportDetail
 */
export interface UserJsonldInboundTransportDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldInboundTransportDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldInboundTransportOverview
 */
export interface UserJsonldInboundTransportOverview {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldInboundTransportOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldInboundTransportOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldLocationDetail
 */
export interface UserJsonldLocationDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldLocationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldLocationMutationDetail
 */
export interface UserJsonldLocationMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldLocationMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldLocationMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldOutboundDetail
 */
export interface UserJsonldOutboundDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldOutboundDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldOutboundItemReturnDetail
 */
export interface UserJsonldOutboundItemReturnDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldOutboundItemReturnDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldOutboundItemReturnInstructionDetail
 */
export interface UserJsonldOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldOutboundItemReturnInstructionDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnInstructionDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnInstructionDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundItemReturnInstructionDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldOutboundMutationDetail
 */
export interface UserJsonldOutboundMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldOutboundMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldOutboundOverview
 */
export interface UserJsonldOutboundOverview {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldOutboundOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldOutboundOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldProductMutationDetail
 */
export interface UserJsonldProductMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldProductMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldProductPickListDetail
 */
export interface UserJsonldProductPickListDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldProductPickListDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldProductPickListOverview
 */
export interface UserJsonldProductPickListOverview {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldProductPickListOverview
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListOverview
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListOverview
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldProductPickListOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldStockMutationDetail
 */
export interface UserJsonldStockMutationDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldStockMutationDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldStockMutationDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldStockMutationDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldStockMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserJsonldUserDetail
 */
export interface UserJsonldUserDetail {
    /**
     *
     * @type {string | any}
     * @memberof UserJsonldUserDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserDetail
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof UserJsonldUserDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserLocationDetail
 */
export interface UserLocationDetail {
    /**
     *
     * @type {string}
     * @memberof UserLocationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserLocationMutationDetail
 */
export interface UserLocationMutationDetail {
    /**
     *
     * @type {string}
     * @memberof UserLocationMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserOutboundDetail
 */
export interface UserOutboundDetail {
    /**
     *
     * @type {string}
     * @memberof UserOutboundDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserOutboundItemReturnDetail
 */
export interface UserOutboundItemReturnDetail {
    /**
     *
     * @type {string}
     * @memberof UserOutboundItemReturnDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserOutboundItemReturnInstructionDetail
 */
export interface UserOutboundItemReturnInstructionDetail {
    /**
     *
     * @type {string}
     * @memberof UserOutboundItemReturnInstructionDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserOutboundMutationDetail
 */
export interface UserOutboundMutationDetail {
    /**
     *
     * @type {string}
     * @memberof UserOutboundMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserOutboundOverview
 */
export interface UserOutboundOverview {
    /**
     *
     * @type {string}
     * @memberof UserOutboundOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserProductMutationDetail
 */
export interface UserProductMutationDetail {
    /**
     *
     * @type {string}
     * @memberof UserProductMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserProductPickListDetail
 */
export interface UserProductPickListDetail {
    /**
     *
     * @type {string}
     * @memberof UserProductPickListDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserProductPickListOverview
 */
export interface UserProductPickListOverview {
    /**
     *
     * @type {string}
     * @memberof UserProductPickListOverview
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserStockMutationDetail
 */
export interface UserStockMutationDetail {
    /**
     *
     * @type {string}
     * @memberof UserStockMutationDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface UserUserDetail
 */
export interface UserUserDetail {
    /**
     *
     * @type {string}
     * @memberof UserUserDetail
     */
    displayName?: string;
}
/**
 *
 * @export
 * @interface WorkspaceJsonldPrinterDetail
 */
export interface WorkspaceJsonldPrinterDetail {
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldPrinterDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldPrinterDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof WorkspaceJsonldPrinterDetail
     */
    context?: string | any;
}
/**
 *
 * @export
 * @interface WorkspaceJsonldWorkspaceDetail
 */
export interface WorkspaceJsonldWorkspaceDetail {
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    type?: string;
    /**
     *
     * @type {string | any}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    context?: string | any;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    label?: string;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    documentPrinter?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    labelPrinter?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    barcodePrinter?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    packingMachine?: boolean;
    /**
     *
     * @type {string}
     * @memberof WorkspaceJsonldWorkspaceDetail
     */
    sparckMachineId?: string | null;
}
/**
 *
 * @export
 * @interface WorkspaceWorkspaceDetail
 */
export interface WorkspaceWorkspaceDetail {
    /**
     *
     * @type {string}
     * @memberof WorkspaceWorkspaceDetail
     */
    label?: string;
    /**
     *
     * @type {string}
     * @memberof WorkspaceWorkspaceDetail
     */
    documentPrinter?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkspaceWorkspaceDetail
     */
    labelPrinter?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkspaceWorkspaceDetail
     */
    barcodePrinter?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof WorkspaceWorkspaceDetail
     */
    packingMachine?: boolean;
    /**
     *
     * @type {string}
     * @memberof WorkspaceWorkspaceDetail
     */
    sparckMachineId?: string | null;
}

/**
 * DistributorApi - axios parameter creator
 * @export
 */
export const DistributorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of Distributor resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/distributors`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Distributor resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDistributorItem.');
            }
            const localVarPath = `/distributors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributorApi - functional programming interface
 * @export
 */
export const DistributorApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of Distributor resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await DistributorApiAxiosParamCreator(configuration).getDistributorCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Distributor resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorJsonldDistributorDetail>> {
            const localVarAxiosArgs = await DistributorApiAxiosParamCreator(configuration).getDistributorItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DistributorApi - factory interface
 * @export
 */
export const DistributorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of Distributor resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2001> {
            return DistributorApiFp(configuration).getDistributorCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Distributor resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorItem(id: string, options?: any): AxiosPromise<DistributorJsonldDistributorDetail> {
            return DistributorApiFp(configuration).getDistributorItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributorApi - object-oriented interface
 * @export
 * @class DistributorApi
 * @extends {BaseAPI}
 */
export class DistributorApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of Distributor resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorApi
     */
    public getDistributorCollection(page?: number, itemsPerPage?: number, options?: any) {
        return DistributorApiFp(this.configuration).getDistributorCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Distributor resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorApi
     */
    public getDistributorItem(id: string, options?: any) {
        return DistributorApiFp(this.configuration).getDistributorItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * InboundApi - axios parameter creator
 * @export
 */
export const InboundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to Inbound
         * @param {string} id
         * @param {InboundPhotosJsonldInboundCreate} [inboundPhotosJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosInboundItem: async (id: string, inboundPhotosJsonldInboundCreate?: InboundPhotosJsonldInboundCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPhotosInboundItem.');
            }
            const localVarPath = `/inbound/{id}/photos/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundPhotosJsonldInboundCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundPhotosJsonldInboundCreate !== undefined ? inboundPhotosJsonldInboundCreate : {}) : (inboundPhotosJsonldInboundCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInboundsItemsGetSubresource: async (id: string, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiInboundsItemsGetSubresource.');
            }
            const localVarPath = `/inbounds/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Removes the Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteInboundItem.');
            }
            const localVarPath = `/inbounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Inbound resources.
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderReference]
         * @param {string} [reference]
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {boolean} [typeReturn]
         * @param {boolean} [typeShop]
         * @param {boolean} [typeOther]
         * @param {boolean} [typePreOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundCollection: async (orderCreatedAt?: 'asc' | 'desc', orderReference?: 'asc' | 'desc', reference?: string, marking?: string, marking2?: Array<string>, itemsProductEan?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, typeReturn?: boolean, typeShop?: boolean, typeOther?: boolean, typePreOrder?: boolean, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbounds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orderCreatedAt !== undefined) {
                localVarQueryParameter['order[createdAt]'] = orderCreatedAt;
            }

            if (orderReference !== undefined) {
                localVarQueryParameter['order[reference]'] = orderReference;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (itemsProductEan !== undefined) {
                localVarQueryParameter['items.product.ean'] = itemsProductEan;
            }

            if (createdAtBefore !== undefined) {
                localVarQueryParameter['createdAt[before]'] = createdAtBefore;
            }

            if (createdAtStrictlyBefore !== undefined) {
                localVarQueryParameter['createdAt[strictly_before]'] = createdAtStrictlyBefore;
            }

            if (createdAtAfter !== undefined) {
                localVarQueryParameter['createdAt[after]'] = createdAtAfter;
            }

            if (createdAtStrictlyAfter !== undefined) {
                localVarQueryParameter['createdAt[strictly_after]'] = createdAtStrictlyAfter;
            }

            if (typeReturn !== undefined) {
                localVarQueryParameter['typeReturn'] = typeReturn;
            }

            if (typeShop !== undefined) {
                localVarQueryParameter['typeShop'] = typeShop;
            }

            if (typeOther !== undefined) {
                localVarQueryParameter['typeOther'] = typeOther;
            }

            if (typePreOrder !== undefined) {
                localVarQueryParameter['typePreOrder'] = typePreOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInboundItem.');
            }
            const localVarPath = `/inbounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get inbound stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {boolean} [returns]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundStatsInboundCollection: async (period?: string, date?: string, time?: string, returns?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound/stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }

            if (returns !== undefined) {
                localVarQueryParameter['returns'] = returns;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Inbound resource.
         * @param {string} id
         * @param {InboundInboundCreate} [inboundInboundCreate] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInboundItem: async (id: string, inboundInboundCreate?: InboundInboundCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchInboundItem.');
            }
            const localVarPath = `/inbounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundInboundCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundInboundCreate !== undefined ? inboundInboundCreate : {}) : (inboundInboundCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a Inbound resource.
         * @param {InboundJsonldInboundCreate} [inboundJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInboundCollection: async (inboundJsonldInboundCreate?: InboundJsonldInboundCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbounds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundJsonldInboundCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundJsonldInboundCreate !== undefined ? inboundJsonldInboundCreate : {}) : (inboundJsonldInboundCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query an Inbound resource.
         * @param {string} reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInboundItem: async (reference: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            if (reference === null || reference === undefined) {
                throw new RequiredError('reference','Required parameter reference was null or undefined when calling queryInboundItem.');
            }
            const localVarPath = `/inbound`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Scan a Location resource for inbounds.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanLocationInboundItem: async (barcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling scanLocationInboundItem.');
            }
            const localVarPath = `/inbound/scan-location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an Inbound resource.
         * @param {string} id
         * @param {InboundStateTransitionInboundStateChange} [inboundStateTransitionInboundStateChange] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeInboundItem: async (id: string, inboundStateTransitionInboundStateChange?: InboundStateTransitionInboundStateChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stateChangeInboundItem.');
            }
            const localVarPath = `/inbounds/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundStateTransitionInboundStateChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundStateTransitionInboundStateChange !== undefined ? inboundStateTransitionInboundStateChange : {}) : (inboundStateTransitionInboundStateChange || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboundApi - functional programming interface
 * @export
 */
export const InboundApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to Inbound
         * @param {string} id
         * @param {InboundPhotosJsonldInboundCreate} [inboundPhotosJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhotosInboundItem(id: string, inboundPhotosJsonldInboundCreate?: InboundPhotosJsonldInboundCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).addPhotosInboundItem(id, inboundPhotosJsonldInboundCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Removes the Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).deleteInboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Inbound resources.
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderReference]
         * @param {string} [reference]
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {boolean} [typeReturn]
         * @param {boolean} [typeShop]
         * @param {boolean} [typeOther]
         * @param {boolean} [typePreOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundCollection(orderCreatedAt?: 'asc' | 'desc', orderReference?: 'asc' | 'desc', reference?: string, marking?: string, marking2?: Array<string>, itemsProductEan?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, typeReturn?: boolean, typeShop?: boolean, typeOther?: boolean, typePreOrder?: boolean, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).getInboundCollection(orderCreatedAt, orderReference, reference, marking, marking2, itemsProductEan, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, typeReturn, typeShop, typeOther, typePreOrder, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).getInboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get inbound stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {boolean} [returns]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboundStatsInboundCollection(period?: string, date?: string, time?: string, returns?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).inboundStatsInboundCollection(period, date, time, returns, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Inbound resource.
         * @param {string} id
         * @param {InboundInboundCreate} [inboundInboundCreate] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInboundItem(id: string, inboundInboundCreate?: InboundInboundCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).patchInboundItem(id, inboundInboundCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a Inbound resource.
         * @param {InboundJsonldInboundCreate} [inboundJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInboundCollection(inboundJsonldInboundCreate?: InboundJsonldInboundCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).postInboundCollection(inboundJsonldInboundCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query an Inbound resource.
         * @param {string} reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryInboundItem(reference: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).queryInboundItem(reference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Scan a Location resource for inbounds.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scanLocationInboundItem(barcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundLocationScan>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).scanLocationInboundItem(barcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an Inbound resource.
         * @param {string} id
         * @param {InboundStateTransitionInboundStateChange} [inboundStateTransitionInboundStateChange] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stateChangeInboundItem(id: string, inboundStateTransitionInboundStateChange?: InboundStateTransitionInboundStateChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundJsonldInboundDetail>> {
            const localVarAxiosArgs = await InboundApiAxiosParamCreator(configuration).stateChangeInboundItem(id, inboundStateTransitionInboundStateChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InboundApi - factory interface
 * @export
 */
export const InboundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Adds photos to Inbound
         * @param {string} id
         * @param {InboundPhotosJsonldInboundCreate} [inboundPhotosJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosInboundItem(id: string, inboundPhotosJsonldInboundCreate?: InboundPhotosJsonldInboundCreate, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).addPhotosInboundItem(id, inboundPhotosJsonldInboundCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return InboundApiFp(configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Removes the Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundItem(id: string, options?: any): AxiosPromise<void> {
            return InboundApiFp(configuration).deleteInboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Inbound resources.
         * @param {'asc' | 'desc'} [orderCreatedAt]
         * @param {'asc' | 'desc'} [orderReference]
         * @param {string} [reference]
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {string} [createdAtBefore]
         * @param {string} [createdAtStrictlyBefore]
         * @param {string} [createdAtAfter]
         * @param {string} [createdAtStrictlyAfter]
         * @param {boolean} [typeReturn]
         * @param {boolean} [typeShop]
         * @param {boolean} [typeOther]
         * @param {boolean} [typePreOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundCollection(orderCreatedAt?: 'asc' | 'desc', orderReference?: 'asc' | 'desc', reference?: string, marking?: string, marking2?: Array<string>, itemsProductEan?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, typeReturn?: boolean, typeShop?: boolean, typeOther?: boolean, typePreOrder?: boolean, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return InboundApiFp(configuration).getInboundCollection(orderCreatedAt, orderReference, reference, marking, marking2, itemsProductEan, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, typeReturn, typeShop, typeOther, typePreOrder, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Inbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItem(id: string, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).getInboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get inbound stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {boolean} [returns]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundStatsInboundCollection(period?: string, date?: string, time?: string, returns?: boolean, options?: any): AxiosPromise<InlineResponse2005> {
            return InboundApiFp(configuration).inboundStatsInboundCollection(period, date, time, returns, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Inbound resource.
         * @param {string} id
         * @param {InboundInboundCreate} [inboundInboundCreate] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInboundItem(id: string, inboundInboundCreate?: InboundInboundCreate, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).patchInboundItem(id, inboundInboundCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a Inbound resource.
         * @param {InboundJsonldInboundCreate} [inboundJsonldInboundCreate] The new Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInboundCollection(inboundJsonldInboundCreate?: InboundJsonldInboundCreate, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).postInboundCollection(inboundJsonldInboundCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query an Inbound resource.
         * @param {string} reference
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInboundItem(reference: string, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).queryInboundItem(reference, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Scan a Location resource for inbounds.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scanLocationInboundItem(barcode: string, options?: any): AxiosPromise<InboundJsonldInboundLocationScan> {
            return InboundApiFp(configuration).scanLocationInboundItem(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the current workflow state for an Inbound resource.
         * @param {string} id
         * @param {InboundStateTransitionInboundStateChange} [inboundStateTransitionInboundStateChange] The updated Inbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeInboundItem(id: string, inboundStateTransitionInboundStateChange?: InboundStateTransitionInboundStateChange, options?: any): AxiosPromise<InboundJsonldInboundDetail> {
            return InboundApiFp(configuration).stateChangeInboundItem(id, inboundStateTransitionInboundStateChange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboundApi - object-oriented interface
 * @export
 * @class InboundApi
 * @extends {BaseAPI}
 */
export class InboundApi extends BaseAPI {
    /**
     *
     * @summary Adds photos to Inbound
     * @param {string} id
     * @param {InboundPhotosJsonldInboundCreate} [inboundPhotosJsonldInboundCreate] The new Inbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public addPhotosInboundItem(id: string, inboundPhotosJsonldInboundCreate?: InboundPhotosJsonldInboundCreate, options?: any) {
        return InboundApiFp(this.configuration).addPhotosInboundItem(id, inboundPhotosJsonldInboundCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of InboundItem resources.
     * @param {string} id
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any) {
        return InboundApiFp(this.configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Removes the Inbound resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public deleteInboundItem(id: string, options?: any) {
        return InboundApiFp(this.configuration).deleteInboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Inbound resources.
     * @param {'asc' | 'desc'} [orderCreatedAt]
     * @param {'asc' | 'desc'} [orderReference]
     * @param {string} [reference]
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [itemsProductEan]
     * @param {string} [createdAtBefore]
     * @param {string} [createdAtStrictlyBefore]
     * @param {string} [createdAtAfter]
     * @param {string} [createdAtStrictlyAfter]
     * @param {boolean} [typeReturn]
     * @param {boolean} [typeShop]
     * @param {boolean} [typeOther]
     * @param {boolean} [typePreOrder]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public getInboundCollection(orderCreatedAt?: 'asc' | 'desc', orderReference?: 'asc' | 'desc', reference?: string, marking?: string, marking2?: Array<string>, itemsProductEan?: string, createdAtBefore?: string, createdAtStrictlyBefore?: string, createdAtAfter?: string, createdAtStrictlyAfter?: string, typeReturn?: boolean, typeShop?: boolean, typeOther?: boolean, typePreOrder?: boolean, page?: number, itemsPerPage?: number, options?: any) {
        return InboundApiFp(this.configuration).getInboundCollection(orderCreatedAt, orderReference, reference, marking, marking2, itemsProductEan, createdAtBefore, createdAtStrictlyBefore, createdAtAfter, createdAtStrictlyAfter, typeReturn, typeShop, typeOther, typePreOrder, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Inbound resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public getInboundItem(id: string, options?: any) {
        return InboundApiFp(this.configuration).getInboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get inbound stats.
     * @param {string} [period]
     * @param {string} [date]
     * @param {string} [time]
     * @param {boolean} [returns]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public inboundStatsInboundCollection(period?: string, date?: string, time?: string, returns?: boolean, options?: any) {
        return InboundApiFp(this.configuration).inboundStatsInboundCollection(period, date, time, returns, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Inbound resource.
     * @param {string} id
     * @param {InboundInboundCreate} [inboundInboundCreate] The updated Inbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public patchInboundItem(id: string, inboundInboundCreate?: InboundInboundCreate, options?: any) {
        return InboundApiFp(this.configuration).patchInboundItem(id, inboundInboundCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a Inbound resource.
     * @param {InboundJsonldInboundCreate} [inboundJsonldInboundCreate] The new Inbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public postInboundCollection(inboundJsonldInboundCreate?: InboundJsonldInboundCreate, options?: any) {
        return InboundApiFp(this.configuration).postInboundCollection(inboundJsonldInboundCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query an Inbound resource.
     * @param {string} reference
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public queryInboundItem(reference: string, options?: any) {
        return InboundApiFp(this.configuration).queryInboundItem(reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Scan a Location resource for inbounds.
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public scanLocationInboundItem(barcode: string, options?: any) {
        return InboundApiFp(this.configuration).scanLocationInboundItem(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the current workflow state for an Inbound resource.
     * @param {string} id
     * @param {InboundStateTransitionInboundStateChange} [inboundStateTransitionInboundStateChange] The updated Inbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundApi
     */
    public stateChangeInboundItem(id: string, inboundStateTransitionInboundStateChange?: InboundStateTransitionInboundStateChange, options?: any) {
        return InboundApiFp(this.configuration).stateChangeInboundItem(id, inboundStateTransitionInboundStateChange, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * InboundItemApi - axios parameter creator
 * @export
 */
export const InboundItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to Inbound Item
         * @param {string} id
         * @param {InboundItemPhotosJsonldInboundItemCreate} [inboundItemPhotosJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosInboundItemItem: async (id: string, inboundItemPhotosJsonldInboundItemCreate?: InboundItemPhotosJsonldInboundItemCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPhotosInboundItemItem.');
            }
            const localVarPath = `/inbound-item/{id}/photos/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundItemPhotosJsonldInboundItemCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundItemPhotosJsonldInboundItemCreate !== undefined ? inboundItemPhotosJsonldInboundItemCreate : {}) : (inboundItemPhotosJsonldInboundItemCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInboundsItemsGetSubresource: async (id: string, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiInboundsItemsGetSubresource.');
            }
            const localVarPath = `/inbounds/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Removes the InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundItemItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteInboundItemItem.');
            }
            const localVarPath = `/inbound_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItemCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound_items`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItemItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInboundItemItem.');
            }
            const localVarPath = `/inbound_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the InboundItem resource.
         * @param {string} id
         * @param {InboundItemInboundItemCreate} [inboundItemInboundItemCreate] The updated InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInboundItemItem: async (id: string, inboundItemInboundItemCreate?: InboundItemInboundItemCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchInboundItemItem.');
            }
            const localVarPath = `/inbound_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundItemInboundItemCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundItemInboundItemCreate !== undefined ? inboundItemInboundItemCreate : {}) : (inboundItemInboundItemCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a InboundItem resource.
         * @param {InboundItemJsonldInboundItemCreate} [inboundItemJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInboundItemCollection: async (inboundItemJsonldInboundItemCreate?: InboundItemJsonldInboundItemCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound_items`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundItemJsonldInboundItemCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundItemJsonldInboundItemCreate !== undefined ? inboundItemJsonldInboundItemCreate : {}) : (inboundItemJsonldInboundItemCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboundItemApi - functional programming interface
 * @export
 */
export const InboundItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to Inbound Item
         * @param {string} id
         * @param {InboundItemPhotosJsonldInboundItemCreate} [inboundItemPhotosJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhotosInboundItemItem(id: string, inboundItemPhotosJsonldInboundItemCreate?: InboundItemPhotosJsonldInboundItemCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundItemJsonldInboundItemDetail>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).addPhotosInboundItemItem(id, inboundItemPhotosJsonldInboundItemCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Removes the InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInboundItemItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).deleteInboundItemItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundItemCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).getInboundItemCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundItemItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundItemJsonldInboundItemDetail>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).getInboundItemItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the InboundItem resource.
         * @param {string} id
         * @param {InboundItemInboundItemCreate} [inboundItemInboundItemCreate] The updated InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchInboundItemItem(id: string, inboundItemInboundItemCreate?: InboundItemInboundItemCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundItemJsonldInboundItemDetail>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).patchInboundItemItem(id, inboundItemInboundItemCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a InboundItem resource.
         * @param {InboundItemJsonldInboundItemCreate} [inboundItemJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInboundItemCollection(inboundItemJsonldInboundItemCreate?: InboundItemJsonldInboundItemCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundItemJsonldInboundItemDetail>> {
            const localVarAxiosArgs = await InboundItemApiAxiosParamCreator(configuration).postInboundItemCollection(inboundItemJsonldInboundItemCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InboundItemApi - factory interface
 * @export
 */
export const InboundItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Adds photos to Inbound Item
         * @param {string} id
         * @param {InboundItemPhotosJsonldInboundItemCreate} [inboundItemPhotosJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosInboundItemItem(id: string, inboundItemPhotosJsonldInboundItemCreate?: InboundItemPhotosJsonldInboundItemCreate, options?: any): AxiosPromise<InboundItemJsonldInboundItemDetail> {
            return InboundItemApiFp(configuration).addPhotosInboundItemItem(id, inboundItemPhotosJsonldInboundItemCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {string} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return InboundItemApiFp(configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Removes the InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInboundItemItem(id: string, options?: any): AxiosPromise<void> {
            return InboundItemApiFp(configuration).deleteInboundItemItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of InboundItem resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItemCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return InboundItemApiFp(configuration).getInboundItemCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a InboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundItemItem(id: string, options?: any): AxiosPromise<InboundItemJsonldInboundItemDetail> {
            return InboundItemApiFp(configuration).getInboundItemItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the InboundItem resource.
         * @param {string} id
         * @param {InboundItemInboundItemCreate} [inboundItemInboundItemCreate] The updated InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchInboundItemItem(id: string, inboundItemInboundItemCreate?: InboundItemInboundItemCreate, options?: any): AxiosPromise<InboundItemJsonldInboundItemDetail> {
            return InboundItemApiFp(configuration).patchInboundItemItem(id, inboundItemInboundItemCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a InboundItem resource.
         * @param {InboundItemJsonldInboundItemCreate} [inboundItemJsonldInboundItemCreate] The new InboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInboundItemCollection(inboundItemJsonldInboundItemCreate?: InboundItemJsonldInboundItemCreate, options?: any): AxiosPromise<InboundItemJsonldInboundItemDetail> {
            return InboundItemApiFp(configuration).postInboundItemCollection(inboundItemJsonldInboundItemCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboundItemApi - object-oriented interface
 * @export
 * @class InboundItemApi
 * @extends {BaseAPI}
 */
export class InboundItemApi extends BaseAPI {
    /**
     *
     * @summary Adds photos to Inbound Item
     * @param {string} id
     * @param {InboundItemPhotosJsonldInboundItemCreate} [inboundItemPhotosJsonldInboundItemCreate] The new InboundItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public addPhotosInboundItemItem(id: string, inboundItemPhotosJsonldInboundItemCreate?: InboundItemPhotosJsonldInboundItemCreate, options?: any) {
        return InboundItemApiFp(this.configuration).addPhotosInboundItemItem(id, inboundItemPhotosJsonldInboundItemCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of InboundItem resources.
     * @param {string} id
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public apiInboundsItemsGetSubresource(id: string, page?: number, itemsPerPage?: number, options?: any) {
        return InboundItemApiFp(this.configuration).apiInboundsItemsGetSubresource(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Removes the InboundItem resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public deleteInboundItemItem(id: string, options?: any) {
        return InboundItemApiFp(this.configuration).deleteInboundItemItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of InboundItem resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public getInboundItemCollection(page?: number, itemsPerPage?: number, options?: any) {
        return InboundItemApiFp(this.configuration).getInboundItemCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a InboundItem resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public getInboundItemItem(id: string, options?: any) {
        return InboundItemApiFp(this.configuration).getInboundItemItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the InboundItem resource.
     * @param {string} id
     * @param {InboundItemInboundItemCreate} [inboundItemInboundItemCreate] The updated InboundItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public patchInboundItemItem(id: string, inboundItemInboundItemCreate?: InboundItemInboundItemCreate, options?: any) {
        return InboundItemApiFp(this.configuration).patchInboundItemItem(id, inboundItemInboundItemCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a InboundItem resource.
     * @param {InboundItemJsonldInboundItemCreate} [inboundItemJsonldInboundItemCreate] The new InboundItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundItemApi
     */
    public postInboundItemCollection(inboundItemJsonldInboundItemCreate?: InboundItemJsonldInboundItemCreate, options?: any) {
        return InboundItemApiFp(this.configuration).postInboundItemCollection(inboundItemJsonldInboundItemCreate, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * InboundTransportApi - axios parameter creator
 * @export
 */
export const InboundTransportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Converts an InboundTransport to Picklist for orderpick zone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertToPicklistInboundTransportItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling convertToPicklistInboundTransportItem.');
            }
            const localVarPath = `/inbound-transports/{id}/convert/pick-list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundTransport resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundTransportCollection: async (marking?: string, marking2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound_transports`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a InboundTransport resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundTransportItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInboundTransportItem.');
            }
            const localVarPath = `/inbound_transports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get inbound transport stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundTransportStatsInboundTransportCollection: async (period?: string, date?: string, time?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound-transport/stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the target Location resource for an InboundTransport product.
         * @param {string} product
         * @param {string} fromLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTargetLocationInboundTransportItem: async (product: string, fromLocation: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling productTargetLocationInboundTransportItem.');
            }
            // verify required parameter 'fromLocation' is not null or undefined
            if (fromLocation === null || fromLocation === undefined) {
                throw new RequiredError('fromLocation','Required parameter fromLocation was null or undefined when calling productTargetLocationInboundTransportItem.');
            }
            const localVarPath = `/inbound_transport/product_target_location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (fromLocation !== undefined) {
                localVarQueryParameter['fromLocation'] = fromLocation;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an InboundTransport resource.
         * @param {string} id
         * @param {InboundTransportStateTransitionInboundTransportStateChange} [inboundTransportStateTransitionInboundTransportStateChange] The updated InboundTransport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeInboundTransportItem: async (id: string, inboundTransportStateTransitionInboundTransportStateChange?: InboundTransportStateTransitionInboundTransportStateChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stateChangeInboundTransportItem.');
            }
            const localVarPath = `/inbound_transports/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof inboundTransportStateTransitionInboundTransportStateChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inboundTransportStateTransitionInboundTransportStateChange !== undefined ? inboundTransportStateTransitionInboundTransportStateChange : {}) : (inboundTransportStateTransitionInboundTransportStateChange || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboundTransportApi - functional programming interface
 * @export
 */
export const InboundTransportApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Converts an InboundTransport to Picklist for orderpick zone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertToPicklistInboundTransportItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundTransportJsonldInboundTransportDetail>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).convertToPicklistInboundTransportItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of InboundTransport resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundTransportCollection(marking?: string, marking2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).getInboundTransportCollection(marking, marking2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a InboundTransport resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundTransportItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundTransportJsonldInboundTransportDetail>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).getInboundTransportItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get inbound transport stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboundTransportStatsInboundTransportCollection(period?: string, date?: string, time?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).inboundTransportStatsInboundTransportCollection(period, date, time, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the target Location resource for an InboundTransport product.
         * @param {string} product
         * @param {string} fromLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productTargetLocationInboundTransportItem(product: string, fromLocation: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundTransportJsonldInboundTransportDetail>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).productTargetLocationInboundTransportItem(product, fromLocation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an InboundTransport resource.
         * @param {string} id
         * @param {InboundTransportStateTransitionInboundTransportStateChange} [inboundTransportStateTransitionInboundTransportStateChange] The updated InboundTransport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stateChangeInboundTransportItem(id: string, inboundTransportStateTransitionInboundTransportStateChange?: InboundTransportStateTransitionInboundTransportStateChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboundTransportJsonldInboundTransportDetail>> {
            const localVarAxiosArgs = await InboundTransportApiAxiosParamCreator(configuration).stateChangeInboundTransportItem(id, inboundTransportStateTransitionInboundTransportStateChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InboundTransportApi - factory interface
 * @export
 */
export const InboundTransportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Converts an InboundTransport to Picklist for orderpick zone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertToPicklistInboundTransportItem(id: string, options?: any): AxiosPromise<InboundTransportJsonldInboundTransportDetail> {
            return InboundTransportApiFp(configuration).convertToPicklistInboundTransportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of InboundTransport resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundTransportCollection(marking?: string, marking2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2007> {
            return InboundTransportApiFp(configuration).getInboundTransportCollection(marking, marking2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a InboundTransport resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundTransportItem(id: string, options?: any): AxiosPromise<InboundTransportJsonldInboundTransportDetail> {
            return InboundTransportApiFp(configuration).getInboundTransportItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get inbound transport stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundTransportStatsInboundTransportCollection(period?: string, date?: string, time?: string, options?: any): AxiosPromise<InlineResponse2004> {
            return InboundTransportApiFp(configuration).inboundTransportStatsInboundTransportCollection(period, date, time, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the target Location resource for an InboundTransport product.
         * @param {string} product
         * @param {string} fromLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTargetLocationInboundTransportItem(product: string, fromLocation: string, options?: any): AxiosPromise<InboundTransportJsonldInboundTransportDetail> {
            return InboundTransportApiFp(configuration).productTargetLocationInboundTransportItem(product, fromLocation, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the current workflow state for an InboundTransport resource.
         * @param {string} id
         * @param {InboundTransportStateTransitionInboundTransportStateChange} [inboundTransportStateTransitionInboundTransportStateChange] The updated InboundTransport resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeInboundTransportItem(id: string, inboundTransportStateTransitionInboundTransportStateChange?: InboundTransportStateTransitionInboundTransportStateChange, options?: any): AxiosPromise<InboundTransportJsonldInboundTransportDetail> {
            return InboundTransportApiFp(configuration).stateChangeInboundTransportItem(id, inboundTransportStateTransitionInboundTransportStateChange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboundTransportApi - object-oriented interface
 * @export
 * @class InboundTransportApi
 * @extends {BaseAPI}
 */
export class InboundTransportApi extends BaseAPI {
    /**
     *
     * @summary Converts an InboundTransport to Picklist for orderpick zone
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public convertToPicklistInboundTransportItem(id: string, options?: any) {
        return InboundTransportApiFp(this.configuration).convertToPicklistInboundTransportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of InboundTransport resources.
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public getInboundTransportCollection(marking?: string, marking2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return InboundTransportApiFp(this.configuration).getInboundTransportCollection(marking, marking2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a InboundTransport resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public getInboundTransportItem(id: string, options?: any) {
        return InboundTransportApiFp(this.configuration).getInboundTransportItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get inbound transport stats.
     * @param {string} [period]
     * @param {string} [date]
     * @param {string} [time]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public inboundTransportStatsInboundTransportCollection(period?: string, date?: string, time?: string, options?: any) {
        return InboundTransportApiFp(this.configuration).inboundTransportStatsInboundTransportCollection(period, date, time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the target Location resource for an InboundTransport product.
     * @param {string} product
     * @param {string} fromLocation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public productTargetLocationInboundTransportItem(product: string, fromLocation: string, options?: any) {
        return InboundTransportApiFp(this.configuration).productTargetLocationInboundTransportItem(product, fromLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the current workflow state for an InboundTransport resource.
     * @param {string} id
     * @param {InboundTransportStateTransitionInboundTransportStateChange} [inboundTransportStateTransitionInboundTransportStateChange] The updated InboundTransport resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboundTransportApi
     */
    public stateChangeInboundTransportItem(id: string, inboundTransportStateTransitionInboundTransportStateChange?: InboundTransportStateTransitionInboundTransportStateChange, options?: any) {
        return InboundTransportApiFp(this.configuration).stateChangeInboundTransportItem(id, inboundTransportStateTransitionInboundTransportStateChange, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a Job resource.
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobItem: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getJobItem.');
            }
            const localVarPath = `/jobs/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsJobCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/it/jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a Job resource.
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobItem(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await JobApiAxiosParamCreator(configuration).getJobItem(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsJobCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await JobApiAxiosParamCreator(configuration).jobsJobCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves a Job resource.
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobItem(name: string, options?: any): AxiosPromise<void> {
            return JobApiFp(configuration).getJobItem(name, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsJobCollection(options?: any): AxiosPromise<InlineResponse2009> {
            return JobApiFp(configuration).jobsJobCollection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     *
     * @summary Retrieves a Job resource.
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getJobItem(name: string, options?: any) {
        return JobApiFp(this.configuration).getJobItem(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public jobsJobCollection(options?: any) {
        return JobApiFp(this.configuration).jobsJobCollection(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get next balance Location.
         * @param {string} row
         * @param {boolean} stockBalance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceNextLocationItem: async (row: string, stockBalance: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'row' is not null or undefined
            if (row === null || row === undefined) {
                throw new RequiredError('row','Required parameter row was null or undefined when calling balanceNextLocationItem.');
            }
            // verify required parameter 'stockBalance' is not null or undefined
            if (stockBalance === null || stockBalance === undefined) {
                throw new RequiredError('stockBalance','Required parameter stockBalance was null or undefined when calling balanceNextLocationItem.');
            }
            const localVarPath = `/balance/row/next`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (row !== undefined) {
                localVarQueryParameter['row'] = row;
            }

            if (stockBalance !== undefined) {
                localVarQueryParameter['stock_balance'] = stockBalance;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartZonesLocationCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbound-cart-zones`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Removes the Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLocationItem.');
            }
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {string} [barcode]
         * @param {boolean} [manco]
         * @param {boolean} [enabled]
         * @param {boolean} [full]
         * @param {number} [level]
         * @param {Array<number>} [level2]
         * @param {boolean} [mobile]
         * @param {boolean} [stock]
         * @param {Array<string>} [zone]
         * @param {Array<string>} [excludedZone]
         * @param {boolean} [pickOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationCollection: async (barcode?: string, manco?: boolean, enabled?: boolean, full?: boolean, level?: number, level2?: Array<number>, mobile?: boolean, stock?: boolean, zone?: Array<string>, excludedZone?: Array<string>, pickOrder?: boolean, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/locations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (manco !== undefined) {
                localVarQueryParameter['manco'] = manco;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['enabled'] = enabled;
            }

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (level2) {
                localVarQueryParameter['level[]'] = level2;
            }

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }

            if (stock !== undefined) {
                localVarQueryParameter['stock'] = stock;
            }

            if (zone) {
                localVarQueryParameter['zone[]'] = zone;
            }

            if (excludedZone) {
                localVarQueryParameter['excluded_zone[]'] = excludedZone;
            }

            if (pickOrder !== undefined) {
                localVarQueryParameter['pick_order'] = pickOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLocationItem.');
            }
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Import locations from CSV.
         * @param {LocationUploadFileJsonldLocationCreate} [locationUploadFileJsonldLocationCreate] The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLocationCsvLocationCollection: async (locationUploadFileJsonldLocationCreate?: LocationUploadFileJsonldLocationCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location/import-csv`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof locationUploadFileJsonldLocationCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationUploadFileJsonldLocationCreate !== undefined ? locationUploadFileJsonldLocationCreate : {}) : (locationUploadFileJsonldLocationCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Invalidate location cache.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateLocationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invalidateLocationItem.');
            }
            const localVarPath = `/location/{id}/invalidate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationRowsLocationCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_rows`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Location resource.
         * @param {string} id
         * @param {LocationLocationCreate} [locationLocationCreate] The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLocationItem: async (id: string, locationLocationCreate?: LocationLocationCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchLocationItem.');
            }
            const localVarPath = `/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof locationLocationCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(locationLocationCreate !== undefined ? locationLocationCreate : {}) : (locationLocationCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query a Location resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryLocationItem: async (barcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling queryLocationItem.');
            }
            const localVarPath = `/location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get inbound items linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnInboundItemsLocationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling returnInboundItemsLocationItem.');
            }
            const localVarPath = `/location/{id}/return_inbound_items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get outbounds linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnOutboundsLocationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling returnOutboundsLocationItem.');
            }
            const localVarPath = `/location/{id}/return_outbounds`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockRowsLocationCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_stock_rows`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query Location zones
         * @param {boolean} [stat]
         * @param {Array<string>} [exclude]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesLocationCollection: async (stat?: boolean, exclude?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_zones`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (stat !== undefined) {
                localVarQueryParameter['stat'] = stat;
            }

            if (exclude) {
                localVarQueryParameter['exclude[]'] = exclude;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get next balance Location.
         * @param {string} row
         * @param {boolean} stockBalance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceNextLocationItem(row: string, stockBalance: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).balanceNextLocationItem(row, stockBalance, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartZonesLocationCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).cartZonesLocationCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Removes the Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).deleteLocationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {string} [barcode]
         * @param {boolean} [manco]
         * @param {boolean} [enabled]
         * @param {boolean} [full]
         * @param {number} [level]
         * @param {Array<number>} [level2]
         * @param {boolean} [mobile]
         * @param {boolean} [stock]
         * @param {Array<string>} [zone]
         * @param {Array<string>} [excludedZone]
         * @param {boolean} [pickOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationCollection(barcode?: string, manco?: boolean, enabled?: boolean, full?: boolean, level?: number, level2?: Array<number>, mobile?: boolean, stock?: boolean, zone?: Array<string>, excludedZone?: Array<string>, pickOrder?: boolean, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).getLocationCollection(barcode, manco, enabled, full, level, level2, mobile, stock, zone, excludedZone, pickOrder, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).getLocationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Import locations from CSV.
         * @param {LocationUploadFileJsonldLocationCreate} [locationUploadFileJsonldLocationCreate] The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate?: LocationUploadFileJsonldLocationCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Invalidate location cache.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateLocationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).invalidateLocationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).locationRowsLocationCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Location resource.
         * @param {string} id
         * @param {LocationLocationCreate} [locationLocationCreate] The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLocationItem(id: string, locationLocationCreate?: LocationLocationCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).patchLocationItem(id, locationLocationCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query a Location resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryLocationItem(barcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationDetail>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).queryLocationItem(barcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get inbound items linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnInboundItemsLocationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationReturnInboundItems>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).returnInboundItemsLocationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get outbounds linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnOutboundsLocationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationJsonldLocationReturnOutbounds>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).returnOutboundsLocationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).stockRowsLocationCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query Location zones
         * @param {boolean} [stat]
         * @param {Array<string>} [exclude]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zonesLocationCollection(stat?: boolean, exclude?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await LocationApiAxiosParamCreator(configuration).zonesLocationCollection(stat, exclude, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Get next balance Location.
         * @param {string} row
         * @param {boolean} stockBalance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceNextLocationItem(row: string, stockBalance: boolean, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).balanceNextLocationItem(row, stockBalance, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartZonesLocationCollection(options?: any): AxiosPromise<InlineResponse2003> {
            return LocationApiFp(configuration).cartZonesLocationCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Removes the Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationItem(id: string, options?: any): AxiosPromise<void> {
            return LocationApiFp(configuration).deleteLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {string} [barcode]
         * @param {boolean} [manco]
         * @param {boolean} [enabled]
         * @param {boolean} [full]
         * @param {number} [level]
         * @param {Array<number>} [level2]
         * @param {boolean} [mobile]
         * @param {boolean} [stock]
         * @param {Array<string>} [zone]
         * @param {Array<string>} [excludedZone]
         * @param {boolean} [pickOrder]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationCollection(barcode?: string, manco?: boolean, enabled?: boolean, full?: boolean, level?: number, level2?: Array<number>, mobile?: boolean, stock?: boolean, zone?: Array<string>, excludedZone?: Array<string>, pickOrder?: boolean, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return LocationApiFp(configuration).getLocationCollection(barcode, manco, enabled, full, level, level2, mobile, stock, zone, excludedZone, pickOrder, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Location resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationItem(id: string, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).getLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Import locations from CSV.
         * @param {LocationUploadFileJsonldLocationCreate} [locationUploadFileJsonldLocationCreate] The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate?: LocationUploadFileJsonldLocationCreate, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invalidate location cache.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateLocationItem(id: string, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).invalidateLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return LocationApiFp(configuration).locationRowsLocationCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Location resource.
         * @param {string} id
         * @param {LocationLocationCreate} [locationLocationCreate] The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLocationItem(id: string, locationLocationCreate?: LocationLocationCreate, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).patchLocationItem(id, locationLocationCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query a Location resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryLocationItem(barcode: string, options?: any): AxiosPromise<LocationJsonldLocationDetail> {
            return LocationApiFp(configuration).queryLocationItem(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get inbound items linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnInboundItemsLocationItem(id: string, options?: any): AxiosPromise<LocationJsonldLocationReturnInboundItems> {
            return LocationApiFp(configuration).returnInboundItemsLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get outbounds linked to return locations.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnOutboundsLocationItem(id: string, options?: any): AxiosPromise<LocationJsonldLocationReturnOutbounds> {
            return LocationApiFp(configuration).returnOutboundsLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return LocationApiFp(configuration).stockRowsLocationCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query Location zones
         * @param {boolean} [stat]
         * @param {Array<string>} [exclude]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zonesLocationCollection(stat?: boolean, exclude?: Array<string>, options?: any): AxiosPromise<InlineResponse2003> {
            return LocationApiFp(configuration).zonesLocationCollection(stat, exclude, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     *
     * @summary Get next balance Location.
     * @param {string} row
     * @param {boolean} stockBalance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public balanceNextLocationItem(row: string, stockBalance: boolean, options?: any) {
        return LocationApiFp(this.configuration).balanceNextLocationItem(row, stockBalance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Location resources.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public cartZonesLocationCollection(options?: any) {
        return LocationApiFp(this.configuration).cartZonesLocationCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Removes the Location resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocationItem(id: string, options?: any) {
        return LocationApiFp(this.configuration).deleteLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Location resources.
     * @param {string} [barcode]
     * @param {boolean} [manco]
     * @param {boolean} [enabled]
     * @param {boolean} [full]
     * @param {number} [level]
     * @param {Array<number>} [level2]
     * @param {boolean} [mobile]
     * @param {boolean} [stock]
     * @param {Array<string>} [zone]
     * @param {Array<string>} [excludedZone]
     * @param {boolean} [pickOrder]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationCollection(barcode?: string, manco?: boolean, enabled?: boolean, full?: boolean, level?: number, level2?: Array<number>, mobile?: boolean, stock?: boolean, zone?: Array<string>, excludedZone?: Array<string>, pickOrder?: boolean, page?: number, itemsPerPage?: number, options?: any) {
        return LocationApiFp(this.configuration).getLocationCollection(barcode, manco, enabled, full, level, level2, mobile, stock, zone, excludedZone, pickOrder, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Location resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationItem(id: string, options?: any) {
        return LocationApiFp(this.configuration).getLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Import locations from CSV.
     * @param {LocationUploadFileJsonldLocationCreate} [locationUploadFileJsonldLocationCreate] The new Location resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate?: LocationUploadFileJsonldLocationCreate, options?: any) {
        return LocationApiFp(this.configuration).importLocationCsvLocationCollection(locationUploadFileJsonldLocationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Invalidate location cache.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public invalidateLocationItem(id: string, options?: any) {
        return LocationApiFp(this.configuration).invalidateLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Location resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any) {
        return LocationApiFp(this.configuration).locationRowsLocationCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Location resource.
     * @param {string} id
     * @param {LocationLocationCreate} [locationLocationCreate] The updated Location resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public patchLocationItem(id: string, locationLocationCreate?: LocationLocationCreate, options?: any) {
        return LocationApiFp(this.configuration).patchLocationItem(id, locationLocationCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query a Location resource.
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public queryLocationItem(barcode: string, options?: any) {
        return LocationApiFp(this.configuration).queryLocationItem(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get inbound items linked to return locations.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public returnInboundItemsLocationItem(id: string, options?: any) {
        return LocationApiFp(this.configuration).returnInboundItemsLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get outbounds linked to return locations.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public returnOutboundsLocationItem(id: string, options?: any) {
        return LocationApiFp(this.configuration).returnOutboundsLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Location resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public stockRowsLocationCollection(page?: number, itemsPerPage?: number, options?: any) {
        return LocationApiFp(this.configuration).stockRowsLocationCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query Location zones
     * @param {boolean} [stat]
     * @param {Array<string>} [exclude]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public zonesLocationCollection(stat?: boolean, exclude?: Array<string>, options?: any) {
        return LocationApiFp(this.configuration).zonesLocationCollection(stat, exclude, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LocationMutationApi - axios parameter creator
 * @export
 */
export const LocationMutationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationMutation resources.
         * @param {string} [location]
         * @param {Array<string>} [location2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationMutationCollection: async (location?: string, location2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_mutations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (location2) {
                localVarQueryParameter['location[]'] = location2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a LocationMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationMutationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLocationMutationItem.');
            }
            const localVarPath = `/location_mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationMutationApi - functional programming interface
 * @export
 */
export const LocationMutationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationMutation resources.
         * @param {string} [location]
         * @param {Array<string>} [location2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationMutationCollection(location?: string, location2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await LocationMutationApiAxiosParamCreator(configuration).getLocationMutationCollection(location, location2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a LocationMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationMutationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationMutationJsonldLocationMutationDetail>> {
            const localVarAxiosArgs = await LocationMutationApiAxiosParamCreator(configuration).getLocationMutationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocationMutationApi - factory interface
 * @export
 */
export const LocationMutationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationMutation resources.
         * @param {string} [location]
         * @param {Array<string>} [location2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationMutationCollection(location?: string, location2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return LocationMutationApiFp(configuration).getLocationMutationCollection(location, location2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a LocationMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationMutationItem(id: string, options?: any): AxiosPromise<LocationMutationJsonldLocationMutationDetail> {
            return LocationMutationApiFp(configuration).getLocationMutationItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationMutationApi - object-oriented interface
 * @export
 * @class LocationMutationApi
 * @extends {BaseAPI}
 */
export class LocationMutationApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of LocationMutation resources.
     * @param {string} [location]
     * @param {Array<string>} [location2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationMutationApi
     */
    public getLocationMutationCollection(location?: string, location2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return LocationMutationApiFp(this.configuration).getLocationMutationCollection(location, location2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a LocationMutation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationMutationApi
     */
    public getLocationMutationItem(id: string, options?: any) {
        return LocationMutationApiFp(this.configuration).getLocationMutationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LocationTypeApi - axios parameter creator
 * @export
 */
export const LocationTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationTypeCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/location_types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a LocationType resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationTypeItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getLocationTypeItem.');
            }
            const localVarPath = `/location_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationTypeApi - functional programming interface
 * @export
 */
export const LocationTypeApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationTypeCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20012>> {
            const localVarAxiosArgs = await LocationTypeApiAxiosParamCreator(configuration).getLocationTypeCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a LocationType resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationTypeItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationTypeJsonldLocationTypeDetail>> {
            const localVarAxiosArgs = await LocationTypeApiAxiosParamCreator(configuration).getLocationTypeItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocationTypeApi - factory interface
 * @export
 */
export const LocationTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of LocationType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationTypeCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20012> {
            return LocationTypeApiFp(configuration).getLocationTypeCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a LocationType resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationTypeItem(id: string, options?: any): AxiosPromise<LocationTypeJsonldLocationTypeDetail> {
            return LocationTypeApiFp(configuration).getLocationTypeItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationTypeApi - object-oriented interface
 * @export
 * @class LocationTypeApi
 * @extends {BaseAPI}
 */
export class LocationTypeApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of LocationType resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationTypeApi
     */
    public getLocationTypeCollection(page?: number, itemsPerPage?: number, options?: any) {
        return LocationTypeApiFp(this.configuration).getLocationTypeCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a LocationType resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationTypeApi
     */
    public getLocationTypeItem(id: string, options?: any) {
        return LocationTypeApiFp(this.configuration).getLocationTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundApi - axios parameter creator
 * @export
 */
export const OutboundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOutboundsItemsGetSubresource: async (id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiOutboundsItemsGetSubresource.');
            }
            const localVarPath = `/outbounds/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (productEan !== undefined) {
                localVarQueryParameter['product.ean'] = productEan;
            }

            if (productEan2) {
                localVarQueryParameter['product.ean[]'] = productEan2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Assign an Outbound resource to the current user.
         * @param {string} id
         * @param {OutboundOutboundWorkspaceJsonld} [body] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOutboundItem: async (id: string, body?: OutboundOutboundWorkspaceJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling assignOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Determine if user can pack an outbound.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPackOutboundOutboundCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbounds/can-pack`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Collect an outbound on cart.
         * @param {number} id
         * @param {number} stock
         * @param {string} [workspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOnCartOutboundItem: async (id: number, stock: number, workspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling collectOnCartOutboundItem.');
            }
            // verify required parameter 'stock' is not null or undefined
            if (stock === null || stock === undefined) {
                throw new RequiredError('stock','Required parameter stock was null or undefined when calling collectOnCartOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/collect-on-cart/{stock}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"stock"}}`, encodeURIComponent(String(stock)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (workspace !== undefined) {
                localVarQueryParameter['workspace'] = workspace;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Collected outbounds on cart.
         * @param {number} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectedOnCartOutboundCollection: async (id: number, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling collectedOnCartOutboundCollection.');
            }
            const localVarPath = `/outbounds/collected-on-cart/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Replaces the Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckOrderInfoOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cvpSparckOrderInfoOutboundItem.');
            }
            const localVarPath = `/cvp/sparck/V1/orderinfo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of OrderDocument resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling documentsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of existing OrderShippingLabel resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existingLabelsOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling existingLabelsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/existing-labels`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Outbound resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [reference]
         * @param {string} [itemsProductEan]
         * @param {string} [distributorCode]
         * @param {Array<string>} [distributorCode2]
         * @param {boolean} [existsAssignee]
         * @param {boolean} [existsRequestedShipDate]
         * @param {'asc' | 'desc'} [orderCutOffTime]
         * @param {Array<string>} [locationZone]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundCollection: async (marking?: string, marking2?: Array<string>, reference?: string, itemsProductEan?: string, distributorCode?: string, distributorCode2?: Array<string>, existsAssignee?: boolean, existsRequestedShipDate?: boolean, orderCutOffTime?: 'asc' | 'desc', locationZone?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbounds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (itemsProductEan !== undefined) {
                localVarQueryParameter['items.product.ean'] = itemsProductEan;
            }

            if (distributorCode !== undefined) {
                localVarQueryParameter['distributor.code'] = distributorCode;
            }

            if (distributorCode2) {
                localVarQueryParameter['distributor.code[]'] = distributorCode2;
            }

            if (existsAssignee !== undefined) {
                localVarQueryParameter['exists[assignee]'] = existsAssignee;
            }

            if (existsRequestedShipDate !== undefined) {
                localVarQueryParameter['exists[requestedShipDate]'] = existsRequestedShipDate;
            }

            if (orderCutOffTime !== undefined) {
                localVarQueryParameter['order[cutOffTime]'] = orderCutOffTime;
            }

            if (locationZone) {
                localVarQueryParameter['location.zone[]'] = locationZone;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Adds CMS log for Outbound.
         * @param {string} id
         * @param {OutboundLogOutboundJsonld} [outboundLogOutboundJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOutboundItem: async (id: string, outboundLogOutboundJsonld?: OutboundLogOutboundJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/log`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundLogOutboundJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundLogOutboundJsonld !== undefined ? outboundLogOutboundJsonld : {}) : (outboundLogOutboundJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Outbound overview
         * @param {string} [reference]
         * @param {string} [status]
         * @param {string} [ean]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outboundsOverviewOutboundCollection: async (reference?: string, status?: string, ean?: string, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbounds/overview`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundPackingMaterial resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packingMaterialsOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling packingMaterialsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/packing_materials`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Outbound resource.
         * @param {string} id
         * @param {OutboundOutboundEdit} [outboundOutboundEdit] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundItem: async (id: string, outboundOutboundEdit?: OutboundOutboundEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundOutboundEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundOutboundEdit !== undefined ? outboundOutboundEdit : {}) : (outboundOutboundEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a Outbound resource.
         * @param {OutboundExternalOrderJsonld} [outboundExternalOrderJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundCollection: async (outboundExternalOrderJsonld?: OutboundExternalOrderJsonld, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbounds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundExternalOrderJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundExternalOrderJsonld !== undefined ? outboundExternalOrderJsonld : {}) : (outboundExternalOrderJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Prints documents for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundDocumentsJsonld} [outboundPrintOutboundDocumentsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printDocumentsOutboundItem: async (id: string, outboundPrintOutboundDocumentsJsonld?: OutboundPrintOutboundDocumentsJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling printDocumentsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/print_documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundPrintOutboundDocumentsJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundPrintOutboundDocumentsJsonld !== undefined ? outboundPrintOutboundDocumentsJsonld : {}) : (outboundPrintOutboundDocumentsJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Prints new, extra or existing labels for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundLabelsJsonld} [outboundPrintOutboundLabelsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printLabelsOutboundItem: async (id: string, outboundPrintOutboundLabelsJsonld?: OutboundPrintOutboundLabelsJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling printLabelsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/print_labels`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundPrintOutboundLabelsJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundPrintOutboundLabelsJsonld !== undefined ? outboundPrintOutboundLabelsJsonld : {}) : (outboundPrintOutboundLabelsJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query an Outbound resource.
         * @param {string} reference
         * @param {boolean} [_import]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryOutboundItem: async (reference: string, _import?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            if (reference === null || reference === undefined) {
                throw new RequiredError('reference','Required parameter reference was null or undefined when calling queryOutboundItem.');
            }
            const localVarPath = `/outbound`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (reference !== undefined) {
                localVarQueryParameter['reference'] = reference;
            }

            if (_import !== undefined) {
                localVarQueryParameter['import'] = _import;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the Outbound resource for an return label barcode.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryReturnLabelOutboundCollection: async (barcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling queryReturnLabelOutboundCollection.');
            }
            const localVarPath = `/outbounds/return-label`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Return Requests resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnRequestsOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling returnRequestsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/return_requests`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set distributor for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundDistributorJsonld} [outboundOutboundDistributorJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDistributorOutboundItem: async (id: string, outboundOutboundDistributorJsonld?: OutboundOutboundDistributorJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setDistributorOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/distributor/set`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundOutboundDistributorJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundOutboundDistributorJsonld !== undefined ? outboundOutboundDistributorJsonld : {}) : (outboundOutboundDistributorJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Sets the ShippingMethod for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundShippingMethodJsonld} [outboundOutboundShippingMethodJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShippingMethodOutboundItem: async (id: string, outboundOutboundShippingMethodJsonld?: OutboundOutboundShippingMethodJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setShippingMethodOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/shipping_methods/set`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundOutboundShippingMethodJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundOutboundShippingMethodJsonld !== undefined ? outboundOutboundShippingMethodJsonld : {}) : (outboundOutboundShippingMethodJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of ShippingMethod resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingMethodsOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling shippingMethodsOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/shipping_methods`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an Outbound resource.
         * @param {string} id
         * @param {OutboundStateTransitionOutboundStateChange} [outboundStateTransitionOutboundStateChange] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeOutboundItem: async (id: string, outboundStateTransitionOutboundStateChange?: OutboundStateTransitionOutboundStateChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stateChangeOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundStateTransitionOutboundStateChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundStateTransitionOutboundStateChange !== undefined ? outboundStateTransitionOutboundStateChange : {}) : (outboundStateTransitionOutboundStateChange || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the suggested PackingMaterial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedPackingMaterialOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling suggestedPackingMaterialOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/suggested-packing-material`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Unassign an Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignOutboundItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unassignOutboundItem.');
            }
            const localVarPath = `/outbounds/{id}/unassign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundApi - functional programming interface
 * @export
 */
export const OutboundApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Assign an Outbound resource to the current user.
         * @param {string} id
         * @param {OutboundOutboundWorkspaceJsonld} [body] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignOutboundItem(id: string, body?: OutboundOutboundWorkspaceJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).assignOutboundItem(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Determine if user can pack an outbound.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async canPackOutboundOutboundCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).canPackOutboundOutboundCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Collect an outbound on cart.
         * @param {number} id
         * @param {number} stock
         * @param {string} [workspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectOnCartOutboundItem(id: number, stock: number, workspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).collectOnCartOutboundItem(id, stock, workspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Collected outbounds on cart.
         * @param {number} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectedOnCartOutboundCollection(id: number, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).collectedOnCartOutboundCollection(id, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Replaces the Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cvpSparckOrderInfoOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).cvpSparckOrderInfoOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of OrderDocument resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDocumentDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).documentsOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of existing OrderShippingLabel resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async existingLabelsOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundShippingLabelDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).existingLabelsOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Outbound resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [reference]
         * @param {string} [itemsProductEan]
         * @param {string} [distributorCode]
         * @param {Array<string>} [distributorCode2]
         * @param {boolean} [existsAssignee]
         * @param {boolean} [existsRequestedShipDate]
         * @param {'asc' | 'desc'} [orderCutOffTime]
         * @param {Array<string>} [locationZone]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundCollection(marking?: string, marking2?: Array<string>, reference?: string, itemsProductEan?: string, distributorCode?: string, distributorCode2?: Array<string>, existsAssignee?: boolean, existsRequestedShipDate?: boolean, orderCutOffTime?: 'asc' | 'desc', locationZone?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).getOutboundCollection(marking, marking2, reference, itemsProductEan, distributorCode, distributorCode2, existsAssignee, existsRequestedShipDate, orderCutOffTime, locationZone, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).getOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Adds CMS log for Outbound.
         * @param {string} id
         * @param {OutboundLogOutboundJsonld} [outboundLogOutboundJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logOutboundItem(id: string, outboundLogOutboundJsonld?: OutboundLogOutboundJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).logOutboundItem(id, outboundLogOutboundJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Outbound overview
         * @param {string} [reference]
         * @param {string} [status]
         * @param {string} [ean]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outboundsOverviewOutboundCollection(reference?: string, status?: string, ean?: string, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20017>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).outboundsOverviewOutboundCollection(reference, status, ean, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundPackingMaterial resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async packingMaterialsOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundPackingMaterialDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).packingMaterialsOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Outbound resource.
         * @param {string} id
         * @param {OutboundOutboundEdit} [outboundOutboundEdit] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOutboundItem(id: string, outboundOutboundEdit?: OutboundOutboundEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).patchOutboundItem(id, outboundOutboundEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a Outbound resource.
         * @param {OutboundExternalOrderJsonld} [outboundExternalOrderJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOutboundCollection(outboundExternalOrderJsonld?: OutboundExternalOrderJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).postOutboundCollection(outboundExternalOrderJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Prints documents for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundDocumentsJsonld} [outboundPrintOutboundDocumentsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printDocumentsOutboundItem(id: string, outboundPrintOutboundDocumentsJsonld?: OutboundPrintOutboundDocumentsJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).printDocumentsOutboundItem(id, outboundPrintOutboundDocumentsJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Prints new, extra or existing labels for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundLabelsJsonld} [outboundPrintOutboundLabelsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printLabelsOutboundItem(id: string, outboundPrintOutboundLabelsJsonld?: OutboundPrintOutboundLabelsJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).printLabelsOutboundItem(id, outboundPrintOutboundLabelsJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query an Outbound resource.
         * @param {string} reference
         * @param {boolean} [_import]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryOutboundItem(reference: string, _import?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).queryOutboundItem(reference, _import, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the Outbound resource for an return label barcode.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryReturnLabelOutboundCollection(barcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20018>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).queryReturnLabelOutboundCollection(barcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Return Requests resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnRequestsOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldReturnRequestDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).returnRequestsOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Set distributor for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundDistributorJsonld} [outboundOutboundDistributorJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDistributorOutboundItem(id: string, outboundOutboundDistributorJsonld?: OutboundOutboundDistributorJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).setDistributorOutboundItem(id, outboundOutboundDistributorJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Sets the ShippingMethod for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundShippingMethodJsonld} [outboundOutboundShippingMethodJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setShippingMethodOutboundItem(id: string, outboundOutboundShippingMethodJsonld?: OutboundOutboundShippingMethodJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).setShippingMethodOutboundItem(id, outboundOutboundShippingMethodJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of ShippingMethod resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingMethodsOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundShippingMethodDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).shippingMethodsOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an Outbound resource.
         * @param {string} id
         * @param {OutboundStateTransitionOutboundStateChange} [outboundStateTransitionOutboundStateChange] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stateChangeOutboundItem(id: string, outboundStateTransitionOutboundStateChange?: OutboundStateTransitionOutboundStateChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).stateChangeOutboundItem(id, outboundStateTransitionOutboundStateChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the suggested PackingMaterial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestedPackingMaterialOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldPackingMaterialDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).suggestedPackingMaterialOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Unassign an Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignOutboundItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundJsonldOutboundDetail>> {
            const localVarAxiosArgs = await OutboundApiAxiosParamCreator(configuration).unassignOutboundItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundApi - factory interface
 * @export
 */
export const OutboundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return OutboundApiFp(configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Assign an Outbound resource to the current user.
         * @param {string} id
         * @param {OutboundOutboundWorkspaceJsonld} [body] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignOutboundItem(id: string, body?: OutboundOutboundWorkspaceJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).assignOutboundItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Determine if user can pack an outbound.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canPackOutboundOutboundCollection(options?: any): AxiosPromise<InlineResponse20018> {
            return OutboundApiFp(configuration).canPackOutboundOutboundCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Collect an outbound on cart.
         * @param {number} id
         * @param {number} stock
         * @param {string} [workspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOnCartOutboundItem(id: number, stock: number, workspace?: string, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).collectOnCartOutboundItem(id, stock, workspace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Collected outbounds on cart.
         * @param {number} id
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectedOnCartOutboundCollection(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20018> {
            return OutboundApiFp(configuration).collectedOnCartOutboundCollection(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Replaces the Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckOrderInfoOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).cvpSparckOrderInfoOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of OrderDocument resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundDocumentDetail> {
            return OutboundApiFp(configuration).documentsOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of existing OrderShippingLabel resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        existingLabelsOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundShippingLabelDetail> {
            return OutboundApiFp(configuration).existingLabelsOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Outbound resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [reference]
         * @param {string} [itemsProductEan]
         * @param {string} [distributorCode]
         * @param {Array<string>} [distributorCode2]
         * @param {boolean} [existsAssignee]
         * @param {boolean} [existsRequestedShipDate]
         * @param {'asc' | 'desc'} [orderCutOffTime]
         * @param {Array<string>} [locationZone]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundCollection(marking?: string, marking2?: Array<string>, reference?: string, itemsProductEan?: string, distributorCode?: string, distributorCode2?: Array<string>, existsAssignee?: boolean, existsRequestedShipDate?: boolean, orderCutOffTime?: 'asc' | 'desc', locationZone?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20017> {
            return OutboundApiFp(configuration).getOutboundCollection(marking, marking2, reference, itemsProductEan, distributorCode, distributorCode2, existsAssignee, existsRequestedShipDate, orderCutOffTime, locationZone, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).getOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Adds CMS log for Outbound.
         * @param {string} id
         * @param {OutboundLogOutboundJsonld} [outboundLogOutboundJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logOutboundItem(id: string, outboundLogOutboundJsonld?: OutboundLogOutboundJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).logOutboundItem(id, outboundLogOutboundJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Outbound overview
         * @param {string} [reference]
         * @param {string} [status]
         * @param {string} [ean]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outboundsOverviewOutboundCollection(reference?: string, status?: string, ean?: string, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20017> {
            return OutboundApiFp(configuration).outboundsOverviewOutboundCollection(reference, status, ean, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of OutboundPackingMaterial resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packingMaterialsOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundPackingMaterialDetail> {
            return OutboundApiFp(configuration).packingMaterialsOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Outbound resource.
         * @param {string} id
         * @param {OutboundOutboundEdit} [outboundOutboundEdit] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundItem(id: string, outboundOutboundEdit?: OutboundOutboundEdit, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).patchOutboundItem(id, outboundOutboundEdit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a Outbound resource.
         * @param {OutboundExternalOrderJsonld} [outboundExternalOrderJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundCollection(outboundExternalOrderJsonld?: OutboundExternalOrderJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).postOutboundCollection(outboundExternalOrderJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Prints documents for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundDocumentsJsonld} [outboundPrintOutboundDocumentsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printDocumentsOutboundItem(id: string, outboundPrintOutboundDocumentsJsonld?: OutboundPrintOutboundDocumentsJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).printDocumentsOutboundItem(id, outboundPrintOutboundDocumentsJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Prints new, extra or existing labels for an Outbound.
         * @param {string} id
         * @param {OutboundPrintOutboundLabelsJsonld} [outboundPrintOutboundLabelsJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printLabelsOutboundItem(id: string, outboundPrintOutboundLabelsJsonld?: OutboundPrintOutboundLabelsJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).printLabelsOutboundItem(id, outboundPrintOutboundLabelsJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query an Outbound resource.
         * @param {string} reference
         * @param {boolean} [_import]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryOutboundItem(reference: string, _import?: boolean, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).queryOutboundItem(reference, _import, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the Outbound resource for an return label barcode.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryReturnLabelOutboundCollection(barcode: string, options?: any): AxiosPromise<InlineResponse20018> {
            return OutboundApiFp(configuration).queryReturnLabelOutboundCollection(barcode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Return Requests resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnRequestsOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldReturnRequestDetail> {
            return OutboundApiFp(configuration).returnRequestsOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set distributor for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundDistributorJsonld} [outboundOutboundDistributorJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDistributorOutboundItem(id: string, outboundOutboundDistributorJsonld?: OutboundOutboundDistributorJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).setDistributorOutboundItem(id, outboundOutboundDistributorJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Sets the ShippingMethod for an Outbound.
         * @param {string} id
         * @param {OutboundOutboundShippingMethodJsonld} [outboundOutboundShippingMethodJsonld] The new Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setShippingMethodOutboundItem(id: string, outboundOutboundShippingMethodJsonld?: OutboundOutboundShippingMethodJsonld, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).setShippingMethodOutboundItem(id, outboundOutboundShippingMethodJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of ShippingMethod resources for an Outbound.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingMethodsOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundShippingMethodDetail> {
            return OutboundApiFp(configuration).shippingMethodsOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the current workflow state for an Outbound resource.
         * @param {string} id
         * @param {OutboundStateTransitionOutboundStateChange} [outboundStateTransitionOutboundStateChange] The updated Outbound resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeOutboundItem(id: string, outboundStateTransitionOutboundStateChange?: OutboundStateTransitionOutboundStateChange, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).stateChangeOutboundItem(id, outboundStateTransitionOutboundStateChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the suggested PackingMaterial
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedPackingMaterialOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldPackingMaterialDetail> {
            return OutboundApiFp(configuration).suggestedPackingMaterialOutboundItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unassign an Outbound resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignOutboundItem(id: string, options?: any): AxiosPromise<OutboundJsonldOutboundDetail> {
            return OutboundApiFp(configuration).unassignOutboundItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundApi - object-oriented interface
 * @export
 * @class OutboundApi
 * @extends {BaseAPI}
 */
export class OutboundApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of OutboundItem resources.
     * @param {string} id
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [productEan]
     * @param {Array<string>} [productEan2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundApiFp(this.configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Assign an Outbound resource to the current user.
     * @param {string} id
     * @param {OutboundOutboundWorkspaceJsonld} [body] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public assignOutboundItem(id: string, body?: OutboundOutboundWorkspaceJsonld, options?: any) {
        return OutboundApiFp(this.configuration).assignOutboundItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Determine if user can pack an outbound.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public canPackOutboundOutboundCollection(options?: any) {
        return OutboundApiFp(this.configuration).canPackOutboundOutboundCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Collect an outbound on cart.
     * @param {number} id
     * @param {number} stock
     * @param {string} [workspace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public collectOnCartOutboundItem(id: number, stock: number, workspace?: string, options?: any) {
        return OutboundApiFp(this.configuration).collectOnCartOutboundItem(id, stock, workspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Collected outbounds on cart.
     * @param {number} id
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public collectedOnCartOutboundCollection(id: number, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundApiFp(this.configuration).collectedOnCartOutboundCollection(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Replaces the Outbound resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public cvpSparckOrderInfoOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).cvpSparckOrderInfoOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of OrderDocument resources for an Outbound.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public documentsOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).documentsOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of existing OrderShippingLabel resources for an Outbound.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public existingLabelsOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).existingLabelsOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Outbound resources.
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [reference]
     * @param {string} [itemsProductEan]
     * @param {string} [distributorCode]
     * @param {Array<string>} [distributorCode2]
     * @param {boolean} [existsAssignee]
     * @param {boolean} [existsRequestedShipDate]
     * @param {'asc' | 'desc'} [orderCutOffTime]
     * @param {Array<string>} [locationZone]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public getOutboundCollection(marking?: string, marking2?: Array<string>, reference?: string, itemsProductEan?: string, distributorCode?: string, distributorCode2?: Array<string>, existsAssignee?: boolean, existsRequestedShipDate?: boolean, orderCutOffTime?: 'asc' | 'desc', locationZone?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundApiFp(this.configuration).getOutboundCollection(marking, marking2, reference, itemsProductEan, distributorCode, distributorCode2, existsAssignee, existsRequestedShipDate, orderCutOffTime, locationZone, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Outbound resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public getOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).getOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Adds CMS log for Outbound.
     * @param {string} id
     * @param {OutboundLogOutboundJsonld} [outboundLogOutboundJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public logOutboundItem(id: string, outboundLogOutboundJsonld?: OutboundLogOutboundJsonld, options?: any) {
        return OutboundApiFp(this.configuration).logOutboundItem(id, outboundLogOutboundJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Outbound overview
     * @param {string} [reference]
     * @param {string} [status]
     * @param {string} [ean]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public outboundsOverviewOutboundCollection(reference?: string, status?: string, ean?: string, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundApiFp(this.configuration).outboundsOverviewOutboundCollection(reference, status, ean, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of OutboundPackingMaterial resources for an Outbound.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public packingMaterialsOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).packingMaterialsOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Outbound resource.
     * @param {string} id
     * @param {OutboundOutboundEdit} [outboundOutboundEdit] The updated Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public patchOutboundItem(id: string, outboundOutboundEdit?: OutboundOutboundEdit, options?: any) {
        return OutboundApiFp(this.configuration).patchOutboundItem(id, outboundOutboundEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a Outbound resource.
     * @param {OutboundExternalOrderJsonld} [outboundExternalOrderJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public postOutboundCollection(outboundExternalOrderJsonld?: OutboundExternalOrderJsonld, options?: any) {
        return OutboundApiFp(this.configuration).postOutboundCollection(outboundExternalOrderJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Prints documents for an Outbound.
     * @param {string} id
     * @param {OutboundPrintOutboundDocumentsJsonld} [outboundPrintOutboundDocumentsJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public printDocumentsOutboundItem(id: string, outboundPrintOutboundDocumentsJsonld?: OutboundPrintOutboundDocumentsJsonld, options?: any) {
        return OutboundApiFp(this.configuration).printDocumentsOutboundItem(id, outboundPrintOutboundDocumentsJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Prints new, extra or existing labels for an Outbound.
     * @param {string} id
     * @param {OutboundPrintOutboundLabelsJsonld} [outboundPrintOutboundLabelsJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public printLabelsOutboundItem(id: string, outboundPrintOutboundLabelsJsonld?: OutboundPrintOutboundLabelsJsonld, options?: any) {
        return OutboundApiFp(this.configuration).printLabelsOutboundItem(id, outboundPrintOutboundLabelsJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query an Outbound resource.
     * @param {string} reference
     * @param {boolean} [_import]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public queryOutboundItem(reference: string, _import?: boolean, options?: any) {
        return OutboundApiFp(this.configuration).queryOutboundItem(reference, _import, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the Outbound resource for an return label barcode.
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public queryReturnLabelOutboundCollection(barcode: string, options?: any) {
        return OutboundApiFp(this.configuration).queryReturnLabelOutboundCollection(barcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Return Requests resources for an Outbound.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public returnRequestsOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).returnRequestsOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Set distributor for an Outbound.
     * @param {string} id
     * @param {OutboundOutboundDistributorJsonld} [outboundOutboundDistributorJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public setDistributorOutboundItem(id: string, outboundOutboundDistributorJsonld?: OutboundOutboundDistributorJsonld, options?: any) {
        return OutboundApiFp(this.configuration).setDistributorOutboundItem(id, outboundOutboundDistributorJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Sets the ShippingMethod for an Outbound.
     * @param {string} id
     * @param {OutboundOutboundShippingMethodJsonld} [outboundOutboundShippingMethodJsonld] The new Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public setShippingMethodOutboundItem(id: string, outboundOutboundShippingMethodJsonld?: OutboundOutboundShippingMethodJsonld, options?: any) {
        return OutboundApiFp(this.configuration).setShippingMethodOutboundItem(id, outboundOutboundShippingMethodJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of ShippingMethod resources for an Outbound.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public shippingMethodsOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).shippingMethodsOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the current workflow state for an Outbound resource.
     * @param {string} id
     * @param {OutboundStateTransitionOutboundStateChange} [outboundStateTransitionOutboundStateChange] The updated Outbound resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public stateChangeOutboundItem(id: string, outboundStateTransitionOutboundStateChange?: OutboundStateTransitionOutboundStateChange, options?: any) {
        return OutboundApiFp(this.configuration).stateChangeOutboundItem(id, outboundStateTransitionOutboundStateChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the suggested PackingMaterial
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public suggestedPackingMaterialOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).suggestedPackingMaterialOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Unassign an Outbound resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundApi
     */
    public unassignOutboundItem(id: string, options?: any) {
        return OutboundApiFp(this.configuration).unassignOutboundItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundAddressApi - axios parameter creator
 * @export
 */
export const OutboundAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a OutboundAddress resource.
         * @param {string} outbound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundAddressItem: async (outbound: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outbound' is not null or undefined
            if (outbound === null || outbound === undefined) {
                throw new RequiredError('outbound','Required parameter outbound was null or undefined when calling getOutboundAddressItem.');
            }
            const localVarPath = `/outbound_addresses/{outbound}`
                .replace(`{${"outbound"}}`, encodeURIComponent(String(outbound)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the OutboundAddress resource.
         * @param {string} outbound
         * @param {OutboundAddressOutboundAddressEdit} [outboundAddressOutboundAddressEdit] The updated OutboundAddress resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundAddressItem: async (outbound: string, outboundAddressOutboundAddressEdit?: OutboundAddressOutboundAddressEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'outbound' is not null or undefined
            if (outbound === null || outbound === undefined) {
                throw new RequiredError('outbound','Required parameter outbound was null or undefined when calling patchOutboundAddressItem.');
            }
            const localVarPath = `/outbound_addresses/{outbound}`
                .replace(`{${"outbound"}}`, encodeURIComponent(String(outbound)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundAddressOutboundAddressEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundAddressOutboundAddressEdit !== undefined ? outboundAddressOutboundAddressEdit : {}) : (outboundAddressOutboundAddressEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundAddressApi - functional programming interface
 * @export
 */
export const OutboundAddressApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a OutboundAddress resource.
         * @param {string} outbound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundAddressItem(outbound: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundAddressJsonldOutboundAddressDetail>> {
            const localVarAxiosArgs = await OutboundAddressApiAxiosParamCreator(configuration).getOutboundAddressItem(outbound, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the OutboundAddress resource.
         * @param {string} outbound
         * @param {OutboundAddressOutboundAddressEdit} [outboundAddressOutboundAddressEdit] The updated OutboundAddress resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOutboundAddressItem(outbound: string, outboundAddressOutboundAddressEdit?: OutboundAddressOutboundAddressEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundAddressJsonldOutboundAddressDetail>> {
            const localVarAxiosArgs = await OutboundAddressApiAxiosParamCreator(configuration).patchOutboundAddressItem(outbound, outboundAddressOutboundAddressEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundAddressApi - factory interface
 * @export
 */
export const OutboundAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves a OutboundAddress resource.
         * @param {string} outbound
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundAddressItem(outbound: string, options?: any): AxiosPromise<OutboundAddressJsonldOutboundAddressDetail> {
            return OutboundAddressApiFp(configuration).getOutboundAddressItem(outbound, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the OutboundAddress resource.
         * @param {string} outbound
         * @param {OutboundAddressOutboundAddressEdit} [outboundAddressOutboundAddressEdit] The updated OutboundAddress resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundAddressItem(outbound: string, outboundAddressOutboundAddressEdit?: OutboundAddressOutboundAddressEdit, options?: any): AxiosPromise<OutboundAddressJsonldOutboundAddressDetail> {
            return OutboundAddressApiFp(configuration).patchOutboundAddressItem(outbound, outboundAddressOutboundAddressEdit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundAddressApi - object-oriented interface
 * @export
 * @class OutboundAddressApi
 * @extends {BaseAPI}
 */
export class OutboundAddressApi extends BaseAPI {
    /**
     *
     * @summary Retrieves a OutboundAddress resource.
     * @param {string} outbound
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundAddressApi
     */
    public getOutboundAddressItem(outbound: string, options?: any) {
        return OutboundAddressApiFp(this.configuration).getOutboundAddressItem(outbound, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the OutboundAddress resource.
     * @param {string} outbound
     * @param {OutboundAddressOutboundAddressEdit} [outboundAddressOutboundAddressEdit] The updated OutboundAddress resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundAddressApi
     */
    public patchOutboundAddressItem(outbound: string, outboundAddressOutboundAddressEdit?: OutboundAddressOutboundAddressEdit, options?: any) {
        return OutboundAddressApiFp(this.configuration).patchOutboundAddressItem(outbound, outboundAddressOutboundAddressEdit, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundItemApi - axios parameter creator
 * @export
 */
export const OutboundItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOutboundsItemsGetSubresource: async (id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiOutboundsItemsGetSubresource.');
            }
            const localVarPath = `/outbounds/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (productEan !== undefined) {
                localVarQueryParameter['product.ean'] = productEan;
            }

            if (productEan2) {
                localVarQueryParameter['product.ean[]'] = productEan2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemCollection: async (marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_items`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (productEan !== undefined) {
                localVarQueryParameter['product.ean'] = productEan;
            }

            if (productEan2) {
                localVarQueryParameter['product.ean[]'] = productEan2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundItemItem.');
            }
            const localVarPath = `/outbound_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemOutboundItemEdit} [outboundItemOutboundItemEdit] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundItemItem: async (id: string, outboundItemOutboundItemEdit?: OutboundItemOutboundItemEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchOutboundItemItem.');
            }
            const localVarPath = `/outbound_items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundItemOutboundItemEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundItemOutboundItemEdit !== undefined ? outboundItemOutboundItemEdit : {}) : (outboundItemOutboundItemEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemStateTransitionOutboundItemStateChange} [outboundItemStateTransitionOutboundItemStateChange] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeOutboundItemItem: async (id: string, outboundItemStateTransitionOutboundItemStateChange?: OutboundItemStateTransitionOutboundItemStateChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stateChangeOutboundItemItem.');
            }
            const localVarPath = `/outbound_items/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundItemStateTransitionOutboundItemStateChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundItemStateTransitionOutboundItemStateChange !== undefined ? outboundItemStateTransitionOutboundItemStateChange : {}) : (outboundItemStateTransitionOutboundItemStateChange || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundItemApi - functional programming interface
 * @export
 */
export const OutboundItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await OutboundItemApiAxiosParamCreator(configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemCollection(marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20016>> {
            const localVarAxiosArgs = await OutboundItemApiAxiosParamCreator(configuration).getOutboundItemCollection(marking, marking2, productEan, productEan2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemJsonldOutboundItemDetail>> {
            const localVarAxiosArgs = await OutboundItemApiAxiosParamCreator(configuration).getOutboundItemItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemOutboundItemEdit} [outboundItemOutboundItemEdit] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOutboundItemItem(id: string, outboundItemOutboundItemEdit?: OutboundItemOutboundItemEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemJsonldOutboundItemDetail>> {
            const localVarAxiosArgs = await OutboundItemApiAxiosParamCreator(configuration).patchOutboundItemItem(id, outboundItemOutboundItemEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the current workflow state for an OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemStateTransitionOutboundItemStateChange} [outboundItemStateTransitionOutboundItemStateChange] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stateChangeOutboundItemItem(id: string, outboundItemStateTransitionOutboundItemStateChange?: OutboundItemStateTransitionOutboundItemStateChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemJsonldOutboundItemDetail>> {
            const localVarAxiosArgs = await OutboundItemApiAxiosParamCreator(configuration).stateChangeOutboundItemItem(id, outboundItemStateTransitionOutboundItemStateChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundItemApi - factory interface
 * @export
 */
export const OutboundItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} id
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return OutboundItemApiFp(configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItem resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [productEan]
         * @param {Array<string>} [productEan2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemCollection(marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20016> {
            return OutboundItemApiFp(configuration).getOutboundItemCollection(marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a OutboundItem resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemItem(id: string, options?: any): AxiosPromise<OutboundItemJsonldOutboundItemDetail> {
            return OutboundItemApiFp(configuration).getOutboundItemItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemOutboundItemEdit} [outboundItemOutboundItemEdit] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundItemItem(id: string, outboundItemOutboundItemEdit?: OutboundItemOutboundItemEdit, options?: any): AxiosPromise<OutboundItemJsonldOutboundItemDetail> {
            return OutboundItemApiFp(configuration).patchOutboundItemItem(id, outboundItemOutboundItemEdit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the current workflow state for an OutboundItem resource.
         * @param {string} id
         * @param {OutboundItemStateTransitionOutboundItemStateChange} [outboundItemStateTransitionOutboundItemStateChange] The updated OutboundItem resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeOutboundItemItem(id: string, outboundItemStateTransitionOutboundItemStateChange?: OutboundItemStateTransitionOutboundItemStateChange, options?: any): AxiosPromise<OutboundItemJsonldOutboundItemDetail> {
            return OutboundItemApiFp(configuration).stateChangeOutboundItemItem(id, outboundItemStateTransitionOutboundItemStateChange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundItemApi - object-oriented interface
 * @export
 * @class OutboundItemApi
 * @extends {BaseAPI}
 */
export class OutboundItemApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of OutboundItem resources.
     * @param {string} id
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [productEan]
     * @param {Array<string>} [productEan2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemApi
     */
    public apiOutboundsItemsGetSubresource(id: string, marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundItemApiFp(this.configuration).apiOutboundsItemsGetSubresource(id, marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of OutboundItem resources.
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [productEan]
     * @param {Array<string>} [productEan2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemApi
     */
    public getOutboundItemCollection(marking?: string, marking2?: Array<string>, productEan?: string, productEan2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundItemApiFp(this.configuration).getOutboundItemCollection(marking, marking2, productEan, productEan2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a OutboundItem resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemApi
     */
    public getOutboundItemItem(id: string, options?: any) {
        return OutboundItemApiFp(this.configuration).getOutboundItemItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the OutboundItem resource.
     * @param {string} id
     * @param {OutboundItemOutboundItemEdit} [outboundItemOutboundItemEdit] The updated OutboundItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemApi
     */
    public patchOutboundItemItem(id: string, outboundItemOutboundItemEdit?: OutboundItemOutboundItemEdit, options?: any) {
        return OutboundItemApiFp(this.configuration).patchOutboundItemItem(id, outboundItemOutboundItemEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the current workflow state for an OutboundItem resource.
     * @param {string} id
     * @param {OutboundItemStateTransitionOutboundItemStateChange} [outboundItemStateTransitionOutboundItemStateChange] The updated OutboundItem resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemApi
     */
    public stateChangeOutboundItemItem(id: string, outboundItemStateTransitionOutboundItemStateChange?: OutboundItemStateTransitionOutboundItemStateChange, options?: any) {
        return OutboundItemApiFp(this.configuration).stateChangeOutboundItemItem(id, outboundItemStateTransitionOutboundItemStateChange, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundItemReturnApi - axios parameter creator
 * @export
 */
export const OutboundItemReturnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to OutboundItemReturn
         * @param {string} id
         * @param {OutboundItemReturnPhotosJsonldOutboundItemReturnCreate} [outboundItemReturnPhotosJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosOutboundItemReturnItem: async (id: string, outboundItemReturnPhotosJsonldOutboundItemReturnCreate?: OutboundItemReturnPhotosJsonldOutboundItemReturnCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addPhotosOutboundItemReturnItem.');
            }
            const localVarPath = `/outboundItemReturn/{id}/photos`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundItemReturnPhotosJsonldOutboundItemReturnCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundItemReturnPhotosJsonldOutboundItemReturnCreate !== undefined ? outboundItemReturnPhotosJsonldOutboundItemReturnCreate : {}) : (outboundItemReturnPhotosJsonldOutboundItemReturnCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturn resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnCollection: async (outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_item_returns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (outboundItemOutbound !== undefined) {
                localVarQueryParameter['outboundItem.outbound'] = outboundItemOutbound;
            }

            if (outboundItemOutbound2) {
                localVarQueryParameter['outboundItem.outbound[]'] = outboundItemOutbound2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturn resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundItemReturnItem.');
            }
            const localVarPath = `/outbound_item_returns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a OutboundItemReturn resource.
         * @param {OutboundItemReturnJsonldOutboundItemReturnCreate} [outboundItemReturnJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundItemReturnCollection: async (outboundItemReturnJsonldOutboundItemReturnCreate?: OutboundItemReturnJsonldOutboundItemReturnCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_item_returns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundItemReturnJsonldOutboundItemReturnCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundItemReturnJsonldOutboundItemReturnCreate !== undefined ? outboundItemReturnJsonldOutboundItemReturnCreate : {}) : (outboundItemReturnJsonldOutboundItemReturnCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundItemReturnApi - functional programming interface
 * @export
 */
export const OutboundItemReturnApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Adds photos to OutboundItemReturn
         * @param {string} id
         * @param {OutboundItemReturnPhotosJsonldOutboundItemReturnCreate} [outboundItemReturnPhotosJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPhotosOutboundItemReturnItem(id: string, outboundItemReturnPhotosJsonldOutboundItemReturnCreate?: OutboundItemReturnPhotosJsonldOutboundItemReturnCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail>> {
            const localVarAxiosArgs = await OutboundItemReturnApiAxiosParamCreator(configuration).addPhotosOutboundItemReturnItem(id, outboundItemReturnPhotosJsonldOutboundItemReturnCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturn resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemReturnCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20015>> {
            const localVarAxiosArgs = await OutboundItemReturnApiAxiosParamCreator(configuration).getOutboundItemReturnCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturn resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemReturnItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail>> {
            const localVarAxiosArgs = await OutboundItemReturnApiAxiosParamCreator(configuration).getOutboundItemReturnItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a OutboundItemReturn resource.
         * @param {OutboundItemReturnJsonldOutboundItemReturnCreate} [outboundItemReturnJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate?: OutboundItemReturnJsonldOutboundItemReturnCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail>> {
            const localVarAxiosArgs = await OutboundItemReturnApiAxiosParamCreator(configuration).postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundItemReturnApi - factory interface
 * @export
 */
export const OutboundItemReturnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Adds photos to OutboundItemReturn
         * @param {string} id
         * @param {OutboundItemReturnPhotosJsonldOutboundItemReturnCreate} [outboundItemReturnPhotosJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhotosOutboundItemReturnItem(id: string, outboundItemReturnPhotosJsonldOutboundItemReturnCreate?: OutboundItemReturnPhotosJsonldOutboundItemReturnCreate, options?: any): AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail> {
            return OutboundItemReturnApiFp(configuration).addPhotosOutboundItemReturnItem(id, outboundItemReturnPhotosJsonldOutboundItemReturnCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturn resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20015> {
            return OutboundItemReturnApiFp(configuration).getOutboundItemReturnCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturn resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnItem(id: string, options?: any): AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail> {
            return OutboundItemReturnApiFp(configuration).getOutboundItemReturnItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a OutboundItemReturn resource.
         * @param {OutboundItemReturnJsonldOutboundItemReturnCreate} [outboundItemReturnJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate?: OutboundItemReturnJsonldOutboundItemReturnCreate, options?: any): AxiosPromise<OutboundItemReturnJsonldOutboundItemReturnDetail> {
            return OutboundItemReturnApiFp(configuration).postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundItemReturnApi - object-oriented interface
 * @export
 * @class OutboundItemReturnApi
 * @extends {BaseAPI}
 */
export class OutboundItemReturnApi extends BaseAPI {
    /**
     *
     * @summary Adds photos to OutboundItemReturn
     * @param {string} id
     * @param {OutboundItemReturnPhotosJsonldOutboundItemReturnCreate} [outboundItemReturnPhotosJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnApi
     */
    public addPhotosOutboundItemReturnItem(id: string, outboundItemReturnPhotosJsonldOutboundItemReturnCreate?: OutboundItemReturnPhotosJsonldOutboundItemReturnCreate, options?: any) {
        return OutboundItemReturnApiFp(this.configuration).addPhotosOutboundItemReturnItem(id, outboundItemReturnPhotosJsonldOutboundItemReturnCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of OutboundItemReturn resources.
     * @param {string} [outboundItemOutbound]
     * @param {Array<string>} [outboundItemOutbound2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnApi
     */
    public getOutboundItemReturnCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundItemReturnApiFp(this.configuration).getOutboundItemReturnCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a OutboundItemReturn resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnApi
     */
    public getOutboundItemReturnItem(id: string, options?: any) {
        return OutboundItemReturnApiFp(this.configuration).getOutboundItemReturnItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a OutboundItemReturn resource.
     * @param {OutboundItemReturnJsonldOutboundItemReturnCreate} [outboundItemReturnJsonldOutboundItemReturnCreate] The new OutboundItemReturn resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnApi
     */
    public postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate?: OutboundItemReturnJsonldOutboundItemReturnCreate, options?: any) {
        return OutboundItemReturnApiFp(this.configuration).postOutboundItemReturnCollection(outboundItemReturnJsonldOutboundItemReturnCreate, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundItemReturnInstructionApi - axios parameter creator
 * @export
 */
export const OutboundItemReturnInstructionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturnInstruction resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnInstructionCollection: async (outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_item_return_instructions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (outboundItemOutbound !== undefined) {
                localVarQueryParameter['outboundItem.outbound'] = outboundItemOutbound;
            }

            if (outboundItemOutbound2) {
                localVarQueryParameter['outboundItem.outbound[]'] = outboundItemOutbound2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturnInstruction resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnInstructionItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundItemReturnInstructionItem.');
            }
            const localVarPath = `/outbound_item_return_instructions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a OutboundItemReturnInstruction resource.
         * @param {OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate} [outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate] The new OutboundItemReturnInstruction resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundItemReturnInstructionCollection: async (outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate?: OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_item_return_instructions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate !== undefined ? outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate : {}) : (outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundItemReturnInstructionApi - functional programming interface
 * @export
 */
export const OutboundItemReturnInstructionApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturnInstruction resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemReturnInstructionCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20014>> {
            const localVarAxiosArgs = await OutboundItemReturnInstructionApiAxiosParamCreator(configuration).getOutboundItemReturnInstructionCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturnInstruction resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundItemReturnInstructionItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail>> {
            const localVarAxiosArgs = await OutboundItemReturnInstructionApiAxiosParamCreator(configuration).getOutboundItemReturnInstructionItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a OutboundItemReturnInstruction resource.
         * @param {OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate} [outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate] The new OutboundItemReturnInstruction resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate?: OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail>> {
            const localVarAxiosArgs = await OutboundItemReturnInstructionApiAxiosParamCreator(configuration).postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundItemReturnInstructionApi - factory interface
 * @export
 */
export const OutboundItemReturnInstructionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of OutboundItemReturnInstruction resources.
         * @param {string} [outboundItemOutbound]
         * @param {Array<string>} [outboundItemOutbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnInstructionCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20014> {
            return OutboundItemReturnInstructionApiFp(configuration).getOutboundItemReturnInstructionCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a OutboundItemReturnInstruction resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundItemReturnInstructionItem(id: string, options?: any): AxiosPromise<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail> {
            return OutboundItemReturnInstructionApiFp(configuration).getOutboundItemReturnInstructionItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a OutboundItemReturnInstruction resource.
         * @param {OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate} [outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate] The new OutboundItemReturnInstruction resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate?: OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options?: any): AxiosPromise<OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionDetail> {
            return OutboundItemReturnInstructionApiFp(configuration).postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundItemReturnInstructionApi - object-oriented interface
 * @export
 * @class OutboundItemReturnInstructionApi
 * @extends {BaseAPI}
 */
export class OutboundItemReturnInstructionApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of OutboundItemReturnInstruction resources.
     * @param {string} [outboundItemOutbound]
     * @param {Array<string>} [outboundItemOutbound2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnInstructionApi
     */
    public getOutboundItemReturnInstructionCollection(outboundItemOutbound?: string, outboundItemOutbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundItemReturnInstructionApiFp(this.configuration).getOutboundItemReturnInstructionCollection(outboundItemOutbound, outboundItemOutbound2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a OutboundItemReturnInstruction resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnInstructionApi
     */
    public getOutboundItemReturnInstructionItem(id: string, options?: any) {
        return OutboundItemReturnInstructionApiFp(this.configuration).getOutboundItemReturnInstructionItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a OutboundItemReturnInstruction resource.
     * @param {OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate} [outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate] The new OutboundItemReturnInstruction resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundItemReturnInstructionApi
     */
    public postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate?: OutboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options?: any) {
        return OutboundItemReturnInstructionApiFp(this.configuration).postOutboundItemReturnInstructionCollection(outboundItemReturnInstructionJsonldOutboundItemReturnInstructionCreate, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundMutationApi - axios parameter creator
 * @export
 */
export const OutboundMutationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get outbound completed stats.
         * @param {string} [age]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedStatsOutboundMutationCollection: async (age?: string, date?: string, time?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound/completed-stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundMutation resources.
         * @param {string} [outbound]
         * @param {Array<string>} [outbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundMutationCollection: async (outbound?: string, outbound2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_mutations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (outbound !== undefined) {
                localVarQueryParameter['outbound'] = outbound;
            }

            if (outbound2) {
                localVarQueryParameter['outbound[]'] = outbound2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a OutboundMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundMutationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundMutationItem.');
            }
            const localVarPath = `/outbound_mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get outbound completed stats for monitor.
         * @param {string} secretKey
         * @param {string} [age]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCompletedStatsOutboundMutationCollection: async (secretKey: string, age?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretKey' is not null or undefined
            if (secretKey === null || secretKey === undefined) {
                throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling monitorCompletedStatsOutboundMutationCollection.');
            }
            const localVarPath = `/monitor/outbound/completed-stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (age !== undefined) {
                localVarQueryParameter['age'] = age;
            }

            if (secretKey !== undefined) {
                localVarQueryParameter['secret_key'] = secretKey;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundMutationApi - functional programming interface
 * @export
 */
export const OutboundMutationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get outbound completed stats.
         * @param {string} [age]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completedStatsOutboundMutationCollection(age?: string, date?: string, time?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await OutboundMutationApiAxiosParamCreator(configuration).completedStatsOutboundMutationCollection(age, date, time, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of OutboundMutation resources.
         * @param {string} [outbound]
         * @param {Array<string>} [outbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundMutationCollection(outbound?: string, outbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await OutboundMutationApiAxiosParamCreator(configuration).getOutboundMutationCollection(outbound, outbound2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a OutboundMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundMutationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundMutationJsonldOutboundMutationDetail>> {
            const localVarAxiosArgs = await OutboundMutationApiAxiosParamCreator(configuration).getOutboundMutationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get outbound completed stats for monitor.
         * @param {string} secretKey
         * @param {string} [age]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorCompletedStatsOutboundMutationCollection(secretKey: string, age?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20013>> {
            const localVarAxiosArgs = await OutboundMutationApiAxiosParamCreator(configuration).monitorCompletedStatsOutboundMutationCollection(secretKey, age, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundMutationApi - factory interface
 * @export
 */
export const OutboundMutationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Get outbound completed stats.
         * @param {string} [age]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completedStatsOutboundMutationCollection(age?: string, date?: string, time?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return OutboundMutationApiFp(configuration).completedStatsOutboundMutationCollection(age, date, time, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of OutboundMutation resources.
         * @param {string} [outbound]
         * @param {Array<string>} [outbound2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundMutationCollection(outbound?: string, outbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20013> {
            return OutboundMutationApiFp(configuration).getOutboundMutationCollection(outbound, outbound2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a OutboundMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundMutationItem(id: string, options?: any): AxiosPromise<OutboundMutationJsonldOutboundMutationDetail> {
            return OutboundMutationApiFp(configuration).getOutboundMutationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get outbound completed stats for monitor.
         * @param {string} secretKey
         * @param {string} [age]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCompletedStatsOutboundMutationCollection(secretKey: string, age?: string, options?: any): AxiosPromise<InlineResponse20013> {
            return OutboundMutationApiFp(configuration).monitorCompletedStatsOutboundMutationCollection(secretKey, age, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundMutationApi - object-oriented interface
 * @export
 * @class OutboundMutationApi
 * @extends {BaseAPI}
 */
export class OutboundMutationApi extends BaseAPI {
    /**
     *
     * @summary Get outbound completed stats.
     * @param {string} [age]
     * @param {string} [date]
     * @param {string} [time]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundMutationApi
     */
    public completedStatsOutboundMutationCollection(age?: string, date?: string, time?: string, options?: any) {
        return OutboundMutationApiFp(this.configuration).completedStatsOutboundMutationCollection(age, date, time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of OutboundMutation resources.
     * @param {string} [outbound]
     * @param {Array<string>} [outbound2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundMutationApi
     */
    public getOutboundMutationCollection(outbound?: string, outbound2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return OutboundMutationApiFp(this.configuration).getOutboundMutationCollection(outbound, outbound2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a OutboundMutation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundMutationApi
     */
    public getOutboundMutationItem(id: string, options?: any) {
        return OutboundMutationApiFp(this.configuration).getOutboundMutationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get outbound completed stats for monitor.
     * @param {string} secretKey
     * @param {string} [age]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundMutationApi
     */
    public monitorCompletedStatsOutboundMutationCollection(secretKey: string, age?: string, options?: any) {
        return OutboundMutationApiFp(this.configuration).monitorCompletedStatsOutboundMutationCollection(secretKey, age, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * OutboundPackingMaterialApi - axios parameter creator
 * @export
 */
export const OutboundPackingMaterialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOutboundPackingMaterialItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOutboundPackingMaterialItem.');
            }
            const localVarPath = `/outbound_packing_materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundPackingMaterialItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOutboundPackingMaterialItem.');
            }
            const localVarPath = `/outbound_packing_materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {OutboundPackingMaterialOutboundPackingMaterialCreate} [outboundPackingMaterialOutboundPackingMaterialCreate] The updated OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundPackingMaterialItem: async (id: string, outboundPackingMaterialOutboundPackingMaterialCreate?: OutboundPackingMaterialOutboundPackingMaterialCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchOutboundPackingMaterialItem.');
            }
            const localVarPath = `/outbound_packing_materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundPackingMaterialOutboundPackingMaterialCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundPackingMaterialOutboundPackingMaterialCreate !== undefined ? outboundPackingMaterialOutboundPackingMaterialCreate : {}) : (outboundPackingMaterialOutboundPackingMaterialCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a OutboundPackingMaterial resource.
         * @param {OutboundPackingMaterialJsonldOutboundPackingMaterialCreate} [outboundPackingMaterialJsonldOutboundPackingMaterialCreate] The new OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundPackingMaterialCollection: async (outboundPackingMaterialJsonldOutboundPackingMaterialCreate?: OutboundPackingMaterialJsonldOutboundPackingMaterialCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/outbound_packing_materials`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof outboundPackingMaterialJsonldOutboundPackingMaterialCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(outboundPackingMaterialJsonldOutboundPackingMaterialCreate !== undefined ? outboundPackingMaterialJsonldOutboundPackingMaterialCreate : {}) : (outboundPackingMaterialJsonldOutboundPackingMaterialCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OutboundPackingMaterialApi - functional programming interface
 * @export
 */
export const OutboundPackingMaterialApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOutboundPackingMaterialItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OutboundPackingMaterialApiAxiosParamCreator(configuration).deleteOutboundPackingMaterialItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutboundPackingMaterialItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail>> {
            const localVarAxiosArgs = await OutboundPackingMaterialApiAxiosParamCreator(configuration).getOutboundPackingMaterialItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {OutboundPackingMaterialOutboundPackingMaterialCreate} [outboundPackingMaterialOutboundPackingMaterialCreate] The updated OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOutboundPackingMaterialItem(id: string, outboundPackingMaterialOutboundPackingMaterialCreate?: OutboundPackingMaterialOutboundPackingMaterialCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail>> {
            const localVarAxiosArgs = await OutboundPackingMaterialApiAxiosParamCreator(configuration).patchOutboundPackingMaterialItem(id, outboundPackingMaterialOutboundPackingMaterialCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a OutboundPackingMaterial resource.
         * @param {OutboundPackingMaterialJsonldOutboundPackingMaterialCreate} [outboundPackingMaterialJsonldOutboundPackingMaterialCreate] The new OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate?: OutboundPackingMaterialJsonldOutboundPackingMaterialCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail>> {
            const localVarAxiosArgs = await OutboundPackingMaterialApiAxiosParamCreator(configuration).postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OutboundPackingMaterialApi - factory interface
 * @export
 */
export const OutboundPackingMaterialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Removes the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOutboundPackingMaterialItem(id: string, options?: any): AxiosPromise<void> {
            return OutboundPackingMaterialApiFp(configuration).deleteOutboundPackingMaterialItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a OutboundPackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutboundPackingMaterialItem(id: string, options?: any): AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail> {
            return OutboundPackingMaterialApiFp(configuration).getOutboundPackingMaterialItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the OutboundPackingMaterial resource.
         * @param {string} id
         * @param {OutboundPackingMaterialOutboundPackingMaterialCreate} [outboundPackingMaterialOutboundPackingMaterialCreate] The updated OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOutboundPackingMaterialItem(id: string, outboundPackingMaterialOutboundPackingMaterialCreate?: OutboundPackingMaterialOutboundPackingMaterialCreate, options?: any): AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail> {
            return OutboundPackingMaterialApiFp(configuration).patchOutboundPackingMaterialItem(id, outboundPackingMaterialOutboundPackingMaterialCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a OutboundPackingMaterial resource.
         * @param {OutboundPackingMaterialJsonldOutboundPackingMaterialCreate} [outboundPackingMaterialJsonldOutboundPackingMaterialCreate] The new OutboundPackingMaterial resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate?: OutboundPackingMaterialJsonldOutboundPackingMaterialCreate, options?: any): AxiosPromise<OutboundPackingMaterialJsonldOutboundPackingMaterialDetail> {
            return OutboundPackingMaterialApiFp(configuration).postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OutboundPackingMaterialApi - object-oriented interface
 * @export
 * @class OutboundPackingMaterialApi
 * @extends {BaseAPI}
 */
export class OutboundPackingMaterialApi extends BaseAPI {
    /**
     *
     * @summary Removes the OutboundPackingMaterial resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundPackingMaterialApi
     */
    public deleteOutboundPackingMaterialItem(id: string, options?: any) {
        return OutboundPackingMaterialApiFp(this.configuration).deleteOutboundPackingMaterialItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a OutboundPackingMaterial resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundPackingMaterialApi
     */
    public getOutboundPackingMaterialItem(id: string, options?: any) {
        return OutboundPackingMaterialApiFp(this.configuration).getOutboundPackingMaterialItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the OutboundPackingMaterial resource.
     * @param {string} id
     * @param {OutboundPackingMaterialOutboundPackingMaterialCreate} [outboundPackingMaterialOutboundPackingMaterialCreate] The updated OutboundPackingMaterial resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundPackingMaterialApi
     */
    public patchOutboundPackingMaterialItem(id: string, outboundPackingMaterialOutboundPackingMaterialCreate?: OutboundPackingMaterialOutboundPackingMaterialCreate, options?: any) {
        return OutboundPackingMaterialApiFp(this.configuration).patchOutboundPackingMaterialItem(id, outboundPackingMaterialOutboundPackingMaterialCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a OutboundPackingMaterial resource.
     * @param {OutboundPackingMaterialJsonldOutboundPackingMaterialCreate} [outboundPackingMaterialJsonldOutboundPackingMaterialCreate] The new OutboundPackingMaterial resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OutboundPackingMaterialApi
     */
    public postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate?: OutboundPackingMaterialJsonldOutboundPackingMaterialCreate, options?: any) {
        return OutboundPackingMaterialApiFp(this.configuration).postOutboundPackingMaterialCollection(outboundPackingMaterialJsonldOutboundPackingMaterialCreate, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PackingMaterialApi - axios parameter creator
 * @export
 */
export const PackingMaterialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of PackingMaterial resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackingMaterialCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/packing_materials`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a PackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackingMaterialItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPackingMaterialItem.');
            }
            const localVarPath = `/packing_materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query a PackingMaterial resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPackingMaterialItem: async (barcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling queryPackingMaterialItem.');
            }
            const localVarPath = `/packing_material`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackingMaterialApi - functional programming interface
 * @export
 */
export const PackingMaterialApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of PackingMaterial resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackingMaterialCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20020>> {
            const localVarAxiosArgs = await PackingMaterialApiAxiosParamCreator(configuration).getPackingMaterialCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a PackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPackingMaterialItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackingMaterialJsonldPackingMaterialDetail>> {
            const localVarAxiosArgs = await PackingMaterialApiAxiosParamCreator(configuration).getPackingMaterialItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query a PackingMaterial resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryPackingMaterialItem(barcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackingMaterialJsonldPackingMaterialDetail>> {
            const localVarAxiosArgs = await PackingMaterialApiAxiosParamCreator(configuration).queryPackingMaterialItem(barcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PackingMaterialApi - factory interface
 * @export
 */
export const PackingMaterialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of PackingMaterial resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackingMaterialCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20020> {
            return PackingMaterialApiFp(configuration).getPackingMaterialCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a PackingMaterial resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPackingMaterialItem(id: string, options?: any): AxiosPromise<PackingMaterialJsonldPackingMaterialDetail> {
            return PackingMaterialApiFp(configuration).getPackingMaterialItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query a PackingMaterial resource.
         * @param {string} barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryPackingMaterialItem(barcode: string, options?: any): AxiosPromise<PackingMaterialJsonldPackingMaterialDetail> {
            return PackingMaterialApiFp(configuration).queryPackingMaterialItem(barcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackingMaterialApi - object-oriented interface
 * @export
 * @class PackingMaterialApi
 * @extends {BaseAPI}
 */
export class PackingMaterialApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of PackingMaterial resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackingMaterialApi
     */
    public getPackingMaterialCollection(page?: number, itemsPerPage?: number, options?: any) {
        return PackingMaterialApiFp(this.configuration).getPackingMaterialCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a PackingMaterial resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackingMaterialApi
     */
    public getPackingMaterialItem(id: string, options?: any) {
        return PackingMaterialApiFp(this.configuration).getPackingMaterialItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query a PackingMaterial resource.
     * @param {string} barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackingMaterialApi
     */
    public queryPackingMaterialItem(barcode: string, options?: any) {
        return PackingMaterialApiFp(this.configuration).queryPackingMaterialItem(barcode, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductItem.');
            }
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        getProductCollection: async (ean?: string, name?: string, fragile?: boolean, machineExcluded?: boolean, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }
            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }
            if (fragile !== undefined) {
                localVarQueryParameter['fragile'] = fragile;
            }
            if (machineExcluded !== undefined) {
                localVarQueryParameter['machineExcluded'] = machineExcluded;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductItem.');
            }
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Products for a package ean.
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packageProductsProductCollection: async (ean: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ean' is not null or undefined
            if (ean === null || ean === undefined) {
                throw new RequiredError('ean','Required parameter ean was null or undefined when calling packageProductsProductCollection.');
            }
            const localVarPath = `/package/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Product resource.
         * @param {string} id
         * @param {ProductProductCreate} [productProductCreate] The updated Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductItem: async (id: string, productProductCreate?: ProductProductCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchProductItem.');
            }
            const localVarPath = `/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productProductCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productProductCreate !== undefined ? productProductCreate : {}) : (productProductCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a Product resource.
         * @param {ProductJsonldProductCreate} [productJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductCollection: async (productJsonldProductCreate?: ProductJsonldProductCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productJsonldProductCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productJsonldProductCreate !== undefined ? productJsonldProductCreate : {}) : (productJsonldProductCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Prints barcode for a Product.
         * @param {string} id
         * @param {ProductPrintProductJsonldProductCreate} [productPrintProductJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printProductItem: async (id: string, productPrintProductJsonldProductCreate?: ProductPrintProductJsonldProductCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling printProductItem.');
            }
            const localVarPath = `/product/{id}/print`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productPrintProductJsonldProductCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productPrintProductJsonldProductCreate !== undefined ? productPrintProductJsonldProductCreate : {}) : (productPrintProductJsonldProductCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query a Product resource.
         * @param {string} ean
         * @param {boolean} [ignoreLeadingZero]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryProductItem: async (ean: string, ignoreLeadingZero?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ean' is not null or undefined
            if (ean === null || ean === undefined) {
                throw new RequiredError('ean','Required parameter ean was null or undefined when calling queryProductItem.');
            }
            const localVarPath = `/product`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (ignoreLeadingZero !== undefined) {
                localVarQueryParameter['ignoreLeadingZero'] = ignoreLeadingZero;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).deleteProductItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },

        async getProductCollection(ean?: string, name?: string, fragile?: boolean, machineExcluded?: boolean, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20024>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).getProductCollection(ean, name, fragile, machineExcluded, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductJsonldProductDetail>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).getProductItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Products for a package ean.
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async packageProductsProductCollection(ean: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20019>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).packageProductsProductCollection(ean, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Product resource.
         * @param {string} id
         * @param {ProductProductCreate} [productProductCreate] The updated Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProductItem(id: string, productProductCreate?: ProductProductCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductJsonldProductDetail>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).patchProductItem(id, productProductCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a Product resource.
         * @param {ProductJsonldProductCreate} [productJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductCollection(productJsonldProductCreate?: ProductJsonldProductCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductJsonldProductDetail>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).postProductCollection(productJsonldProductCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Prints barcode for a Product.
         * @param {string} id
         * @param {ProductPrintProductJsonldProductCreate} [productPrintProductJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printProductItem(id: string, productPrintProductJsonldProductCreate?: ProductPrintProductJsonldProductCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductJsonldProductDetail>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).printProductItem(id, productPrintProductJsonldProductCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query a Product resource.
         * @param {string} ean
         * @param {boolean} [ignoreLeadingZero]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryProductItem(ean: string, ignoreLeadingZero?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductJsonldProductDetail>> {
            const localVarAxiosArgs = await ProductApiAxiosParamCreator(configuration).queryProductItem(ean, ignoreLeadingZero, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Removes the Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductItem(id: string, options?: any): AxiosPromise<void> {
            return ProductApiFp(configuration).deleteProductItem(id, options).then((request) => request(axios, basePath));
        },

        getProductCollection(ean?: string, name?: string, fragile?: boolean, machineExcluded?: boolean, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20024> {
            return ProductApiFp(configuration).getProductCollection(ean, name, fragile, machineExcluded, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Product resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductItem(id: string, options?: any): AxiosPromise<ProductJsonldProductDetail> {
            return ProductApiFp(configuration).getProductItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Products for a package ean.
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        packageProductsProductCollection(ean: string, options?: any): AxiosPromise<InlineResponse20019> {
            return ProductApiFp(configuration).packageProductsProductCollection(ean, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Product resource.
         * @param {string} id
         * @param {ProductProductCreate} [productProductCreate] The updated Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductItem(id: string, productProductCreate?: ProductProductCreate, options?: any): AxiosPromise<ProductJsonldProductDetail> {
            return ProductApiFp(configuration).patchProductItem(id, productProductCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a Product resource.
         * @param {ProductJsonldProductCreate} [productJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductCollection(productJsonldProductCreate?: ProductJsonldProductCreate, options?: any): AxiosPromise<ProductJsonldProductDetail> {
            return ProductApiFp(configuration).postProductCollection(productJsonldProductCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Prints barcode for a Product.
         * @param {string} id
         * @param {ProductPrintProductJsonldProductCreate} [productPrintProductJsonldProductCreate] The new Product resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printProductItem(id: string, productPrintProductJsonldProductCreate?: ProductPrintProductJsonldProductCreate, options?: any): AxiosPromise<ProductJsonldProductDetail> {
            return ProductApiFp(configuration).printProductItem(id, productPrintProductJsonldProductCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query a Product resource.
         * @param {string} ean
         * @param {boolean} [ignoreLeadingZero]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryProductItem(ean: string, ignoreLeadingZero?: boolean, options?: any): AxiosPromise<ProductJsonldProductDetail> {
            return ProductApiFp(configuration).queryProductItem(ean, ignoreLeadingZero, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     *
     * @summary Removes the Product resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProductItem(id: string, options?: any) {
        return ProductApiFp(this.configuration).deleteProductItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    public getProductCollection(ean?: string, name?: string, fragile?: boolean, machineExcluded?: boolean, page?: number, itemsPerPage?: number, options?: any) {
        return ProductApiFp(this.configuration).getProductCollection(ean, name, fragile, machineExcluded, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Product resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductItem(id: string, options?: any) {
        return ProductApiFp(this.configuration).getProductItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Products for a package ean.
     * @param {string} ean
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public packageProductsProductCollection(ean: string, options?: any) {
        return ProductApiFp(this.configuration).packageProductsProductCollection(ean, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Product resource.
     * @param {string} id
     * @param {ProductProductCreate} [productProductCreate] The updated Product resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public patchProductItem(id: string, productProductCreate?: ProductProductCreate, options?: any) {
        return ProductApiFp(this.configuration).patchProductItem(id, productProductCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a Product resource.
     * @param {ProductJsonldProductCreate} [productJsonldProductCreate] The new Product resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public postProductCollection(productJsonldProductCreate?: ProductJsonldProductCreate, options?: any) {
        return ProductApiFp(this.configuration).postProductCollection(productJsonldProductCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Prints barcode for a Product.
     * @param {string} id
     * @param {ProductPrintProductJsonldProductCreate} [productPrintProductJsonldProductCreate] The new Product resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public printProductItem(id: string, productPrintProductJsonldProductCreate?: ProductPrintProductJsonldProductCreate, options?: any) {
        return ProductApiFp(this.configuration).printProductItem(id, productPrintProductJsonldProductCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query a Product resource.
     * @param {string} ean
     * @param {boolean} [ignoreLeadingZero]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public queryProductItem(ean: string, ignoreLeadingZero?: boolean, options?: any) {
        return ProductApiFp(this.configuration).queryProductItem(ean, ignoreLeadingZero, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductLocationZoneApi - axios parameter creator
 * @export
 */
export const ProductLocationZoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the suggested ProductLocationZone resource for an Inbound product.
         * @param {string} ean
         * @param {string} [quantity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundProductLocationZoneItem: async (ean: string, quantity?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ean' is not null or undefined
            if (ean === null || ean === undefined) {
                throw new RequiredError('ean','Required parameter ean was null or undefined when calling inboundProductLocationZoneItem.');
            }
            const localVarPath = `/inbound/product_location_zone`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductLocationZoneApi - functional programming interface
 * @export
 */
export const ProductLocationZoneApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the suggested ProductLocationZone resource for an Inbound product.
         * @param {string} ean
         * @param {string} [quantity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inboundProductLocationZoneItem(ean: string, quantity?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationZoneJsonldProductLocationZoneDetail>> {
            const localVarAxiosArgs = await ProductLocationZoneApiAxiosParamCreator(configuration).inboundProductLocationZoneItem(ean, quantity, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductLocationZoneApi - factory interface
 * @export
 */
export const ProductLocationZoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the suggested ProductLocationZone resource for an Inbound product.
         * @param {string} ean
         * @param {string} [quantity]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inboundProductLocationZoneItem(ean: string, quantity?: string, options?: any): AxiosPromise<ProductLocationZoneJsonldProductLocationZoneDetail> {
            return ProductLocationZoneApiFp(configuration).inboundProductLocationZoneItem(ean, quantity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductLocationZoneApi - object-oriented interface
 * @export
 * @class ProductLocationZoneApi
 * @extends {BaseAPI}
 */
export class ProductLocationZoneApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the suggested ProductLocationZone resource for an Inbound product.
     * @param {string} ean
     * @param {string} [quantity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLocationZoneApi
     */
    public inboundProductLocationZoneItem(ean: string, quantity?: string, options?: any) {
        return ProductLocationZoneApiFp(this.configuration).inboundProductLocationZoneItem(ean, quantity, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductMutationApi - axios parameter creator
 * @export
 */
export const ProductMutationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of ProductMutation resources.
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMutationCollection: async (product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_mutations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (product2) {
                localVarQueryParameter['product[]'] = product2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a ProductMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMutationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductMutationItem.');
            }
            const localVarPath = `/product_mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductMutationApi - functional programming interface
 * @export
 */
export const ProductMutationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of ProductMutation resources.
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductMutationCollection(product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20022>> {
            const localVarAxiosArgs = await ProductMutationApiAxiosParamCreator(configuration).getProductMutationCollection(product, product2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a ProductMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductMutationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductMutationJsonldProductMutationDetail>> {
            const localVarAxiosArgs = await ProductMutationApiAxiosParamCreator(configuration).getProductMutationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductMutationApi - factory interface
 * @export
 */
export const ProductMutationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of ProductMutation resources.
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMutationCollection(product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20022> {
            return ProductMutationApiFp(configuration).getProductMutationCollection(product, product2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a ProductMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMutationItem(id: string, options?: any): AxiosPromise<ProductMutationJsonldProductMutationDetail> {
            return ProductMutationApiFp(configuration).getProductMutationItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductMutationApi - object-oriented interface
 * @export
 * @class ProductMutationApi
 * @extends {BaseAPI}
 */
export class ProductMutationApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of ProductMutation resources.
     * @param {string} [product]
     * @param {Array<string>} [product2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMutationApi
     */
    public getProductMutationCollection(product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return ProductMutationApiFp(this.configuration).getProductMutationCollection(product, product2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a ProductMutation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMutationApi
     */
    public getProductMutationItem(id: string, options?: any) {
        return ProductMutationApiFp(this.configuration).getProductMutationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductPickApi - axios parameter creator
 * @export
 */
export const ProductPickApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a ProductPick resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductPickItem.');
            }
            const localVarPath = `/product_picks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the ProductPick resource.
         * @param {string} id
         * @param {ProductPickProductPickEdit} [productPickProductPickEdit] The updated ProductPick resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductPickItem: async (id: string, productPickProductPickEdit?: ProductPickProductPickEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchProductPickItem.');
            }
            const localVarPath = `/product_picks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productPickProductPickEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productPickProductPickEdit !== undefined ? productPickProductPickEdit : {}) : (productPickProductPickEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPickApi - functional programming interface
 * @export
 */
export const ProductPickApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a ProductPick resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPickItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickJsonldProductPickDetail>> {
            const localVarAxiosArgs = await ProductPickApiAxiosParamCreator(configuration).getProductPickItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the ProductPick resource.
         * @param {string} id
         * @param {ProductPickProductPickEdit} [productPickProductPickEdit] The updated ProductPick resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProductPickItem(id: string, productPickProductPickEdit?: ProductPickProductPickEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickJsonldProductPickDetail>> {
            const localVarAxiosArgs = await ProductPickApiAxiosParamCreator(configuration).patchProductPickItem(id, productPickProductPickEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductPickApi - factory interface
 * @export
 */
export const ProductPickApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves a ProductPick resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickItem(id: string, options?: any): AxiosPromise<ProductPickJsonldProductPickDetail> {
            return ProductPickApiFp(configuration).getProductPickItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the ProductPick resource.
         * @param {string} id
         * @param {ProductPickProductPickEdit} [productPickProductPickEdit] The updated ProductPick resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductPickItem(id: string, productPickProductPickEdit?: ProductPickProductPickEdit, options?: any): AxiosPromise<ProductPickJsonldProductPickDetail> {
            return ProductPickApiFp(configuration).patchProductPickItem(id, productPickProductPickEdit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPickApi - object-oriented interface
 * @export
 * @class ProductPickApi
 * @extends {BaseAPI}
 */
export class ProductPickApi extends BaseAPI {
    /**
     *
     * @summary Retrieves a ProductPick resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickApi
     */
    public getProductPickItem(id: string, options?: any) {
        return ProductPickApiFp(this.configuration).getProductPickItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the ProductPick resource.
     * @param {string} id
     * @param {ProductPickProductPickEdit} [productPickProductPickEdit] The updated ProductPick resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickApi
     */
    public patchProductPickItem(id: string, productPickProductPickEdit?: ProductPickProductPickEdit, options?: any) {
        return ProductPickApiFp(this.configuration).patchProductPickItem(id, productPickProductPickEdit, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductPickListApi - axios parameter creator
 * @export
 */
export const ProductPickListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductPickListItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of ProductPickList resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {Array<string>} [itemsProductEan2]
         * @param {string} [originZone]
         * @param {Array<string>} [originZone2]
         * @param {string} [destinationZone]
         * @param {Array<string>} [destinationZone2]
         * @param {'asc' | 'desc'} [orderOutboundEarliestCutoff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickListCollection: async (marking?: string, marking2?: Array<string>, itemsProductEan?: string, itemsProductEan2?: Array<string>, originZone?: string, originZone2?: Array<string>, destinationZone?: string, destinationZone2?: Array<string>, orderOutboundEarliestCutoff?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_pick_lists`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (marking !== undefined) {
                localVarQueryParameter['marking'] = marking;
            }

            if (marking2) {
                localVarQueryParameter['marking[]'] = marking2;
            }

            if (itemsProductEan !== undefined) {
                localVarQueryParameter['items.product.ean'] = itemsProductEan;
            }

            if (itemsProductEan2) {
                localVarQueryParameter['items.product.ean[]'] = itemsProductEan2;
            }

            if (originZone !== undefined) {
                localVarQueryParameter['originZone'] = originZone;
            }

            if (originZone2) {
                localVarQueryParameter['originZone[]'] = originZone2;
            }

            if (destinationZone !== undefined) {
                localVarQueryParameter['destinationZone'] = destinationZone;
            }

            if (destinationZone2) {
                localVarQueryParameter['destinationZone[]'] = destinationZone2;
            }

            if (orderOutboundEarliestCutoff !== undefined) {
                localVarQueryParameter['order[outboundEarliestCutoff]'] = orderOutboundEarliestCutoff;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickListItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the outbound for a ProductPickList product.
         * @param {string} id
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPickOutboundProductPickListItem: async (id: string, ean: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling orderPickOutboundProductPickListItem.');
            }
            // verify required parameter 'ean' is not null or undefined
            if (ean === null || ean === undefined) {
                throw new RequiredError('ean','Required parameter ean was null or undefined when calling orderPickOutboundProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}/order_pick_outbound`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListProductPickListEdit} [productPickListProductPickListEdit] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductPickListItem: async (id: string, productPickListProductPickListEdit?: ProductPickListProductPickListEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productPickListProductPickListEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productPickListProductPickListEdit !== undefined ? productPickListProductPickListEdit : {}) : (productPickListProductPickListEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get pick list distribute stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickListDistributeStatsProductPickListCollection: async (period?: string, date?: string, time?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pick-list/distribute/stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get pick list pick stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickListPickStatsProductPickListCollection: async (period?: string, date?: string, time?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pick-list/pick/stats`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (time !== undefined) {
                localVarQueryParameter['time'] = time;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the target Location resource for a not orderPick picklist product.
         * @param {string} product
         * @param {string} destinationZone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTargetLocationProductPickListItem: async (product: string, destinationZone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling productTargetLocationProductPickListItem.');
            }
            // verify required parameter 'destinationZone' is not null or undefined
            if (destinationZone === null || destinationZone === undefined) {
                throw new RequiredError('destinationZone','Required parameter destinationZone was null or undefined when calling productTargetLocationProductPickListItem.');
            }
            const localVarPath = `/product_pick_list/product_target_location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (destinationZone !== undefined) {
                localVarQueryParameter['destinationZone'] = destinationZone;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Query a ProductPickList resource.
         * @param {string} locationBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryProductPickListItem: async (locationBarcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationBarcode' is not null or undefined
            if (locationBarcode === null || locationBarcode === undefined) {
                throw new RequiredError('locationBarcode','Required parameter locationBarcode was null or undefined when calling queryProductPickListItem.');
            }
            const localVarPath = `/product_pick_list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (locationBarcode !== undefined) {
                localVarQueryParameter['locationBarcode'] = locationBarcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the current workflow state for a ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListStateTransitionProductPickListStateChange} [productPickListStateTransitionProductPickListStateChange] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeProductPickListItem: async (id: string, productPickListStateTransitionProductPickListStateChange?: ProductPickListStateTransitionProductPickListStateChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stateChangeProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof productPickListStateTransitionProductPickListStateChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(productPickListStateTransitionProductPickListStateChange !== undefined ? productPickListStateTransitionProductPickListStateChange : {}) : (productPickListStateTransitionProductPickListStateChange || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources for a ProductPick product.
         * @param {string} id
         * @param {string} ean
         * @param {string} [currentLocationBarcode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksProductPickListItem: async (id: string, ean: string, currentLocationBarcode?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling stocksProductPickListItem.');
            }
            // verify required parameter 'ean' is not null or undefined
            if (ean === null || ean === undefined) {
                throw new RequiredError('ean','Required parameter ean was null or undefined when calling stocksProductPickListItem.');
            }
            const localVarPath = `/product_pick_lists/{id}/stocks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (currentLocationBarcode !== undefined) {
                localVarQueryParameter['currentLocationBarcode'] = currentLocationBarcode;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPickListApi - functional programming interface
 * @export
 */
export const ProductPickListApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductPickListItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).deleteProductPickListItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of ProductPickList resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {Array<string>} [itemsProductEan2]
         * @param {string} [originZone]
         * @param {Array<string>} [originZone2]
         * @param {string} [destinationZone]
         * @param {Array<string>} [destinationZone2]
         * @param {'asc' | 'desc'} [orderOutboundEarliestCutoff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPickListCollection(marking?: string, marking2?: Array<string>, itemsProductEan?: string, itemsProductEan2?: Array<string>, originZone?: string, originZone2?: Array<string>, destinationZone?: string, destinationZone2?: Array<string>, orderOutboundEarliestCutoff?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20023>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).getProductPickListCollection(marking, marking2, itemsProductEan, itemsProductEan2, originZone, originZone2, destinationZone, destinationZone2, orderOutboundEarliestCutoff, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductPickListItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListDetail>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).getProductPickListItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the outbound for a ProductPickList product.
         * @param {string} id
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPickOutboundProductPickListItem(id: string, ean: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldOrderPickOutbound>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).orderPickOutboundProductPickListItem(id, ean, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListProductPickListEdit} [productPickListProductPickListEdit] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProductPickListItem(id: string, productPickListProductPickListEdit?: ProductPickListProductPickListEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListDetail>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).patchProductPickListItem(id, productPickListProductPickListEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get pick list distribute stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickListDistributeStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).pickListDistributeStatsProductPickListCollection(period, date, time, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get pick list pick stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pickListPickStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20021>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).pickListPickStatsProductPickListCollection(period, date, time, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the target Location resource for a not orderPick picklist product.
         * @param {string} product
         * @param {string} destinationZone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productTargetLocationProductPickListItem(product: string, destinationZone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListDetail>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).productTargetLocationProductPickListItem(product, destinationZone, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Query a ProductPickList resource.
         * @param {string} locationBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryProductPickListItem(locationBarcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListDetail>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).queryProductPickListItem(locationBarcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the current workflow state for a ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListStateTransitionProductPickListStateChange} [productPickListStateTransitionProductPickListStateChange] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stateChangeProductPickListItem(id: string, productPickListStateTransitionProductPickListStateChange?: ProductPickListStateTransitionProductPickListStateChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListDetail>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).stateChangeProductPickListItem(id, productPickListStateTransitionProductPickListStateChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources for a ProductPick product.
         * @param {string} id
         * @param {string} ean
         * @param {string} [currentLocationBarcode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stocksProductPickListItem(id: string, ean: string, currentLocationBarcode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPickListJsonldProductPickListStocks>> {
            const localVarAxiosArgs = await ProductPickListApiAxiosParamCreator(configuration).stocksProductPickListItem(id, ean, currentLocationBarcode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductPickListApi - factory interface
 * @export
 */
export const ProductPickListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Removes the ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductPickListItem(id: string, options?: any): AxiosPromise<void> {
            return ProductPickListApiFp(configuration).deleteProductPickListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of ProductPickList resources.
         * @param {string} [marking]
         * @param {Array<string>} [marking2]
         * @param {string} [itemsProductEan]
         * @param {Array<string>} [itemsProductEan2]
         * @param {string} [originZone]
         * @param {Array<string>} [originZone2]
         * @param {string} [destinationZone]
         * @param {Array<string>} [destinationZone2]
         * @param {'asc' | 'desc'} [orderOutboundEarliestCutoff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickListCollection(marking?: string, marking2?: Array<string>, itemsProductEan?: string, itemsProductEan2?: Array<string>, originZone?: string, originZone2?: Array<string>, destinationZone?: string, destinationZone2?: Array<string>, orderOutboundEarliestCutoff?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20023> {
            return ProductPickListApiFp(configuration).getProductPickListCollection(marking, marking2, itemsProductEan, itemsProductEan2, originZone, originZone2, destinationZone, destinationZone2, orderOutboundEarliestCutoff, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a ProductPickList resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductPickListItem(id: string, options?: any): AxiosPromise<ProductPickListJsonldProductPickListDetail> {
            return ProductPickListApiFp(configuration).getProductPickListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the outbound for a ProductPickList product.
         * @param {string} id
         * @param {string} ean
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPickOutboundProductPickListItem(id: string, ean: string, options?: any): AxiosPromise<ProductPickListJsonldOrderPickOutbound> {
            return ProductPickListApiFp(configuration).orderPickOutboundProductPickListItem(id, ean, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListProductPickListEdit} [productPickListProductPickListEdit] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProductPickListItem(id: string, productPickListProductPickListEdit?: ProductPickListProductPickListEdit, options?: any): AxiosPromise<ProductPickListJsonldProductPickListDetail> {
            return ProductPickListApiFp(configuration).patchProductPickListItem(id, productPickListProductPickListEdit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get pick list distribute stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickListDistributeStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any): AxiosPromise<InlineResponse20021> {
            return ProductPickListApiFp(configuration).pickListDistributeStatsProductPickListCollection(period, date, time, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get pick list pick stats.
         * @param {string} [period]
         * @param {string} [date]
         * @param {string} [time]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pickListPickStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any): AxiosPromise<InlineResponse20021> {
            return ProductPickListApiFp(configuration).pickListPickStatsProductPickListCollection(period, date, time, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the target Location resource for a not orderPick picklist product.
         * @param {string} product
         * @param {string} destinationZone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productTargetLocationProductPickListItem(product: string, destinationZone: string, options?: any): AxiosPromise<ProductPickListJsonldProductPickListDetail> {
            return ProductPickListApiFp(configuration).productTargetLocationProductPickListItem(product, destinationZone, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Query a ProductPickList resource.
         * @param {string} locationBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryProductPickListItem(locationBarcode: string, options?: any): AxiosPromise<ProductPickListJsonldProductPickListDetail> {
            return ProductPickListApiFp(configuration).queryProductPickListItem(locationBarcode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the current workflow state for a ProductPickList resource.
         * @param {string} id
         * @param {ProductPickListStateTransitionProductPickListStateChange} [productPickListStateTransitionProductPickListStateChange] The updated ProductPickList resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stateChangeProductPickListItem(id: string, productPickListStateTransitionProductPickListStateChange?: ProductPickListStateTransitionProductPickListStateChange, options?: any): AxiosPromise<ProductPickListJsonldProductPickListDetail> {
            return ProductPickListApiFp(configuration).stateChangeProductPickListItem(id, productPickListStateTransitionProductPickListStateChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources for a ProductPick product.
         * @param {string} id
         * @param {string} ean
         * @param {string} [currentLocationBarcode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stocksProductPickListItem(id: string, ean: string, currentLocationBarcode?: string, options?: any): AxiosPromise<ProductPickListJsonldProductPickListStocks> {
            return ProductPickListApiFp(configuration).stocksProductPickListItem(id, ean, currentLocationBarcode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPickListApi - object-oriented interface
 * @export
 * @class ProductPickListApi
 * @extends {BaseAPI}
 */
export class ProductPickListApi extends BaseAPI {
    /**
     *
     * @summary Removes the ProductPickList resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public deleteProductPickListItem(id: string, options?: any) {
        return ProductPickListApiFp(this.configuration).deleteProductPickListItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of ProductPickList resources.
     * @param {string} [marking]
     * @param {Array<string>} [marking2]
     * @param {string} [itemsProductEan]
     * @param {Array<string>} [itemsProductEan2]
     * @param {string} [originZone]
     * @param {Array<string>} [originZone2]
     * @param {string} [destinationZone]
     * @param {Array<string>} [destinationZone2]
     * @param {'asc' | 'desc'} [orderOutboundEarliestCutoff]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public getProductPickListCollection(marking?: string, marking2?: Array<string>, itemsProductEan?: string, itemsProductEan2?: Array<string>, originZone?: string, originZone2?: Array<string>, destinationZone?: string, destinationZone2?: Array<string>, orderOutboundEarliestCutoff?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any) {
        return ProductPickListApiFp(this.configuration).getProductPickListCollection(marking, marking2, itemsProductEan, itemsProductEan2, originZone, originZone2, destinationZone, destinationZone2, orderOutboundEarliestCutoff, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a ProductPickList resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public getProductPickListItem(id: string, options?: any) {
        return ProductPickListApiFp(this.configuration).getProductPickListItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the outbound for a ProductPickList product.
     * @param {string} id
     * @param {string} ean
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public orderPickOutboundProductPickListItem(id: string, ean: string, options?: any) {
        return ProductPickListApiFp(this.configuration).orderPickOutboundProductPickListItem(id, ean, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the ProductPickList resource.
     * @param {string} id
     * @param {ProductPickListProductPickListEdit} [productPickListProductPickListEdit] The updated ProductPickList resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public patchProductPickListItem(id: string, productPickListProductPickListEdit?: ProductPickListProductPickListEdit, options?: any) {
        return ProductPickListApiFp(this.configuration).patchProductPickListItem(id, productPickListProductPickListEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get pick list distribute stats.
     * @param {string} [period]
     * @param {string} [date]
     * @param {string} [time]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public pickListDistributeStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any) {
        return ProductPickListApiFp(this.configuration).pickListDistributeStatsProductPickListCollection(period, date, time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get pick list pick stats.
     * @param {string} [period]
     * @param {string} [date]
     * @param {string} [time]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public pickListPickStatsProductPickListCollection(period?: string, date?: string, time?: string, options?: any) {
        return ProductPickListApiFp(this.configuration).pickListPickStatsProductPickListCollection(period, date, time, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the target Location resource for a not orderPick picklist product.
     * @param {string} product
     * @param {string} destinationZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public productTargetLocationProductPickListItem(product: string, destinationZone: string, options?: any) {
        return ProductPickListApiFp(this.configuration).productTargetLocationProductPickListItem(product, destinationZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Query a ProductPickList resource.
     * @param {string} locationBarcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public queryProductPickListItem(locationBarcode: string, options?: any) {
        return ProductPickListApiFp(this.configuration).queryProductPickListItem(locationBarcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the current workflow state for a ProductPickList resource.
     * @param {string} id
     * @param {ProductPickListStateTransitionProductPickListStateChange} [productPickListStateTransitionProductPickListStateChange] The updated ProductPickList resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public stateChangeProductPickListItem(id: string, productPickListStateTransitionProductPickListStateChange?: ProductPickListStateTransitionProductPickListStateChange, options?: any) {
        return ProductPickListApiFp(this.configuration).stateChangeProductPickListItem(id, productPickListStateTransitionProductPickListStateChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Stock resources for a ProductPick product.
     * @param {string} id
     * @param {string} ean
     * @param {string} [currentLocationBarcode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPickListApi
     */
    public stocksProductPickListItem(id: string, ean: string, currentLocationBarcode?: string, options?: any) {
        return ProductPickListApiFp(this.configuration).stocksProductPickListItem(id, ean, currentLocationBarcode, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ShippingMethodApi - axios parameter creator
 * @export
 */
export const ShippingMethodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a ShippingMethod resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingMethodItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getShippingMethodItem.');
            }
            const localVarPath = `/shipping_methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingMethodApi - functional programming interface
 * @export
 */
export const ShippingMethodApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves a ShippingMethod resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingMethodItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ShippingMethodApiAxiosParamCreator(configuration).getShippingMethodItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ShippingMethodApi - factory interface
 * @export
 */
export const ShippingMethodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves a ShippingMethod resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingMethodItem(id: string, options?: any): AxiosPromise<void> {
            return ShippingMethodApiFp(configuration).getShippingMethodItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShippingMethodApi - object-oriented interface
 * @export
 * @class ShippingMethodApi
 * @extends {BaseAPI}
 */
export class ShippingMethodApi extends BaseAPI {
    /**
     *
     * @summary Retrieves a ShippingMethod resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingMethodApi
     */
    public getShippingMethodItem(id: string, options?: any) {
        return ShippingMethodApiFp(this.configuration).getShippingMethodItem(id, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * StockApi - axios parameter creator
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Balance stock.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceStockItem: async (id: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling balanceStockItem.');
            }
            const localVarPath = `/stock/{id}/balance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} [ean]
         * @param {string} [name]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleStockLocationsStockCollection: async (fromZone: string, toZone: string, ean?: string, name?: string, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromZone' is not null or undefined
            if (fromZone === null || fromZone === undefined) {
                throw new RequiredError('fromZone','Required parameter fromZone was null or undefined when calling doubleStockLocationsStockCollection.');
            }
            // verify required parameter 'toZone' is not null or undefined
            if (toZone === null || toZone === undefined) {
                throw new RequiredError('toZone','Required parameter toZone was null or undefined when calling doubleStockLocationsStockCollection.');
            }
            const localVarPath = `/double-stock-locations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromZone !== undefined) {
                localVarQueryParameter['fromZone'] = fromZone;
            }

            if (toZone !== undefined) {
                localVarQueryParameter['toZone'] = toZone;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources.
         * @param {string} [productName]
         * @param {string} [productEan]
         * @param {string} [productSupplierName]
         * @param {Array<string>} [productSupplierName2]
         * @param {string} [locationBarcode]
         * @param {boolean} [locationManco]
         * @param {boolean} [locationFull]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationZone]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [multiple]
         * @param {'asc' | 'desc'} [orderLocationCount]
         * @param {'asc' | 'desc'} [orderProductId]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockCollection: async (productName?: string, productEan?: string, productSupplierName?: string, productSupplierName2?: Array<string>, locationBarcode?: string, locationManco?: boolean, locationFull?: boolean, locationMobile?: boolean, locationStock?: boolean, locationZone?: Array<string>, locationExcludedZone?: Array<string>, multiple?: boolean, orderLocationCount?: 'asc' | 'desc', orderProductId?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (productName !== undefined) {
                localVarQueryParameter['product.name'] = productName;
            }

            if (productEan !== undefined) {
                localVarQueryParameter['product.ean'] = productEan;
            }

            if (productSupplierName !== undefined) {
                localVarQueryParameter['product.supplierName'] = productSupplierName;
            }

            if (productSupplierName2) {
                localVarQueryParameter['product.supplierName[]'] = productSupplierName2;
            }

            if (locationBarcode !== undefined) {
                localVarQueryParameter['location.barcode'] = locationBarcode;
            }

            if (locationManco !== undefined) {
                localVarQueryParameter['location.manco'] = locationManco;
            }

            if (locationFull !== undefined) {
                localVarQueryParameter['location.full'] = locationFull;
            }

            if (locationMobile !== undefined) {
                localVarQueryParameter['location.mobile'] = locationMobile;
            }

            if (locationStock !== undefined) {
                localVarQueryParameter['location.stock'] = locationStock;
            }

            if (locationZone) {
                localVarQueryParameter['location.zone[]'] = locationZone;
            }

            if (locationExcludedZone) {
                localVarQueryParameter['location.excluded_zone[]'] = locationExcludedZone;
            }

            if (multiple !== undefined) {
                localVarQueryParameter['multiple'] = multiple;
            }

            if (orderLocationCount !== undefined) {
                localVarQueryParameter['order[location_count]'] = orderLocationCount;
            }

            if (orderProductId !== undefined) {
                localVarQueryParameter['order[product.id]'] = orderProductId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Stock resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStockItem.');
            }
            const localVarPath = `/stocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export all multiple stock to CSV.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleExportStockCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/multiple-export`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} [ean]
         * @param {string} [name]
         * @param {string} [location]
         * @param {boolean} [full]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleStockCollection: async (ean?: string, name?: string, location?: string, full?: boolean, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock/multiple`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ean !== undefined) {
                localVarQueryParameter['ean'] = ean;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Stock resource.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStockItem: async (id: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchStockItem.');
            }
            const localVarPath = `/stocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsStockCollection: async (location: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'location' is not null or undefined
            if (location === null || location === undefined) {
                throw new RequiredError('location','Required parameter location was null or undefined when calling productsStockCollection.');
            }
            const localVarPath = `/stock/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Add stock to relocate picklist.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relocateStockItem: async (fromZone: string, toZone: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromZone' is not null or undefined
            if (fromZone === null || fromZone === undefined) {
                throw new RequiredError('fromZone','Required parameter fromZone was null or undefined when calling relocateStockItem.');
            }
            // verify required parameter 'toZone' is not null or undefined
            if (toZone === null || toZone === undefined) {
                throw new RequiredError('toZone','Required parameter toZone was null or undefined when calling relocateStockItem.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling relocateStockItem.');
            }
            const localVarPath = `/stock/{id}/relocate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromZone !== undefined) {
                localVarQueryParameter['fromZone'] = fromZone;
            }

            if (toZone !== undefined) {
                localVarQueryParameter['toZone'] = toZone;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a suggested floor for a Product
         * @param {string} zone
         * @param {string} product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedFloorStockItem: async (zone: string, product: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zone' is not null or undefined
            if (zone === null || zone === undefined) {
                throw new RequiredError('zone','Required parameter zone was null or undefined when calling suggestedFloorStockItem.');
            }
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling suggestedFloorStockItem.');
            }
            const localVarPath = `/inbound-transport/suggested-floor`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (zone !== undefined) {
                localVarQueryParameter['zone'] = zone;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a suggested stock for a InboundTransport
         * @param {string} fromLocation
         * @param {string} [currentLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedStockStockItem: async (fromLocation: string, currentLocation?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromLocation' is not null or undefined
            if (fromLocation === null || fromLocation === undefined) {
                throw new RequiredError('fromLocation','Required parameter fromLocation was null or undefined when calling suggestedStockStockItem.');
            }
            const localVarPath = `/inbound-transport/suggested-stock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromLocation !== undefined) {
                localVarQueryParameter['fromLocation'] = fromLocation;
            }

            if (currentLocation !== undefined) {
                localVarQueryParameter['currentLocation'] = currentLocation;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Transfers a Stock resource.
         * @param {string} id
         * @param {StockStockTransferJsonld} [stockStockTransferJsonld] The new Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferStockItem: async (id: string, stockStockTransferJsonld?: StockStockTransferJsonld, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transferStockItem.');
            }
            const localVarPath = `/stock/{id}/transfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stockStockTransferJsonld !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stockStockTransferJsonld !== undefined ? stockStockTransferJsonld : {}) : (stockStockTransferJsonld || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Balance stock.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceStockItem(id: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).balanceStockItem(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} [ean]
         * @param {string} [name]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doubleStockLocationsStockCollection(fromZone: string, toZone: string, ean?: string, name?: string, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).doubleStockLocationsStockCollection(fromZone, toZone, ean, name, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources.
         * @param {string} [productName]
         * @param {string} [productEan]
         * @param {string} [productSupplierName]
         * @param {Array<string>} [productSupplierName2]
         * @param {string} [locationBarcode]
         * @param {boolean} [locationManco]
         * @param {boolean} [locationFull]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationZone]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [multiple]
         * @param {'asc' | 'desc'} [orderLocationCount]
         * @param {'asc' | 'desc'} [orderProductId]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockCollection(productName?: string, productEan?: string, productSupplierName?: string, productSupplierName2?: Array<string>, locationBarcode?: string, locationManco?: boolean, locationFull?: boolean, locationMobile?: boolean, locationStock?: boolean, locationZone?: Array<string>, locationExcludedZone?: Array<string>, multiple?: boolean, orderLocationCount?: 'asc' | 'desc', orderProductId?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getStockCollection(productName, productEan, productSupplierName, productSupplierName2, locationBarcode, locationManco, locationFull, locationMobile, locationStock, locationZone, locationExcludedZone, multiple, orderLocationCount, orderProductId, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Stock resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).getStockItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Export all multiple stock to CSV.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multipleExportStockCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20025>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).multipleExportStockCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} [ean]
         * @param {string} [name]
         * @param {string} [location]
         * @param {boolean} [full]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async multipleStockCollection(ean?: string, name?: string, location?: string, full?: boolean, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).multipleStockCollection(ean, name, location, full, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Stock resource.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStockItem(id: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).patchStockItem(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsStockCollection(location: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20026>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).productsStockCollection(location, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Add stock to relocate picklist.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relocateStockItem(fromZone: string, toZone: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).relocateStockItem(fromZone, toZone, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a suggested floor for a Product
         * @param {string} zone
         * @param {string} product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestedFloorStockItem(zone: string, product: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).suggestedFloorStockItem(zone, product, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a suggested stock for a InboundTransport
         * @param {string} fromLocation
         * @param {string} [currentLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suggestedStockStockItem(fromLocation: string, currentLocation?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).suggestedStockStockItem(fromLocation, currentLocation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Transfers a Stock resource.
         * @param {string} id
         * @param {StockStockTransferJsonld} [stockStockTransferJsonld] The new Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferStockItem(id: string, stockStockTransferJsonld?: StockStockTransferJsonld, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockJsonldStockDetail>> {
            const localVarAxiosArgs = await StockApiAxiosParamCreator(configuration).transferStockItem(id, stockStockTransferJsonld, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StockApi - factory interface
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Balance stock.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceStockItem(id: string, body?: object, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).balanceStockItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} [ean]
         * @param {string} [name]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doubleStockLocationsStockCollection(fromZone: string, toZone: string, ean?: string, name?: string, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return StockApiFp(configuration).doubleStockLocationsStockCollection(fromZone, toZone, ean, name, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Stock resources.
         * @param {string} [productName]
         * @param {string} [productEan]
         * @param {string} [productSupplierName]
         * @param {Array<string>} [productSupplierName2]
         * @param {string} [locationBarcode]
         * @param {boolean} [locationManco]
         * @param {boolean} [locationFull]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationZone]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [multiple]
         * @param {'asc' | 'desc'} [orderLocationCount]
         * @param {'asc' | 'desc'} [orderProductId]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockCollection(productName?: string, productEan?: string, productSupplierName?: string, productSupplierName2?: Array<string>, locationBarcode?: string, locationManco?: boolean, locationFull?: boolean, locationMobile?: boolean, locationStock?: boolean, locationZone?: Array<string>, locationExcludedZone?: Array<string>, multiple?: boolean, orderLocationCount?: 'asc' | 'desc', orderProductId?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return StockApiFp(configuration).getStockCollection(productName, productEan, productSupplierName, productSupplierName2, locationBarcode, locationManco, locationFull, locationMobile, locationStock, locationZone, locationExcludedZone, multiple, orderLocationCount, orderProductId, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Stock resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockItem(id: string, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).getStockItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Export all multiple stock to CSV.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleExportStockCollection(options?: any): AxiosPromise<InlineResponse20025> {
            return StockApiFp(configuration).multipleExportStockCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} [ean]
         * @param {string} [name]
         * @param {string} [location]
         * @param {boolean} [full]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        multipleStockCollection(ean?: string, name?: string, location?: string, full?: boolean, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse2002> {
            return StockApiFp(configuration).multipleStockCollection(ean, name, location, full, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Stock resource.
         * @param {string} id
         * @param {object} [body] The updated Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStockItem(id: string, body?: object, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).patchStockItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Queries the Product resources in Stock.
         * @param {string} location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsStockCollection(location: string, options?: any): AxiosPromise<InlineResponse20026> {
            return StockApiFp(configuration).productsStockCollection(location, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Add stock to relocate picklist.
         * @param {string} fromZone
         * @param {string} toZone
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relocateStockItem(fromZone: string, toZone: string, id: string, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).relocateStockItem(fromZone, toZone, id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a suggested floor for a Product
         * @param {string} zone
         * @param {string} product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedFloorStockItem(zone: string, product: string, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).suggestedFloorStockItem(zone, product, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a suggested stock for a InboundTransport
         * @param {string} fromLocation
         * @param {string} [currentLocation]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suggestedStockStockItem(fromLocation: string, currentLocation?: string, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).suggestedStockStockItem(fromLocation, currentLocation, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Transfers a Stock resource.
         * @param {string} id
         * @param {StockStockTransferJsonld} [stockStockTransferJsonld] The new Stock resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferStockItem(id: string, stockStockTransferJsonld?: StockStockTransferJsonld, options?: any): AxiosPromise<StockJsonldStockDetail> {
            return StockApiFp(configuration).transferStockItem(id, stockStockTransferJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - object-oriented interface
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI {
    /**
     *
     * @summary Balance stock.
     * @param {string} id
     * @param {object} [body] The updated Stock resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public balanceStockItem(id: string, body?: object, options?: any) {
        return StockApiFp(this.configuration).balanceStockItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Queries the Product resources in Stock.
     * @param {string} fromZone
     * @param {string} toZone
     * @param {string} [ean]
     * @param {string} [name]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public doubleStockLocationsStockCollection(fromZone: string, toZone: string, ean?: string, name?: string, page?: number, itemsPerPage?: number, options?: any) {
        return StockApiFp(this.configuration).doubleStockLocationsStockCollection(fromZone, toZone, ean, name, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Stock resources.
     * @param {string} [productName]
     * @param {string} [productEan]
     * @param {string} [productSupplierName]
     * @param {Array<string>} [productSupplierName2]
     * @param {string} [locationBarcode]
     * @param {boolean} [locationManco]
     * @param {boolean} [locationFull]
     * @param {boolean} [locationMobile]
     * @param {boolean} [locationStock]
     * @param {Array<string>} [locationZone]
     * @param {Array<string>} [locationExcludedZone]
     * @param {boolean} [multiple]
     * @param {'asc' | 'desc'} [orderLocationCount]
     * @param {'asc' | 'desc'} [orderProductId]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getStockCollection(productName?: string, productEan?: string, productSupplierName?: string, productSupplierName2?: Array<string>, locationBarcode?: string, locationManco?: boolean, locationFull?: boolean, locationMobile?: boolean, locationStock?: boolean, locationZone?: Array<string>, locationExcludedZone?: Array<string>, multiple?: boolean, orderLocationCount?: 'asc' | 'desc', orderProductId?: 'asc' | 'desc', page?: number, itemsPerPage?: number, options?: any) {
        return StockApiFp(this.configuration).getStockCollection(productName, productEan, productSupplierName, productSupplierName2, locationBarcode, locationManco, locationFull, locationMobile, locationStock, locationZone, locationExcludedZone, multiple, orderLocationCount, orderProductId, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Stock resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public getStockItem(id: string, options?: any) {
        return StockApiFp(this.configuration).getStockItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Export all multiple stock to CSV.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public multipleExportStockCollection(options?: any) {
        return StockApiFp(this.configuration).multipleExportStockCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Queries the Product resources in Stock.
     * @param {string} [ean]
     * @param {string} [name]
     * @param {string} [location]
     * @param {boolean} [full]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public multipleStockCollection(ean?: string, name?: string, location?: string, full?: boolean, page?: number, itemsPerPage?: number, options?: any) {
        return StockApiFp(this.configuration).multipleStockCollection(ean, name, location, full, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Stock resource.
     * @param {string} id
     * @param {object} [body] The updated Stock resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public patchStockItem(id: string, body?: object, options?: any) {
        return StockApiFp(this.configuration).patchStockItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Queries the Product resources in Stock.
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public productsStockCollection(location: string, options?: any) {
        return StockApiFp(this.configuration).productsStockCollection(location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Add stock to relocate picklist.
     * @param {string} fromZone
     * @param {string} toZone
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public relocateStockItem(fromZone: string, toZone: string, id: string, options?: any) {
        return StockApiFp(this.configuration).relocateStockItem(fromZone, toZone, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a suggested floor for a Product
     * @param {string} zone
     * @param {string} product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public suggestedFloorStockItem(zone: string, product: string, options?: any) {
        return StockApiFp(this.configuration).suggestedFloorStockItem(zone, product, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a suggested stock for a InboundTransport
     * @param {string} fromLocation
     * @param {string} [currentLocation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public suggestedStockStockItem(fromLocation: string, currentLocation?: string, options?: any) {
        return StockApiFp(this.configuration).suggestedStockStockItem(fromLocation, currentLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Transfers a Stock resource.
     * @param {string} id
     * @param {StockStockTransferJsonld} [stockStockTransferJsonld] The new Stock resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public transferStockItem(id: string, stockStockTransferJsonld?: StockStockTransferJsonld, options?: any) {
        return StockApiFp(this.configuration).transferStockItem(id, stockStockTransferJsonld, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * StockMutationApi - axios parameter creator
 * @export
 */
export const StockMutationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {'asc' | 'desc'} [orderMutatedAt]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [quantityDiff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockMutationCollection: async (mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, orderMutatedAt?: 'asc' | 'desc', locationMobile?: boolean, locationStock?: boolean, locationExcludedZone?: Array<string>, quantityDiff?: boolean, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/stock_mutations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mutatedAtBefore !== undefined) {
                localVarQueryParameter['mutatedAt[before]'] = mutatedAtBefore;
            }

            if (mutatedAtStrictlyBefore !== undefined) {
                localVarQueryParameter['mutatedAt[strictly_before]'] = mutatedAtStrictlyBefore;
            }

            if (mutatedAtAfter !== undefined) {
                localVarQueryParameter['mutatedAt[after]'] = mutatedAtAfter;
            }

            if (mutatedAtStrictlyAfter !== undefined) {
                localVarQueryParameter['mutatedAt[strictly_after]'] = mutatedAtStrictlyAfter;
            }

            if (inboundTransport !== undefined) {
                localVarQueryParameter['inboundTransport'] = inboundTransport;
            }

            if (inboundTransport2) {
                localVarQueryParameter['inboundTransport[]'] = inboundTransport2;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (product2) {
                localVarQueryParameter['product[]'] = product2;
            }

            if (orderMutatedAt !== undefined) {
                localVarQueryParameter['order[mutatedAt]'] = orderMutatedAt;
            }

            if (locationMobile !== undefined) {
                localVarQueryParameter['location.mobile'] = locationMobile;
            }

            if (locationStock !== undefined) {
                localVarQueryParameter['location.stock'] = locationStock;
            }

            if (locationExcludedZone) {
                localVarQueryParameter['location.excluded_zone[]'] = locationExcludedZone;
            }

            if (quantityDiff !== undefined) {
                localVarQueryParameter['quantity_diff'] = quantityDiff;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a StockMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockMutationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStockMutationItem.');
            }
            const localVarPath = `/stock_mutations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStockMutationCollection: async (mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product_stock_mutations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (mutatedAtBefore !== undefined) {
                localVarQueryParameter['mutatedAt[before]'] = mutatedAtBefore;
            }

            if (mutatedAtStrictlyBefore !== undefined) {
                localVarQueryParameter['mutatedAt[strictly_before]'] = mutatedAtStrictlyBefore;
            }

            if (mutatedAtAfter !== undefined) {
                localVarQueryParameter['mutatedAt[after]'] = mutatedAtAfter;
            }

            if (mutatedAtStrictlyAfter !== undefined) {
                localVarQueryParameter['mutatedAt[strictly_after]'] = mutatedAtStrictlyAfter;
            }

            if (inboundTransport !== undefined) {
                localVarQueryParameter['inboundTransport'] = inboundTransport;
            }

            if (inboundTransport2) {
                localVarQueryParameter['inboundTransport[]'] = inboundTransport2;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (product2) {
                localVarQueryParameter['product[]'] = product2;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockMutationApi - functional programming interface
 * @export
 */
export const StockMutationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {'asc' | 'desc'} [orderMutatedAt]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [quantityDiff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, orderMutatedAt?: 'asc' | 'desc', locationMobile?: boolean, locationStock?: boolean, locationExcludedZone?: Array<string>, quantityDiff?: boolean, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20027>> {
            const localVarAxiosArgs = await StockMutationApiAxiosParamCreator(configuration).getStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, orderMutatedAt, locationMobile, locationStock, locationExcludedZone, quantityDiff, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a StockMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockMutationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockMutationJsonldStockMutationDetail>> {
            const localVarAxiosArgs = await StockMutationApiAxiosParamCreator(configuration).getStockMutationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockMutationProductStockMutation>>> {
            const localVarAxiosArgs = await StockMutationApiAxiosParamCreator(configuration).productStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StockMutationApi - factory interface
 * @export
 */
export const StockMutationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {'asc' | 'desc'} [orderMutatedAt]
         * @param {boolean} [locationMobile]
         * @param {boolean} [locationStock]
         * @param {Array<string>} [locationExcludedZone]
         * @param {boolean} [quantityDiff]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, orderMutatedAt?: 'asc' | 'desc', locationMobile?: boolean, locationStock?: boolean, locationExcludedZone?: Array<string>, quantityDiff?: boolean, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20027> {
            return StockMutationApiFp(configuration).getStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, orderMutatedAt, locationMobile, locationStock, locationExcludedZone, quantityDiff, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a StockMutation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockMutationItem(id: string, options?: any): AxiosPromise<StockMutationJsonldStockMutationDetail> {
            return StockMutationApiFp(configuration).getStockMutationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of StockMutation resources.
         * @param {string} [mutatedAtBefore]
         * @param {string} [mutatedAtStrictlyBefore]
         * @param {string} [mutatedAtAfter]
         * @param {string} [mutatedAtStrictlyAfter]
         * @param {string} [inboundTransport]
         * @param {Array<string>} [inboundTransport2]
         * @param {string} [product]
         * @param {Array<string>} [product2]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<StockMutationProductStockMutation>> {
            return StockMutationApiFp(configuration).productStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockMutationApi - object-oriented interface
 * @export
 * @class StockMutationApi
 * @extends {BaseAPI}
 */
export class StockMutationApi extends BaseAPI {
    /**
     *
     * @summary Retrieves the collection of StockMutation resources.
     * @param {string} [mutatedAtBefore]
     * @param {string} [mutatedAtStrictlyBefore]
     * @param {string} [mutatedAtAfter]
     * @param {string} [mutatedAtStrictlyAfter]
     * @param {string} [inboundTransport]
     * @param {Array<string>} [inboundTransport2]
     * @param {string} [product]
     * @param {Array<string>} [product2]
     * @param {'asc' | 'desc'} [orderMutatedAt]
     * @param {boolean} [locationMobile]
     * @param {boolean} [locationStock]
     * @param {Array<string>} [locationExcludedZone]
     * @param {boolean} [quantityDiff]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockMutationApi
     */
    public getStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, orderMutatedAt?: 'asc' | 'desc', locationMobile?: boolean, locationStock?: boolean, locationExcludedZone?: Array<string>, quantityDiff?: boolean, page?: number, itemsPerPage?: number, options?: any) {
        return StockMutationApiFp(this.configuration).getStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, orderMutatedAt, locationMobile, locationStock, locationExcludedZone, quantityDiff, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a StockMutation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockMutationApi
     */
    public getStockMutationItem(id: string, options?: any) {
        return StockMutationApiFp(this.configuration).getStockMutationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of StockMutation resources.
     * @param {string} [mutatedAtBefore]
     * @param {string} [mutatedAtStrictlyBefore]
     * @param {string} [mutatedAtAfter]
     * @param {string} [mutatedAtStrictlyAfter]
     * @param {string} [inboundTransport]
     * @param {Array<string>} [inboundTransport2]
     * @param {string} [product]
     * @param {Array<string>} [product2]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockMutationApi
     */
    public productStockMutationCollection(mutatedAtBefore?: string, mutatedAtStrictlyBefore?: string, mutatedAtAfter?: string, mutatedAtStrictlyAfter?: string, inboundTransport?: string, inboundTransport2?: Array<string>, product?: string, product2?: Array<string>, page?: number, itemsPerPage?: number, options?: any) {
        return StockMutationApiFp(this.configuration).productStockMutationCollection(mutatedAtBefore, mutatedAtStrictlyBefore, mutatedAtAfter, mutatedAtStrictlyAfter, inboundTransport, inboundTransport2, product, product2, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TranslationApi - axios parameter creator
 * @export
 */
export const TranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTranslationItem.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Translation resources.
         * @param {string} [locale]
         * @param {Array<string>} [locale2]
         * @param {string} [domain]
         * @param {Array<string>} [domain2]
         * @param {string} [source]
         * @param {string} [target]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationCollection: async (locale?: string, locale2?: Array<string>, domain?: string, domain2?: Array<string>, source?: string, target?: string, page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (locale2) {
                localVarQueryParameter['locale[]'] = locale2;
            }

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }

            if (domain2) {
                localVarQueryParameter['domain[]'] = domain2;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (target !== undefined) {
                localVarQueryParameter['target'] = target;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTranslationItem.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Updates the Translation resource.
         * @param {string} id
         * @param {TranslationTranslationEdit} [translationTranslationEdit] The updated Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTranslationItem: async (id: string, translationTranslationEdit?: TranslationTranslationEdit, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchTranslationItem.');
            }
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof translationTranslationEdit !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(translationTranslationEdit !== undefined ? translationTranslationEdit : {}) : (translationTranslationEdit || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates a Translation resource.
         * @param {TranslationJsonldTranslationCreate} [translationJsonldTranslationCreate] The new Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslationCollection: async (translationJsonldTranslationCreate?: TranslationJsonldTranslationCreate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarHeaderParameter['Content-Type'] = 'application/ld+json;v=1.0.0';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof translationJsonldTranslationCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(translationJsonldTranslationCreate !== undefined ? translationJsonldTranslationCreate : {}) : (translationJsonldTranslationCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Removes the Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTranslationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TranslationApiAxiosParamCreator(configuration).deleteTranslationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Translation resources.
         * @param {string} [locale]
         * @param {Array<string>} [locale2]
         * @param {string} [domain]
         * @param {Array<string>} [domain2]
         * @param {string} [source]
         * @param {string} [target]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationCollection(locale?: string, locale2?: Array<string>, domain?: string, domain2?: Array<string>, source?: string, target?: string, page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20028>> {
            const localVarAxiosArgs = await TranslationApiAxiosParamCreator(configuration).getTranslationCollection(locale, locale2, domain, domain2, source, target, page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationJsonldTranslationDetail>> {
            const localVarAxiosArgs = await TranslationApiAxiosParamCreator(configuration).getTranslationItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Updates the Translation resource.
         * @param {string} id
         * @param {TranslationTranslationEdit} [translationTranslationEdit] The updated Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTranslationItem(id: string, translationTranslationEdit?: TranslationTranslationEdit, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationJsonldTranslationDetail>> {
            const localVarAxiosArgs = await TranslationApiAxiosParamCreator(configuration).patchTranslationItem(id, translationTranslationEdit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Creates a Translation resource.
         * @param {TranslationJsonldTranslationCreate} [translationJsonldTranslationCreate] The new Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTranslationCollection(translationJsonldTranslationCreate?: TranslationJsonldTranslationCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationJsonldTranslationDetail>> {
            const localVarAxiosArgs = await TranslationApiAxiosParamCreator(configuration).postTranslationCollection(translationJsonldTranslationCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Removes the Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTranslationItem(id: string, options?: any): AxiosPromise<void> {
            return TranslationApiFp(configuration).deleteTranslationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Translation resources.
         * @param {string} [locale]
         * @param {Array<string>} [locale2]
         * @param {string} [domain]
         * @param {Array<string>} [domain2]
         * @param {string} [source]
         * @param {string} [target]
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationCollection(locale?: string, locale2?: Array<string>, domain?: string, domain2?: Array<string>, source?: string, target?: string, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20028> {
            return TranslationApiFp(configuration).getTranslationCollection(locale, locale2, domain, domain2, source, target, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Translation resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationItem(id: string, options?: any): AxiosPromise<TranslationJsonldTranslationDetail> {
            return TranslationApiFp(configuration).getTranslationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Updates the Translation resource.
         * @param {string} id
         * @param {TranslationTranslationEdit} [translationTranslationEdit] The updated Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTranslationItem(id: string, translationTranslationEdit?: TranslationTranslationEdit, options?: any): AxiosPromise<TranslationJsonldTranslationDetail> {
            return TranslationApiFp(configuration).patchTranslationItem(id, translationTranslationEdit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates a Translation resource.
         * @param {TranslationJsonldTranslationCreate} [translationJsonldTranslationCreate] The new Translation resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTranslationCollection(translationJsonldTranslationCreate?: TranslationJsonldTranslationCreate, options?: any): AxiosPromise<TranslationJsonldTranslationDetail> {
            return TranslationApiFp(configuration).postTranslationCollection(translationJsonldTranslationCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     *
     * @summary Removes the Translation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public deleteTranslationItem(id: string, options?: any) {
        return TranslationApiFp(this.configuration).deleteTranslationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Translation resources.
     * @param {string} [locale]
     * @param {Array<string>} [locale2]
     * @param {string} [domain]
     * @param {Array<string>} [domain2]
     * @param {string} [source]
     * @param {string} [target]
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public getTranslationCollection(locale?: string, locale2?: Array<string>, domain?: string, domain2?: Array<string>, source?: string, target?: string, page?: number, itemsPerPage?: number, options?: any) {
        return TranslationApiFp(this.configuration).getTranslationCollection(locale, locale2, domain, domain2, source, target, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Translation resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public getTranslationItem(id: string, options?: any) {
        return TranslationApiFp(this.configuration).getTranslationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Updates the Translation resource.
     * @param {string} id
     * @param {TranslationTranslationEdit} [translationTranslationEdit] The updated Translation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public patchTranslationItem(id: string, translationTranslationEdit?: TranslationTranslationEdit, options?: any) {
        return TranslationApiFp(this.configuration).patchTranslationItem(id, translationTranslationEdit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Creates a Translation resource.
     * @param {TranslationJsonldTranslationCreate} [translationJsonldTranslationCreate] The new Translation resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public postTranslationCollection(translationJsonldTranslationCreate?: TranslationJsonldTranslationCreate, options?: any) {
        return TranslationApiFp(this.configuration).postTranslationCollection(translationJsonldTranslationCreate, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Determine if auto logout is needed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogoutUserCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/auto-logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a User resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserItem.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Re-identify user.
         * @param {string} pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reIdentifyUserCollection: async (pin: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pin' is not null or undefined
            if (pin === null || pin === undefined) {
                throw new RequiredError('pin','Required parameter pin was null or undefined when calling reIdentifyUserCollection.');
            }
            const localVarPath = `/user/re-identify`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pin !== undefined) {
                localVarQueryParameter['pin'] = pin;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Determine if auto logout is needed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoLogoutUserCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).autoLogoutUserCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20029>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUserCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a User resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserDetail>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUserItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Re-identify user.
         * @param {string} pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reIdentifyUserCollection(pin: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonldUserDetail>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).reIdentifyUserCollection(pin, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Determine if auto logout is needed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoLogoutUserCollection(options?: any): AxiosPromise<InlineResponse20029> {
            return UserApiFp(configuration).autoLogoutUserCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse20029> {
            return UserApiFp(configuration).getUserCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a User resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem(id: string, options?: any): AxiosPromise<UserJsonldUserDetail> {
            return UserApiFp(configuration).getUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Re-identify user.
         * @param {string} pin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reIdentifyUserCollection(pin: string, options?: any): AxiosPromise<UserJsonldUserDetail> {
            return UserApiFp(configuration).reIdentifyUserCollection(pin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @summary Determine if auto logout is needed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public autoLogoutUserCollection(options?: any) {
        return UserApiFp(this.configuration).autoLogoutUserCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of User resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserCollection(page?: number, itemsPerPage?: number, options?: any) {
        return UserApiFp(this.configuration).getUserCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a User resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserItem(id: string, options?: any) {
        return UserApiFp(this.configuration).getUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Re-identify user.
     * @param {string} pin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reIdentifyUserCollection(pin: string, options?: any) {
        return UserApiFp(this.configuration).reIdentifyUserCollection(pin, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Cancel job for printer.
         * @param {string} name
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJobWorkspaceItem: async (name: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling cancelJobWorkspaceItem.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelJobWorkspaceItem.');
            }
            const localVarPath = `/workspace/printer/job/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CVP Sparck health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckHealthcheckWorkspaceCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cvp/sparck/V1/healthcheck`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary CVP Sparck next order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckNextorderWorkspaceCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cvp/sparck/V1/nextorder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves the collection of Workspace resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCollection: async (page?: number, itemsPerPage?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspaces`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieves a Workspace resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceItem: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getWorkspaceItem.');
            }
            const localVarPath = `/workspaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Print server check
         * @param {string} secretKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerCheckWorkspaceCollection: async (secretKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'secretKey' is not null or undefined
            if (secretKey === null || secretKey === undefined) {
                throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling printerCheckWorkspaceCollection.');
            }
            const localVarPath = `/workspace/print-server/check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (secretKey !== undefined) {
                localVarQueryParameter['secret_key'] = secretKey;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get printer jobs
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerDetailJobsWorkspaceCollection: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling printerDetailJobsWorkspaceCollection.');
            }
            const localVarPath = `/workspace/printer/jobs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get printer detail
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerDetailWorkspaceItem: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling printerDetailWorkspaceItem.');
            }
            const localVarPath = `/workspace/printer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersWorkspaceCollection: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspace/printers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication etrias required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Cancel job for printer.
         * @param {string} name
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelJobWorkspaceItem(name: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).cancelJobWorkspaceItem(name, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary CVP Sparck health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cvpSparckHealthcheckWorkspaceCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).cvpSparckHealthcheckWorkspaceCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary CVP Sparck next order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cvpSparckNextorderWorkspaceCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).cvpSparckNextorderWorkspaceCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves the collection of Workspace resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceCollection(page?: number, itemsPerPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).getWorkspaceCollection(page, itemsPerPage, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Retrieves a Workspace resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceItem(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceJsonldWorkspaceDetail>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).getWorkspaceItem(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Print server check
         * @param {string} secretKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printerCheckWorkspaceCollection(secretKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).printerCheckWorkspaceCollection(secretKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get printer jobs
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printerDetailJobsWorkspaceCollection(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).printerDetailJobsWorkspaceCollection(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get printer detail
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printerDetailWorkspaceItem(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceJsonldPrinterDetail>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).printerDetailWorkspaceItem(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async printersWorkspaceCollection(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20030>> {
            const localVarAxiosArgs = await WorkspaceApiAxiosParamCreator(configuration).printersWorkspaceCollection(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Cancel job for printer.
         * @param {string} name
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelJobWorkspaceItem(name: string, id: string, options?: any): AxiosPromise<void> {
            return WorkspaceApiFp(configuration).cancelJobWorkspaceItem(name, id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary CVP Sparck health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckHealthcheckWorkspaceCollection(options?: any): AxiosPromise<InlineResponse200> {
            return WorkspaceApiFp(configuration).cvpSparckHealthcheckWorkspaceCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary CVP Sparck next order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cvpSparckNextorderWorkspaceCollection(options?: any): AxiosPromise<InlineResponse200> {
            return WorkspaceApiFp(configuration).cvpSparckNextorderWorkspaceCollection(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves the collection of Workspace resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<InlineResponse200> {
            return WorkspaceApiFp(configuration).getWorkspaceCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieves a Workspace resource.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceItem(id: string, options?: any): AxiosPromise<WorkspaceJsonldWorkspaceDetail> {
            return WorkspaceApiFp(configuration).getWorkspaceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Print server check
         * @param {string} secretKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerCheckWorkspaceCollection(secretKey: string, options?: any): AxiosPromise<InlineResponse200> {
            return WorkspaceApiFp(configuration).printerCheckWorkspaceCollection(secretKey, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get printer jobs
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerDetailJobsWorkspaceCollection(name: string, options?: any): AxiosPromise<InlineResponse20030> {
            return WorkspaceApiFp(configuration).printerDetailJobsWorkspaceCollection(name, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get printer detail
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printerDetailWorkspaceItem(name: string, options?: any): AxiosPromise<WorkspaceJsonldPrinterDetail> {
            return WorkspaceApiFp(configuration).printerDetailWorkspaceItem(name, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List printers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        printersWorkspaceCollection(options?: any): AxiosPromise<InlineResponse20030> {
            return WorkspaceApiFp(configuration).printersWorkspaceCollection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
    /**
     *
     * @summary Cancel job for printer.
     * @param {string} name
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public cancelJobWorkspaceItem(name: string, id: string, options?: any) {
        return WorkspaceApiFp(this.configuration).cancelJobWorkspaceItem(name, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary CVP Sparck health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public cvpSparckHealthcheckWorkspaceCollection(options?: any) {
        return WorkspaceApiFp(this.configuration).cvpSparckHealthcheckWorkspaceCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary CVP Sparck next order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public cvpSparckNextorderWorkspaceCollection(options?: any) {
        return WorkspaceApiFp(this.configuration).cvpSparckNextorderWorkspaceCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves the collection of Workspace resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspaceCollection(page?: number, itemsPerPage?: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspaceCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Retrieves a Workspace resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspaceItem(id: string, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspaceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Print server check
     * @param {string} secretKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public printerCheckWorkspaceCollection(secretKey: string, options?: any) {
        return WorkspaceApiFp(this.configuration).printerCheckWorkspaceCollection(secretKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get printer jobs
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public printerDetailJobsWorkspaceCollection(name: string, options?: any) {
        return WorkspaceApiFp(this.configuration).printerDetailJobsWorkspaceCollection(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get printer detail
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public printerDetailWorkspaceItem(name: string, options?: any) {
        return WorkspaceApiFp(this.configuration).printerDetailWorkspaceItem(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List printers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public printersWorkspaceCollection(options?: any) {
        return WorkspaceApiFp(this.configuration).printersWorkspaceCollection(options).then((request) => request(this.axios, this.basePath));
    }

}


